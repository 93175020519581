import dashboardActionType from "../actions/types/billingActionType";

const { GET_DASHBOARD, GET_DASHBOARD_SUCCESS, GET_DASHBOARD_FAIL } =
  dashboardActionType;

const intialState = {
  dashboard_data: {},
  loading: false,
};
const dashboardReducer = (state = intialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD:
      return {
        ...state,
        dashboard_data: {},
        loading: true,
      };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard_data: action.payload,
        loading: false,
      };
    case GET_DASHBOARD_FAIL:
      return {
        ...state,
        dashboard_data: {},
        loading: false,
      };
    default:
      return state;
  }
};
export default dashboardReducer;
