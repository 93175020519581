import { useEffect, useState } from "react";
// api
import config from "../../config/config";
import api from "../../config/api";
// component
import CompanyDetails from "./companyDetails";

function CompanySelector({ theme, token, csrf_token, AuthDetail }) {
  const getCurrentSite = config.getCurrentSite;

  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(true);
  const [hideEdit, setHideEdit] = useState(false);

  const { COMPANY_DETAILS, USER_DETAILS } = config.api_endpoint;

  const handleSaveCompany = (userCompany) => {
    console.log("handleSaveCompany:", userCompany);
    setLoading(true);
    let formData = new FormData();
    formData.append("title", userCompany.title);
    formData.append("name", userCompany.name);
    formData.append("address", userCompany.address);
    formData.append("country", userCompany.country);
    formData.append("area", userCompany.area);
    formData.append("capacity", userCompany.capacity);
    formData.append("rating", userCompany.rating);
    formData.append("green_mark_expiry", userCompany.green_mark_expiry);
    formData.append("next_price_adjustment", userCompany.next_price_adjustment);
    formData.append("building_type", userCompany.building_type);
    formData.append("img", userCompany.img);
    formData.append(
      "ph_op_hrs_start",
      userCompany.ph_op_hrs_start === "NA" ? null : userCompany.ph_op_hrs_start
    );
    formData.append(
      "ph_op_hrs_end",
      userCompany.ph_op_hrs_end === "NA" ? null : userCompany.ph_op_hrs_end
    );
    formData.append(
      "sat_op_hrs_start",
      userCompany.sat_op_hrs_start === "NA"
        ? null
        : userCompany.sat_op_hrs_start
    );
    formData.append(
      "sat_op_hrs_end",
      userCompany.sat_op_hrs_end === "NA" ? null : userCompany.sat_op_hrs_end
    );
    formData.append(
      "sun_op_hrs_start",
      userCompany.sun_op_hrs_start === "NA"
        ? null
        : userCompany.sun_op_hrs_start
    );
    formData.append(
      "sun_op_hrs_end",
      userCompany.sun_op_hrs_end === "NA" ? null : userCompany.sun_op_hrs_end
    );
    formData.append(
      "wd_op_hrs_start",
      userCompany.wd_op_hrs_start === "NA" ? null : userCompany.wd_op_hrs_start
    );
    formData.append(
      "wd_op_hrs_end",
      userCompany.wd_op_hrs_end === "NA" ? null : userCompany.wd_op_hrs_end
    );

    api.post(COMPANY_DETAILS, formData, csrf_token).then((res) => {
      console.log("Company details save: ", res);
      if (res["status"] == "Success") {
        loadData();
        alert("Successfully saved company data.");
      } else {
        alert("Unable to save company data.");
        setLoading(false);
      }
    });
  };

  const loadData = () => {
    setLoading(true);
    api
      .get(USER_DETAILS + "?username=" + AuthDetail.username)
      .then((res) => {
        console.log("Profile res: ", res);
        if (res["results"]["permissions"].length > 0) {
          if (res["results"]["permissions"].indexOf("change_kaersite") > -1) {
            setHideEdit(false);
          }
        }

        for (var ix = 0; ix < res["results"].company.length; ix++) {
          if (res["results"].company[ix]["name"] == getCurrentSite()) {
            setCompany(res["results"].company[ix]);
            break;
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log("Profile err", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="text-center company-loading">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <CompanyDetails
          company={company}
          theme={theme}
          token={token}
          hideEdit={hideEdit}
          saveCompany={handleSaveCompany}
        />
      )}
    </>
  );
}
export default CompanySelector;
