import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap';

// image & css
import "./liveStatsBlk.css";
import Meter from "../assets/images/live/meter-hollow.svg";
import Indicator from "../assets/images/live/indicator.png";
import IndicatorDark from "../assets/images/live/indicator-alt.png";
import Power from "../assets/images/live/power.png";
import Temperature from "../assets/images/live/temperature.png";
import TemperatureDark from "../assets/images/live/temperature-dark.png";
import Water from "../assets/images/live/water.png";
import WaterDark from "../assets/images/live/water-dark.png";

function statsBlk(props) {
  const theme = localStorage.getItem("theme");
  const heading = props.heading;
  const data = props.data;

  // add custom css for dark theme tooltip
  if (theme == "dark") {
    var styles = `
      .tooltip-arrow::before, .tooltip-arrow::before {
        border-bottom-color: #C4C4C4 !important;
      }
    `;

    var styleSheet = document.createElement("style")
    styleSheet.type = "text/css"
    styleSheet.innerText = styles
    document.head.appendChild(styleSheet)
  };

  const showLiveValueModal = props.showLiveValueModal;
  const setShowLiveValueModal = props.setShowLiveValueModal;
  const showLiveType = props.showLiveType;
  const setShowLiveType = props.setShowLiveType;
  const showMoreIconClicked = props.showMoreIconClicked;
  const showSpaceDialogClicked = props.showSpaceDialogClicked;
  const hideKaerAir = props.hideKaerAir || false;

  const showNumberWithCommas = (x) => {
    if (x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return x;
  };

  return (
    <Row className={`rounded stats-blk${theme === "light" ? "" : "-dark"}`}>

      <Modal show={showLiveValueModal}
        className=""
        id="liveStatsBlkModal"
        onHide={function () {
          setShowLiveValueModal(false);
        }}
      >
        <Modal.Header
          closeButton
          className={`${theme === "light" ? "" : "dark dark-blk"}`}
        >
          <Modal.Title>
            <div className="">
              <span className={`liveModalTitle ${theme === "light" ? "" : "dark"}`}>
                {showLiveType === "water" ? "Chilled water supply temperature" : "Cooling load"}
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={`${
            theme === "light" ? "" : "dark dark-blk"
          }`}
        >
          { showLiveType === "water" ? (
            <div style={{ backgroundColor: "#F8F8F8", width: "100%", padding: "20px" }}>
              <p style={{ marginBottom: "0em", fontSize: "12px" }}>Set-Point Temperature
              <strong style={{ float: "right", fontSize: "15px", fontWeight: "bold" }}>{showNumberWithCommas(data.temp_limit)}&#176;<span style={{ fontSize: "12px", fontWeight: "bold" }}>C</span></strong></p>
            </div>
          ) : (
            <div style={{ backgroundColor: "#F8F8F8", width: "100%", padding: "20px" }}>
              <p style={{ marginBottom: "0em", fontSize: "12px" }}>Maximum = {showNumberWithCommas(data.max_cooling)} RT</p>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Row className="ms-0 ps-0 main-blk">
        <Col md="8" sm="8" className="col-8">
          <div className="table-blk">
            <div className="table-cell-blk padding-blk"></div>
            <h3 className={`table-cell-blk blk-header${theme === "light" ? "" : " dark"}`}>{heading}</h3>
          </div>
        </Col>
        <Col md="4" sm="4" className="col-4">

        </Col>
      </Row>
      <Row className="ms-0 ps-0 liveStatsStarBlk">
        <Col lg={`${hideKaerAir ? 4 : 2}`} md="4" sm="12" className="liveStatsStar add-border-right col-12 d-none d-md-inline-block">
          <div className="cdn-blk-title"><h5>System</h5></div>
          <Image className="img-fluid liveStatsStarImg" src={Power} />
          <h5 className={`cdn-blk-text-graphik${theme === "light" ? "" : " dark"}`}
              style={{marginTop: '0.6em'}}>{data.power}</h5>
        </Col>

        <Col sm="12" className="d-block d-md-none">
          <div sm="4" className="col-5 liveStatsStarMobile">
            <Image className="img-fluid liveStatsStarImg" src={Power} />
            <h5 className={`cdn-blk-text-graphik${theme === "light" ? "" : " dark"}`}
                style={{ marginTop: '0.3em' }}>{data.power}</h5>
          </div>
          <div sm="8" className="col-7 liveStatsStarMobile">
            <div className="cdn-blk-title cdn-blk-title-mobile"><h5>System</h5></div>
          </div>
        </Col>
        <hr className="d-block d-md-none mobile-hr"></hr>

        <Col lg={`${hideKaerAir ? 4 : 2}`} md="4" sm="12" className="liveStatsStar add-border-right col-12 d-none d-md-inline-block">
          <div className="cdn-blk-title"><h5>Chilled water supply temperature</h5></div>
          <Image className="img-fluid liveStatsStarImg" src={theme === "light" ? Temperature : TemperatureDark} />
          <OverlayTrigger
            key="tooltip-temp"
            placement="bottom"
            overlay={
              <Tooltip id={`tooltip-temp`} className={`custom-tooltip${theme === "light" ? "" : "-dark"}`}>
                Set-Point Temperature <br></br>
                <strong style={{ fontSize: "15px", fontWeight: "bold" }}>{showNumberWithCommas(data.temp_limit)} &#176;
                <span style={{ fontSize: "12px", fontWeight: "bold" }}>C</span></strong>
              </Tooltip>
            }
          >
            <h5 className={`cdn-blk-text-graphik${theme === "light" ? "" : " dark"}`}
             style={{marginTop: '0.6em'}}>{showNumberWithCommas(data.temp)}<span style={{ fontSize: "18px" }}>&#176;C</span></h5>
          </OverlayTrigger>
        </Col>

        <Col sm="12" className="d-block d-md-none">
          <div sm="4" className="col-5 liveStatsStarMobile">
            <Image className="img-fluid liveStatsStarImg" src={theme === "light" ? Temperature : TemperatureDark} />
            <h5 className={`cdn-blk-text-graphik${theme === "light" ? "" : " dark"}`}
             style={{ marginTop: '0.3em' }}>{data.temp}<span style={{ fontSize: "18px" }}>&#176;C</span></h5>
          </div>
          <div sm="8" className="col-7 liveStatsStarMobile">
            <div className="cdn-blk-title-mobile">
              <h5 style={{ width: "90%" }}>Chilled water supply temperature</h5>
              <i className="bi bi-three-dots-vertical liveStatsModalOpener" data-type="water" onClick={showMoreIconClicked}></i>
            </div>
          </div>
        </Col>
        <hr className="d-block d-md-none mobile-hr"></hr>

        <Col lg={`${hideKaerAir ? 4 : 2}`} md="4" sm="12" className={`liveStatsStar ${!hideKaerAir && "add-border-right"} ${hideKaerAir && "add-border-none"} col-12 d-none d-md-inline-block`}>
          <div className="cdn-blk-title"><h5>Cooling load</h5></div>
          <div style={{ height: "85px" }}>
            <Image className="img-fluid" src={Meter} style={{ height: '66px !important' }} /><br></br>
            <Image className="img-fluid" id="indicator"
              data-cooling={data.cooling}
              src={theme === "light" ? Indicator : IndicatorDark}
              style={{marginTop: "-3em", height: "30px !important", transform: "rotate("+ data.deg +"deg)"}}/>
          </div>
          <OverlayTrigger
            key="tooltip-cooling"
            placement="bottom"
            overlay={
              <Tooltip id={`tooltip-cooling`} className={`custom-tooltip${theme === "light" ? "" : "-dark"}`} >
                Maximum = {showNumberWithCommas(data.max_cooling)} RT
              </Tooltip>
            }
          >
            <h5 className={`cdn-blk-text-cooling cdn-blk-text${theme === "light" ? "" : " dark"}`}>{showNumberWithCommas(data.cooling)} RT</h5>
          </OverlayTrigger>
        </Col>

        <Col sm="12" className="d-block d-md-none">
          <div sm="4" className="col-5 liveStatsStarMobile">
            <div style={{ height: "85px" }}>
              <Image className="img-fluid liveStatsStarImg" src={Meter} /><br></br>
              <Image className="img-fluid" id="indicator2"
                src={theme === "light" ? Indicator : IndicatorDark}
                style={{marginTop: "-3em", height: "30px !important", transform: "rotate("+ data.deg +"deg)"}}/>
            </div>
            <h5 className={`cdn-blk-text-cooling cdn-blk-text${theme === "light" ? "" : " dark"}`}>{data.cooling} RT</h5>
          </div>
          <div sm="8" className="col-7 liveStatsStarMobile">
            <div className="cdn-blk-title-mobile">
              <h5 style={{ width: "90%" }}>Cooling load</h5>
              <i className="bi bi-three-dots-vertical liveStatsModalOpener" data-type="cooling" onClick={showMoreIconClicked}></i>
            </div>
          </div>
        </Col>

        {!hideKaerAir ? (
        <Col lg="6" md="12">
            <Row style={{ height: '25px' }}>
              <div className="cdn-blk-title-right-div">
                <h5 className="cdn-blk-title-right chosen_room">{data.chosen_room}</h5>
                <a className={`dropdown-toggle ${theme === "light" ? "" : "dark"}`} href="#" role="button"
                  id="liveStatsRooms"
                  onClick={showSpaceDialogClicked}
                  data-widget="live-main" ></a>
              </div>
            </Row>
            <Row className={`cdn-blk-parent${theme === "light" ? "" : "-dark"}`}>
              <Col className="cdn-blk col-6" md="6" sm="6" lg="6">
                <Image className="img-fluid right-img liveStatsStarImg" src={theme === "light" ? Temperature : TemperatureDark} />
                <h5 className={`cdn-blk-text${theme === "light" ? "" : " dark"}`} style={{marginTop: '0.3em'}}>{data.confer_temp}<span className="text-be-float"><sup>&#176;C</sup></span></h5>
              </Col>
              <Col className="cdn-blk col-6" md="6" sm="6" lg="6">
                <Image className="img-fluid right-img liveStatsStarImg" src={theme === "light" ? Water : WaterDark} />
                <h5 className={`cdn-blk-text${theme === "light" ? "" : " dark"}`} style={{marginTop: '0.35em'}}>{data.water}<span className="text-be-float"><sup>% RH</sup></span></h5>
              </Col>
            </Row>
        </Col>
        ):(
          <Col lg="6" md="12" className=""></Col>
        )}
      </Row>
    </Row>
  )
}

export default statsBlk;
