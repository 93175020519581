import React, { useEffect, useState } from "react";
import { Row, Col, Image, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import api from "../../config/api";
import config from "../../config/config";
import { connect } from "react-redux";

import NotificationList from "./notificationList.js";
import NotificationSettings from "./notificationSettings.js";

// css & image
import "./notification.css";
import ReadAllIcon from "../../assets/images/notification/read-all.svg";
import ReadAllDarkIcon from "../../assets/images/notification/read-all-dark.svg";
import ReadAllAltIcon from "../../assets/images/notification/read-all-alt.svg";
import CheckIcon from "../../assets/images/notification/check.svg";
import CheckDarkIcon from "../../assets/images/notification/check-dark.svg";
import CheckAltIcon from "../../assets/images/notification/check-alt.svg";

function Notification (props) {

  const theme = localStorage.getItem("theme");
  const [selType, setSelType] = useState("unread");
  const [readAll, setReadAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPage] = useState(1);
  const [shouldRead, setShouldRead] = useState(true);
  const { AuthDetail, AuthLoggedIn, CSRFToken } = props;
  const { GET_NOTIFICATION_DATA, SET_NOTIFICATION_SETTING } = config.api_endpoint;

  const getCurrentSite = config.getCurrentSite;

  if (theme == "dark") {
    var styles = `
      .tooltip-arrow::before, .tooltip-arrow::before {
        border-top-color: #C4C4C4 !important;
      }
    `;

    var styleSheet = document.createElement("style")
    styleSheet.type = "text/css"
    styleSheet.innerText = styles
    document.head.appendChild(styleSheet)
  }

  // events
  const selTypeClicked = (event) => {
    setSelType(event.target.nextSibling.innerHTML.toLowerCase());
    if (event.target.nextSibling.innerHTML.toLowerCase() === "unread") {
      setReadAll(false);
    }
  };

  const readAllClicked = (event) => {
    var data = notiData.slice();
    var noti_id = [];
    if (readAll === true) {
      setReadAll(false);
      data.forEach(function(item) {
        item["selected"] = false;
      });
    }
    else {
      setLoading(true);
      setReadAll(true);
      data.forEach(function(item) {
        item["selected"] = true;
        noti_id.push(item["id"]);
      });
      api
        .post(GET_NOTIFICATION_DATA + "?site_id=" + getCurrentSite(),
          JSON.stringify({"noti_id": noti_id}), CSRFToken
        )
        .then((res) => {
          console.log("Notification read all: ", res);
          data = [];

          setTimeout(() => {
            setNotiData(data);
            setPage(1);
            setShouldRead(true);
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          console.log("Notification read all err", err);
          setLoading(false);
        });
    }
  };

  // data
  const [notiData, setNotiData] = useState([]);
  const [notiSettings, setNotiSettings] = useState({});
  const [shouldSaveSettings, setShouldSaveSettings] = useState(false);

  const checkClicked = (event) => {
      var nid = event.target.getAttribute("data-ind");

      var data = notiData.slice();
      if (data[nid]["selected"] === true) {
        data[nid]["selected"] = false;
      }
      else {
        setLoading(true);
        api
          .post(GET_NOTIFICATION_DATA + "?site_id=" + getCurrentSite(),
            JSON.stringify({"noti_id": [data[nid]["id"]]}), CSRFToken
          )
          .then((res) => {
            console.log("Notification read: ", res);
            data.splice(nid, 1);
            event.target.parentNode.closest('div.noti-row').classList.add("hidden");

            setTimeout(() => {
              setShouldRead(false);
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            console.log("Notification read err", err);
            setLoading(false);
          });
      }
      console.log("checkClicked: ", data);
      setNotiData(data);
    }

  useEffect(() => {
      if (shouldRead === true) {
        setLoading(true);
        api.get(GET_NOTIFICATION_DATA + "?mode=" + selType + "&page=" + page + "&site_id=" + getCurrentSite())
          .then((res) => {
            console.log("Notification res: ", res);
            var noti_data = res["results"];
            noti_data.forEach(function(item) {
              item["selected"] = false;
            });
            console.log("notiData: ", notiData);
            setNotiData(notiData.concat(noti_data));
            setNotiSettings(res["notification_settings"]["setting"]);
            if (res["next"]) {
              setLoadMore(true);
              setPage(page + 1);
            }
            else {
              setLoadMore(false);
            }
            setShouldRead(false);
            setLoading(false);
          })
          .catch((err) => {
            console.log("notification err", err);
            setLoading(false);
          });
      }
      return () => {
        setLoading(false);
      }
  }, [shouldRead]);

  useEffect(() => {
      console.log("selType changed: ", selType);
      setPage(1);
      setNotiData([]);
      setShouldRead(true);
  }, [selType]);

  const loadMoreClicked = () => {
      setShouldRead(true);
  };

  const notiSettingsChanged = (event) => {
    console.log("notiSettingsChanged ==> ", event.target, event.target.id, event.target.checked);

    var tmp = {...notiSettings};

    if (event.target.id == "greenMarkExpiry") {
        tmp["green_mark_expiry"]["enabled"] = event.target.checked;
    }

    if (event.target.id == "greenMarkExpiryValue") {
      tmp["green_mark_expiry"]["value"] = event.target.value;
    }

    if (event.target.id == "monthlyConsumptionAvgValue") {
      tmp["monthly_consumption_avg"]["value"] = event.target.value;
    }

    if (event.target.id == "monthlyConsumptionAvgThreshold") {
      tmp["monthly_consumption_avg"]["threshold"] = event.target.value;
    }

    if (event.target.id == "monthlyConsumptionAvg") {
      tmp["monthly_consumption_avg"]["enabled"] = event.target.checked;
    }

    if (event.target.id == "monthlyConsumptionValue") {
      tmp["monthly_consumption"]["value"] = event.target.value;
    }

    if (event.target.id == "monthlyConsumptionThreshold") {
      tmp["monthly_consumption"]["threshold"] = event.target.value;
    }

    if (event.target.id == "monthlyConsumption") {
      tmp["monthly_consumption"]["enabled"] = event.target.checked;
    }

    setShouldSaveSettings(true);
    setNotiSettings(tmp);
  };

  useEffect(() => {

    console.log("useEffect notiSettings ==> ", notiSettings);
    console.log("useEffect shouldSaveSettings ==> ", shouldSaveSettings);

    if(notiSettings && shouldSaveSettings == true) {
      const timeOutId = setTimeout(() => {
        setLoading(true);
        api
          .post(SET_NOTIFICATION_SETTING,
            JSON.stringify({"settings": notiSettings, "site_id": getCurrentSite()}), CSRFToken
          )
          .then((res) => {
            console.log("Notification set settings: ", res);
            setLoading(false);
            setShouldSaveSettings(false);
          })
          .catch((err) => {
            console.log("Notification set settings err", err);
            setLoading(false);
            setShouldSaveSettings(false);
          });
      }, 3000);
      return () => clearTimeout(timeOutId);
    }

  }, [notiSettings]);


  return (
      <div>
        { loading && (
          <Modal show={loading} data-backdrop="static" data-keyboard="false" className="loader">
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          </Modal>
        )}
        <Row className={`rounded notification-blk${theme === "light" ? "" : "-dark"} mx-2 ps-2`}>
          <Row className="ms-0 ps-0 notification-main-blk">
            <Col md="12" sm="12" className="col-12">
              <div className="table-blk">
                <div className="table-cell-blk notification-padding-blk padding-blk"></div>
                <h3 className={`table-cell-blk notification-blk-header${theme === "light" ? "" : " dark"}`}>My Notifications</h3>
              </div>
            </Col>
          </Row>
          <Row className="notification-holder">
            <Col lg="6" className="pt-2">
              <Row>
                <Col md="9" sm="9" className="col-9">
                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" className="btn-check" name="btnUnread" id="btnUnread" autoComplete="off"
                    defaultChecked onClick={selTypeClicked} />
                  <label className={`btn noti-type ${selType === "unread" && "active"} ${theme === "light" ? "" : "dark"}`}
                    htmlFor="btnUnread">Unread</label>

                  <input type="radio" className="btn-check" name="btnRead" id="btnRead" autoComplete="off"
                    onClick={selTypeClicked} />
                  <label className={`btn noti-type ${selType === "read" && "active"} ${theme === "light" ? "" : "dark"}`}
                    htmlFor="btnRead">Read</label>
                </div>
                </Col>
                <Col md="3" sm="3" className="col-3">
                {selType === "unread" && (
                  <OverlayTrigger
                    key="tooltip-readAll"
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-readAll`} className={`custom-tooltip${theme === "light" ? "" : "-dark"}`}>
                        Read all
                      </Tooltip>
                    }
                  >
                      <Image className="img-fluid read-all" src={
                        theme === "light" && readAll === false ? ReadAllIcon :
                        theme === "dark" && readAll === false ? ReadAllDarkIcon :
                        ReadAllAltIcon
                      } onClick={readAllClicked} />
                  </OverlayTrigger>
                )}
                </Col>
              </Row>
              <Row className="noti-list">
                <NotificationList
                notiData={notiData}
                checkClicked={checkClicked}
                theme={theme} />
                {loadMore === true && (
                  <div className="text-center">
                    <br></br>
                    <h6 className="loadmore" onClick={loadMoreClicked}><u>Load More</u></h6>
                    <br></br>
                  </div>
                )}
                {loadMore === false && notiData.length > 0 ? (
                  <div className="text-center">
                    <br></br>
                    <h6>No more activity</h6>
                    <br></br>
                  </div>
                ):("")}
              </Row>
            </Col>
            <Col lg="6" className="pt-2">
              <NotificationSettings
                header={"Notification Settings"}
                notification_settings={notiSettings}
                notiSettingsChanged={notiSettingsChanged}
              />
            </Col>
          </Row>
        </Row>
      </div>
  )
};

const mapStateToProps = (state) => ({
  AuthDetail: state.loginUserDetail.userDetail,
  AuthLoggedIn: state.loginUserDetail.loggedIn,
  CSRFToken: state.loginUserDetail.csrf,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Notification);
