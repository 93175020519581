import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router";

// routes
import {
  LOGIN_ROUTE
} from "../../routes";
import { useMediaQuery } from "react-responsive";
import { Container, Row, Col, Image, Button, Modal } from 'react-bootstrap';

// API
import config from "../../config/config";
import api from "../../config/api";

// js files
import Login from "./login";

// image & css
import "./login.css";
import Logo from "../../assets/images/logo/login_logo.png";
import DarkLogo from "../../assets/images/logo/login_logo_dark.png";

import PdfViewer from "../../components/PdfViewer";

function Index(props) {

  const theme = localStorage.getItem("theme");
  const isMobile = useMediaQuery({ maxWidth: 765 });

  const [loading, setLoading] = useState(false);

  const { RESET_PASSWORD, GET_TOKEN } = config.api_endpoint;
  const [resetEmail, setResetEmail] = useState("");
  const [resetError, setResetError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [showForgotPasswordDialog, setShowForgotPasswordDialog] = useState(false);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const forgotPassword = () => {
    setResetEmail("");
    setResetError("");
    setEmailError(false);
    setShowForgotPasswordDialog(true);
  };

  const saveReset = () => {
    if (resetEmail == "") {
      setResetError("Please provide a valid email.");
      setEmailError(true);
    }
    else if (!validateEmail(resetEmail)) {
      setResetError("Please provide a valid email.");
      setEmailError(true);
    }
    else {
      setShowForgotPasswordDialog(false);
      setLoading(true);
      api
        .post(
          RESET_PASSWORD,
          JSON.stringify({
            email: resetEmail
          })
        )
        .then((result) => {
          console.log("result: ", result);
          setLoading(false);
          alert(result["details"]);
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        })
        .finally(() => {
          setResetEmail("");
          setResetError("");
          setEmailError(false);
        });
    }
  };

  /*
  <Row className="mt-4 mb-4">
    <p style={{ marginBottom: "0px" }}>
      <i className="bi bi-question-circle-fill login-icon-color" ></i>
      <a href="#" className={`text-decoration-underline ${theme === "light" ? "login-text-color" : "login-text-color-dark"}`}>Frequently Asked Questions</a>
    </p>
    <p>
      <i className="bi bi-shield-fill-check login-icon-color" ></i>
      <a href="#" className={`text-decoration-underline ${theme === "light" ? "login-text-color" : "login-text-color-dark"}`}>Security and You</a>
    </p>
  </Row>
  */

  const [token, setToken] = useState();
  // const [termsPdf, setTermsPdf] = useState();
  // const [showTNC, setShowTNC] = useState(false);

  const [pdfFileModal, setPdfFileModal] = useState(false);
  const [pdfFile, setPdfFile] = useState({
    url: "",
    fileame: "",
  });
  const [pdfZoom, setPdfZoom] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const prevHandle = () => {
    if (pageNumber <= 1) {
      setPageNumber(1);
    } else {
      setPageNumber((prev) => prev - 1);
    }
  };

  const nextHandle = () => {
    if (pageNumber >= numPages) {
      setPageNumber(numPages);
    } else {
      setPageNumber((prev) => prev + 1);
    }
  };

  const downloadFile = (url, filename) => {
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: "application/pdf",
      });

      const isIE = false || !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || window.webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", filename);
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  };

  const tncClicked = (e) => {
    e.preventDefault();
    setPdfFileModal(true);
  }

  useEffect(() => {
    api.get(GET_TOKEN)
      .then((res) => {
        setToken(res["results"]["token"]);
        localStorage.setItem("imgToken", res["results"]["token"]);
      })
      .catch((err) => {
        console.log("Image token err", err);
      });
  }, [])

  useEffect(() => {
    if (token) {
      if (config.url == "https://api.kaerconnect.com/kaer") {
        setPdfFile({
          url: "https://files.kaerconnect.com/terms_of_service/Kaer_Connect_Terms_of_Service.pdf" + token,
          fileame: "Kaer_Connect_Terms_of_Service.pdf"
        });
      }
      else {
        setPdfFile({
          url: "https://kaerdev-cdn.int.weeswares.com/terms_of_service/Kaer_Connect_Terms_of_Service.pdf" + token,
          fileame: "Kaer_Connect_Terms_of_Service.pdf"
        });
      }
    }
  }, [token])

  // <iframe
  //     sandbox="allow-scripts allow-same-origin"
  //     src={"https://drive.google.com/viewerng/viewer?embedded=true&url=" + encodeURIComponent(termsPdf)}
  //     frameBorder="0"
  //     scrolling="auto"
  //     height="100%"
  //     width="100%"
  // ></iframe>
  // <embed
  //     src={termsPdf}
  //     frameBorder="0"
  //     scrolling="auto"
  //     height="100%"
  //     width="100%"
  // ></embed>
  // <Modal show={showTNC}
  //   className={`${isMobile && "modal-sm"} ${!isMobile && "modal-md"}`}
  //   onHide={function () {
  //     setShowTNC(false);
  //   }}
  // >
  //   <Modal.Header
  //     closeButton
  //   >
  //     <Modal.Title>
  //       <h3>Terms of Service (ToS)</h3>
  //     </Modal.Title>
  //   </Modal.Header>
  //   <Modal.Body>
  //
  //   </Modal.Body>
  // </Modal>

  return (
    <>
      { loading && (
        <Modal show={loading} data-backdrop="static" data-keyboard="false" className="loader">
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </Modal>
      )}
      <Container fluid className={`background ${isMobile && "background-mobile"} ${!isMobile && "background-desktop"}`}>
        <Row>
          <Col lg="5" md="2"></Col>
          <Col lg="7" md="10">
            <Row>
              <Col lg="5" md="4" sm="2" col="4" className="col-1" ></Col>
              <Col lg="6" md="7" sm="8" col="7" className="col-10" >
                <Row className="login-background">
                  <Row className="mt-3 mw-100" className={`login-box`}>
                    <Image className="mt-3" src={theme === "dark" ? DarkLogo : Logo} className="login-company-logo" />

                    <Route
                      exact
                      path={LOGIN_ROUTE}
                      render={() => <Login setSignIn={props.setSignIn} />}
                    />
                    <Route
                      render={() =>
                        props.selected !== "" &&
                        <Redirect to="/" />
                      }
                    />
                    <Row className="pb-4 ms-0">
                      <p className="forgot-password-p"><a href="#"
                      className={`text-decoration-underline ${theme === "light" ? "forgot-password" : "forgot-password-dark"}`}
                      onClick={forgotPassword}
                      >Forgot Password</a>&nbsp;&nbsp;&nbsp;&nbsp;
                      <a
                      href=""
                      target="_blank"
                      className={`text-decoration-underline ${theme === "light" ? "forgot-password" : "forgot-password-dark"}`}
                      onClick={tncClicked}>Terms of Service (ToS)</a></p>

                      { pdfFileModal == true && (
                        <div className="pdf-container-view"
                        >
                          <div
                            className="modal_view_overlay"
                          >
                            <div
                              className="modal_view_overlay"
                              style={{ zIndex: 0 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setPdfFileModal(false);
                              }}
                            ></div>
                            <div className="modal_header">
                              <div className="move_fun">
                                <div onClick={prevHandle} className="pdf-button">
                                  {"<"}
                                </div>

                                <div className="pdf-currentPageNumber">
                                  {pageNumber} / {numPages}
                                </div>

                                <div onClick={nextHandle} className="pdf-button">
                                  {" "}
                                  {">"}
                                </div>
                              </div>
                              <div className="right-side-pdf-header">
                                <div
                                  onClick={() => {
                                    setPdfZoom((prev) => prev + 1);
                                  }}
                                  style={{
                                    marginRight: 25,
                                    fontSize: 30,
                                  }}
                                >
                                  +
                                </div>
                                <div
                                  onClick={() => {
                                    setPdfZoom((prev) => (prev === 1 ? 1 : prev - 1));
                                  }}
                                  style={{
                                    fontSize: 30,
                                  }}
                                >
                                  -
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    onClick={() => downloadFile(pdfFile.url, "Kaer Connect Terms of Service")}
                                    style={{
                                      marginRight: 25,
                                      marginLeft: 25,
                                      fontWeight: 500,
                                      fontSize: 16,
                                    }}
                                  >
                                    Download
                                    {/* <DownloadIcon
                                      style={{
                                        width: 30,
                                        height: 21,
                                        marginTop: -5,
                                      }}
                                    /> */}
                                  </div>
                                  <div onClick={() => setPdfFileModal(false)}>X</div>
                                </div>
                              </div>
                            </div>

                            <PdfViewer
                              setPdfFileModal={setPdfFileModal}
                              pdfFile={pdfFile}
                              numPages={numPages}
                              setNumPages={setNumPages}
                              pageNumber={pageNumber}
                              setPageNumber={setPageNumber}
                              pdfZoom={pdfZoom}
                            />
                          </div>
                        </div>
                      )}

                    </Row>
                  </Row>
                </Row>
              </Col>
              <Col lg="1" md="1" sm="2" col="1" className="col-1"></Col>
            </Row>
          </Col>
        </Row>

        <Modal show={showForgotPasswordDialog}
          className={`${isMobile && "modal-sm"} ${!isMobile && "modal-md"}`}
          onHide={function () {
            setShowForgotPasswordDialog(false);
          }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title>
              Please enter the email address used to login to Kaer Connect
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(resetError) && (
              <div className="alert alert-danger d-flex align-items-center" role="alert">
                <i className="bi bi-exclamation-triangle-fill"></i> &nbsp;&nbsp;
                <div>
                  {resetError}
                </div>
              </div>
            )}
            <label className="profile-label">Email:</label>
            <span className="profile-input">
              <input id="reset_email" type="text"
              className={`${emailError && "input_error_cls"}`}
              onChange={(e) => {
                setResetEmail(e.target.value);
              }} />
            </span>
            <Button className="btn save-profile-btn"
              type="button" onClick={saveReset}
              style={{ float: "right", "marginTop": "1em" }}
            >
              <i className="bi bi-key"></i> Set Password
            </Button>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  )
}

export default Index;
