import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";

import { Row, Col, Image, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import api from "../../config/api";
import config from "../../config/config";
import { connect } from "react-redux";

import "./contact.css";
import "./marker.css";
import PhoneIcon from "../../assets/images/contact/phone.svg";
import BigPhoneIcon from "../../assets/images/contact/big-phone.svg";
import CallIcon from "../../assets/images/contact/call.svg";
import EmailIcon from "../../assets/images/contact/email.svg";
import MapIcon from "../../assets/images/contact/map.png";

function Contact (props) {

  const theme = localStorage.getItem("theme");
  const [loading, setLoading] = useState(false);
  const { GET_CONTACT_DATA } = config.api_endpoint;
  const { AuthDetail } = props;
  const [topData, setTopData] = useState([]);
  const [emergencyData, setEmergencyData] = useState({});
  const [mapLocation, setMaplocation] = useState({});
  const [showMap, setShowMap] = useState(false);
  const [company, setCompany] = useState({});

  const getCurrentSite = config.getCurrentSite;

  const gApi = config.googleAPIKey;
  const AnyReactComponent = (text) => {
    return (
      <>
      <div className="pin"></div>
      <div className="pulse"></div>
    </>
    );
  };

  useEffect(() => {
    if (props.AuthLoggedIn === false) {
      return;
    }

    setLoading(true);

    // if (AuthDetail.company) {
    //   for (var ix = 0; ix < AuthDetail.company.length; ix++) {
    //     if (AuthDetail.company[ix]["name"] == getCurrentSite()) {
    //       if (AuthDetail.company[ix]["lat"] && AuthDetail.company[ix]["lng"]) {
    //         setMaplocation({ "lat": AuthDetail.company[ix]["lat"], "lng": AuthDetail.company[ix]["lng"] });
    //         break;
    //       }
    //     }
    //   }
    // }

    api.get(GET_CONTACT_DATA + "?site_id=" + getCurrentSite())
      .then((res) => {
        console.log("contact res: ", res);
        if (res["status"] === "Success") {
          var top = [];
          res["results"].forEach((item) => {
            if (item["department"] === "Customer success" || item["department"] === "Operations") {
              top.push(item);
            }
            else if (item["department"].indexOf("hotline") > -1) {
              setEmergencyData(item);
            }
            else if (item["department"] === "Company") {
              setCompany(item);
              if (item.lat && item.lng) {
                setMaplocation({ "lat": item.lat, "lng": item.lng });
                setShowMap(true);
              }
            }
          });
          setTopData(top);
          setLoading(false);
        }
        else {
          setLoading(false);
          alert("Error retrieving contact data.");
        }
      })
      .catch((err) => {
        console.log("contact err", err);
        setLoading(false);
      });
    return () => {
      setLoading(false);
    }
  }, []);

  return (
    <div>
      { loading && (
        <Modal show={loading} data-backdrop="static" data-keyboard="false" className="loader">
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </Modal>
      )}
      <Row className={`rounded contact-blk${theme === "light" ? "" : "-dark"} mx-2 p-3`}>
        <Row className="ms-0 ps-0 contact-main-blk">
          <Col md="12" sm="12" className="col-12">
            <div className="table-blk">
              <div className="table-cell-blk contact-padding-blk padding-blk"></div>
              <h3 className={`table-cell-blk contact-blk-header${theme === "light" ? "" : " dark"}`}>Kaer</h3>
            </div>
          </Col>
        </Row>
          { topData.length > 0 ? (
            <Row className="ms-0 ps-0 contact-content-blk pt-4">
              {topData.map((_, index) => {
                return (
                  <Col lg="6" md="12" className="col-12 " key={_.id} style={{ paddingBottom: "10px" }}>
                    <div className={`rounded pt-3 ps-3 pe-3 pb-0 info-blk${theme === "light" ? "" : "-dark"}`}>
                      <p>{_.title}</p>
                      <div className="contact-info">
                        <p className="info-title">{_.name}</p>
                        <p><a href={`mailto:${_.email}`}>{_.email}</a></p>
                        <Row>
                          <Col sm="8" className="col-8">
                            <p>{_.phone}</p>
                          </Col>
                          <Col sm="4" className="col-4">
                            <a href={`tel:${_.phone}`}><Image src={PhoneIcon} /></a><a href={`mailto:${_.email}`}><Image src={EmailIcon} /></a>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          ): (
            <div></div>
          )}
          { emergencyData && (
              <Row>
                <Col md="12">
                  <br></br>
                  <div className="emer-blk emer-right-blk">
                    <p>{emergencyData.title} <a href={`tel:${emergencyData.phone}`}>{emergencyData.phone}</a></p>
                  </div>
                  <div className="emer-blk emer-left-blk">
                    <a href={`tel:${emergencyData.phone}`}><Image src={CallIcon} className="img-fluid" /></a>
                  </div>
                </Col>
              </Row>
            )
          }
          <div><hr></hr></div>
          <br></br><br></br>
          <Row>
            <div className="contact-company-mobile">
              <h5 className="contact-company-name">{company.name}</h5>
              <p className="contact-company-address">{company.address}
              <br className="d-block d-lg-none"></br>
              <span className="info-divider d-none d-lg-inline"></span> <a href={`mailto:${company.email}`}>{company.email}</a>&nbsp;
              <br className="d-block d-lg-none"></br>
              <span className="info-divider d-none d-lg-inline"></span>{company.phone}</p>
            </div>
          </Row>
          { showMap && (
            <div className="gmap-wrapper">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: gApi,
                  }}
                  options={{
                    fullscreenControl: false,
                  }}
                  center={mapLocation}
                  zoom={16}
                >
                  <AnyReactComponent
                    lat={mapLocation.lat}
                    lng={mapLocation.lng}
                    text="My Marker"
                  />
              </GoogleMapReact>
            </div>
          )}
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  AuthDetail: state.loginUserDetail.userDetail,
  AuthLoggedIn: state.loginUserDetail.loggedIn,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Contact);
