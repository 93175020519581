import React from "react";
import { Row, Col, Button, Image } from 'react-bootstrap';

// image & css
import "./dataBlk.css";
import PeakLogo from "../assets/images/Data/peak.svg";
import PeakDarkLogo from "../assets/images/Data/peak-alt.svg";
import ElectricityLogo from "../assets/images/Data/electricity.svg";
import ElectricityDarkLogo from "../assets/images/Data/electricity-alt.svg";
import EffciencyLogo from "../assets/images/Data/effciency.svg";
import EffciencyDarkLogo from "../assets/images/Data/effciency-alt.svg";
import DeliveredLogo from "../assets/images/Data/delivered.svg";
import DeliveredDarkLogo from "../assets/images/Data/delivered-alt.svg";
import WaterLogo from "../assets/images/Data/water.svg";
import WaterDarkLogo from "../assets/images/Data/water-alt.svg";
import CarbonLogo from "../assets/images/Data/carbon.svg";
import CarbonDarkLogo from "../assets/images/Data/carbon-alt.svg";

function dataBlk(props) {

  const theme = localStorage.getItem("theme");
  const chart_title = props.chart_title;
  const heading = props.heading;
  const data = props.data;
  const dataChoiceClicked = props.dataChoiceClicked;
  const removeWidgetClicked = props.removeWidgetClicked;
  const id = props.id;
  const chosen_date = props.chosen_date;

  const showNumberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Row className={`rounded dash-data-blk${theme === "light" ? "" : "-dark"}`}>
      <Row className="ms-0 ps-0 main-blk">
        <Col md="8" sm="8" className="col-8">
          <div className="table-blk">
            <div className="table-cell-blk padding-blk"></div>
            <h3 className={`table-cell-blk blk-header${theme === "light" ? "" : " dark"}`}>{heading}</h3>
          </div>
        </Col>
        <Col md="4" sm="4" className="col-4">
          <i className="bi bi-gear-fill settings-icon" role="button" id={id} data-bs-toggle="dropdown" aria-expanded="false"></i>
          <ul className="dropdown-menu" aria-labelledby={id}>
            <li><a className="dropdown-item" href="#" data-choice="day" data-id={id} onClick={removeWidgetClicked}>Remove</a></li>
          </ul>
        </Col>
      </Row>
      <Row>
        <div>
          <h5 className="cdn-blk-title-right" id={`data_${id}`}>{chosen_date}</h5>
          <a className={`dropdown-toggle ${theme === "light" ? "" : "dark"}`} href="#" role="button" id="dataDrop" data-bs-toggle="dropdown" aria-expanded="false"></a>
          <ul className="dropdown-menu" aria-labelledby="dataDrop">
            <li><a className="dropdown-item" href="#" data-choice="day" onClick={dataChoiceClicked}>Previous Day</a></li>
            <li><a className="dropdown-item" href="#" data-choice="week" onClick={dataChoiceClicked}>Previous Week</a></li>
            <li><a className="dropdown-item" href="#" data-choice="month" onClick={dataChoiceClicked}>Previous Month</a></li>
            <li><a className="dropdown-item" href="#" data-choice="year" onClick={dataChoiceClicked}>Previous Year</a></li>
          </ul>
        </div>
      </Row>
      <Row className="ms-0 ddata-ddata" style={{ overflowY: "auto" }}>
        <Col lg="6" md="6" sm="6" className="col-6" style={{ paddingRight: "0px" }}>
          {data.peak_cooling && (
            <div>
              <div className="data-info-blk">
                <p className="data-info-label"><b>Peak cooling</b></p>
                <Image
                  src={theme === "light" ? PeakLogo : PeakDarkLogo}
                  className="data-info-logo"
                />
                <p className="data-info-value-blk">
                  <span className="data-info-value-rate" id={`peak_cooling_${id}`}>{showNumberWithCommas(data.peak_cooling)}</span>
                  <span className="data-info-value-measure">RT</span>
                </p>
              </div>
              <hr></hr>
            </div>
          )}
          {data.electricity_used && (
            <div>
              <div className="data-info-blk">
                <p className="data-info-label"><b>Electricity used</b></p>
                <Image
                  src={theme === "light" ? ElectricityLogo : ElectricityDarkLogo}
                  className="data-info-logo"
                />
                <p className="data-info-value-blk">
                  <span className="data-info-value-rate" id={`electricity_used_${id}`}>{showNumberWithCommas(data.electricity_used)}</span>
                  <span className="data-info-value-measure">kWh</span>
                </p>
              </div>
              <hr></hr>
            </div>
          )}
          {data.efficiency && (
            <div>
              <div className="data-info-blk">
                <p className="data-info-label"><b>Effciency</b></p>
                <Image
                  src={theme === "light" ? EffciencyLogo : EffciencyDarkLogo}
                  className="data-info-logo"
                />
                <p className="data-info-value-blk">
                  <span className="data-info-value-rate" id={`efficiency_${id}`}>{showNumberWithCommas(data.efficiency)}</span>
                  <span className="data-info-value-measure">kW/RT</span>
                </p>
              </div>
            </div>
          )}
        </Col>
        <Col lg="6" md="6" sm="6" className="col-6" style={{ paddingLeft: "0px" }}>
          {data.cooling_delivered && (
            <div>
              <div className="data-info-blk">
                <p className="data-info-label"><b>Cooling delivered</b></p>
                <Image
                  src={theme === "light" ? DeliveredLogo : DeliveredDarkLogo}
                  className="data-info-logo"
                />
                <p className="data-info-value-blk">
                  <span className="data-info-value-rate" id={`cooling_delivered_${id}`}>{showNumberWithCommas(data.cooling_delivered)}</span>
                  <span className="data-info-value-measure">RTH</span>
                </p>
              </div>
              <hr></hr>
            </div>
          )}
          {data.water_used && (
            <div>
              <div className="data-info-blk">
                <p className="data-info-label"><b>Water used</b></p>
                <Image
                  src={theme === "light" ? WaterLogo : WaterDarkLogo}
                  className="data-info-logo"
                />
                <p className="data-info-value-blk">
                  <span className="data-info-value-rate" id={`water_used_${id}`}>{showNumberWithCommas(data.water_used)}</span>
                  <span className="data-info-value-measure">m<sup>3</sup></span>
                </p>
              </div>
              <hr></hr>
            </div>
          )}
          {data.carbon_emission && (
            <div>
              <div className="data-info-blk">
                <p className="data-info-label"><b>Carbon Emissions</b></p>
                <Image
                  src={theme === "light" ? CarbonLogo : CarbonDarkLogo}
                  className="data-info-logo"
                />
                <p className="data-info-value-blk">
                  <span className="data-info-value-rate" id={`carbon_emission_${id}`}>{showNumberWithCommas(data.carbon_emission)}</span>
                  <span className="data-info-value-measure">MT</span>
                </p>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Row>
  )

}

export default dataBlk;
