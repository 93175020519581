import api from "../../config/api";
import config from "../../config/config";
import userManagementActionType from "./types/userManagementActionType";

const { USER_LIST } = config.api_endpoint;

const { GET_USER_LIST, GET_USER_LIST_SUCCESS, GET_USER_LIST_FAIL } =
  userManagementActionType;

const fetchingUserList = () => {
  return {
    type: GET_USER_LIST,
  };
};

const getUsersListFail = () => {
  return {
    type: GET_USER_LIST_FAIL,
  };
};

const getUsersListSuccess = (data) => {
  return {
    type: GET_USER_LIST_SUCCESS,
    payload: data,
  };
};

export const fetchUserList = (site_id, current_page) => {
  return (dispatch) => {
    dispatch(fetchingUserList());
    api
      .get(USER_LIST, {
        site_id,
        page: current_page,
        page_size: 10,
      })
      .then((res) => {
        console.log("res", res);
        if (res.status === "Success") {
          dispatch(getUsersListSuccess(res));
        }
        console.log("res2", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};
