import userManagementActionType from "../actions/types/userManagementActionType";

const { GET_USER_LIST, GET_USER_LIST_SUCCESS, GET_USER_LIST_FAIL } =
  userManagementActionType;

const intialState = {
  users_list: [],
  loading: false,
  total_pages: 1,
};

const userManagementReducer = (state = intialState, action) => {
  switch (action.type) {
    case GET_USER_LIST:
      return {
        loading: true,
      };
    case GET_USER_LIST_SUCCESS:
      return {
        loading: false,
        users_list: action.payload.results,
        total_pages: Math.ceil(action.payload.total / 10),
      };
    case GET_USER_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default userManagementReducer;
