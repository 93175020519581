import loginUserActionTypes from "../actions/types/loginUserActionTypes";

const {
  LOGIN_USER_DETAIL,
  LOGIN_USER_DETAIL_SUCCESS,
  LOGIN_USER_DETAIL_FAIL,
  USER_LOGOUT,
  STORE_TOKEN_USER,
  STORE_CSRF_TOKEN,
  STORE_LOGGED_IN_FLAG,
} = loginUserActionTypes;

const intialState = {
  loggedIn: false,
  userDetail: {},
  loading: true,
  token: null,
  csrf: null,
  sessionKey: null,
};

const loginUserReducer = (state = intialState, action) => {
  switch (action.type) {
    case LOGIN_USER_DETAIL:
      return {
        ...state,
        loggedIn: false,
        userDetail: {},
        loading: true,
      };
    case USER_LOGOUT:
      return {
        ...state,
        token: null,
        loggedIn: false,
        userDetail: {},
        loading: false,
      };
    case LOGIN_USER_DETAIL_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        userDetail: {
          ...action.payload,
        },
        loading: false,
      };
    case LOGIN_USER_DETAIL_FAIL:
      return {
        ...state,
        loggedIn: false,
        userDetail: {},
        loading: false,
      };
    case STORE_TOKEN_USER:
      return {
        ...state,
        token: action.payload,
      };
    case STORE_CSRF_TOKEN:
      return {
        ...state,
        csrf: action.payload,
      };
    case STORE_LOGGED_IN_FLAG:
      return {
        ...state,
        loggedIn: action,
      };
    default:
      return state;
  }
};
export default loginUserReducer;
