import React from "react";
import PeakChart from "./PeakChart/PeakChart";
import CoolingChart from "./CoolingChart/CoolingChart";
import ElectricityChart from "./ElectricityChart/ElectricityChart";
import WaterChart from "./WaterChart/WaterChart";
import EfficiencyChart from "./EfficiencyChart/EfficiencyChart";
import CarbonChart from "./CarbonChart/CarbonChart";

import "./style.css";

const Index = React.memo(() => {
  let theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  return (
    <div>
      <div className="mini_chart_container">
        <div className="mini_chat_indi">
          <PeakChart theme={theme} />
        </div>
        <div>
          <CoolingChart theme={theme} />
        </div>
        <div>
          <ElectricityChart theme={theme} />
        </div>
      </div>
      <div className="mini_chart_container">
        <div className="mini_chat_indi">
          <WaterChart theme={theme} />
        </div>
        <div>
          <EfficiencyChart theme={theme} />
        </div>
        <div>
          <CarbonChart theme={theme} />
        </div>
      </div>
    </div>
  );
});
export default Index;
