import React from "react";
import Download from "./Downloads/Download";
import Submission from "./Submissions/Submission";

function Index() {
  return (
    <div
      style={{
        display: "flex",
        // justifyContent: "center",
        width: "100%",
        flexDirection: "column",
      }}
    >
      {/* <Submission /> */}
      <Download />
    </div>
  );
}

export default Index;
