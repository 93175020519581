import React, { useState, useEffect } from "react";
import { options, data, plugins } from "./chart_two_setting";
import { Line } from "react-chartjs-2";
import "./Chart.css";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";

const ChartTwo = React.memo((props) => {
  const { title, data_count } = props;
  const [count, setCount] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [label, setLabel] = useState([]);

  const maxWidth765 = useMediaQuery({ maxWidth: 765 });
  const maxWidth500 = useMediaQuery({ maxWidth: 500 });

  let theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  useEffect(() => {
    if (data_count && data_count.graph && data_count.graph.chilled_water) {
      let datas = [];
      let labels = [];

      Object.entries(data_count.graph.chilled_water)
        .sort(([key, value], [key2, value2]) => key - key2)
        .map(([key, value]) => {
          labels.push(key);
          datas.push(value);
        });

      setLabel(labels.sort((a, b) => a - b));
      setCount(datas);
    }
  }, [data_count]);
  return (
    <div className="charts_container_mobile">
      <div className={`data_big_chart_container ${theme}`}>
        <div className="title">{title}</div>
        <div style={{ height: 350 }}>
          <Line
            options={options(maxWidth765, maxWidth500, label)}
            data={data(count, label)}
            plugins={plugins}
          />
        </div>
      </div>
    </div>
  );
});
const mapStateToProps = (state) => ({
  data_count: state.data.data_count,
});
export default connect(mapStateToProps, null)(ChartTwo);
