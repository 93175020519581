import React from "react";
import { ReactComponent as EditIcon } from "../../assets/images/billing/edit.svg";
import { ReactComponent as TrashIcon } from "../../assets/images/trash.svg";
import { ReactComponent as MailIcon } from "../../assets/images/envelope.svg";
import { connect } from "react-redux";
import moment from "moment";

const MobileTable = (props) => {
  let theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";
  return (
    <div className="mobile_tables">
      <div className={`mobile_billing_header ${theme}`}>Display Name</div>
      <div className={`mobile_billing_header ${theme}`}>Email</div>
      <div className={`mobile_billing_header ${theme}`}>Groups</div>
      <div className={`mobile_billing_header ${theme}`}>Created By</div>
      <div className={`mobile_billing_header ${theme}`}>Created</div>
      <div className={`mobile_billing_header ${theme}`}>Last Joined</div>
      {props.usersList &&
        props.usersList.map((user) => {
          return (
            <div className="mobile_download_table_card">
              <div>
                <div
                  style={{
                    display: "grid",
                    marginBottom: 30,
                    gridTemplateColumns: "1fr 1fr 1fr",
                  }}
                >
                  {/* <div>
                    <div style={{ fontWeight: "bold" }}>Username</div>
                    <div>{user.username}</div>
                  </div> */}
                  <div>
                    <div>
                      <div style={{ fontWeight: "bold" }}>Display Name</div>
                      <div>{user.full_name}</div>
                    </div>
                  </div>
                  <div>
                    <div style={{ fontWeight: "bold" }}>Email</div>
                    <div>{user.email}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "grid",
                    marginBottom: 30,
                    gridTemplateColumns: "1fr 1fr 1fr",
                  }}
                >
                  <div>
                    <div style={{ fontWeight: "bold" }}>Created By</div>
                    <div>{user.created_by}</div>
                  </div>
                  <div>
                    <div>
                      <div style={{ fontWeight: "bold" }}>Group</div>
                      <div> {user.groups[0] && user.groups[0].name}</div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "grid",
                    marginBottom: 30,
                    gridTemplateColumns: "1fr 1fr 1fr",
                  }}
                >
                  <div>
                    <div style={{ fontWeight: "bold" }}>Last Login</div>
                    <div>
                      {" "}
                      {user.last_login !== "-"
                        ? moment(user.last_login).format("DD MMM YYYY")
                        : "-"}
                    </div>
                  </div>

                  <div>
                    <div>
                      <div style={{ fontWeight: "bold" }}>Date joined</div>
                      <div>
                        {" "}
                        {user.date_joined !== "-"
                          ? moment(user.date_joined).format("DD MMM YYYY")
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  {" "}
                  <EditIcon
                    fill={theme === "light" ? "#000" : "#fff"}
                    width={18}
                    onClick={() => {
                      // props.setIsEditable(true);
                      props.getDetailValue(user);
                      props.handleShowUpdate();
                    }}
                  />
                </div>
                <div>
                  {" "}
                  <TrashIcon
                    fill={theme === "light" ? "#000" : "#fff"}
                    width={16}
                    onClick={() => {
                      props.setSelectUser(user);
                      props.setSelectType("delete");
                      props.handleShowAletBox();
                    }}
                  />
                </div>
                <div>
                  {" "}
                  <MailIcon
                    fill={theme === "light" ? "#000" : "#fff"}
                    width={20}
                    onClick={() => {
                      props.setSelectUser(user);
                      props.setSelectType("mail_resend");
                      props.handleShowAletBox();
                    }}
                  />
                </div>
                <div style={{ width: "100%", marginTop: 10 }}>
                  <button
                    className={`btn ${
                      user.is_active ? "btn-dark" : "btn-danger"
                    }  d-flex justify-content-around align-items-center`}
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      props.setSelectUser(user);
                      props.setSelectType("active");
                      props.handleShowAletBox();
                    }}
                  >
                    <div>{user.is_active ? "Disable" : "Enable"}</div>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
const mapStateToProps = (state) => ({
  usersList: state.usersList.users_list,
});
export default connect(mapStateToProps)(MobileTable);
