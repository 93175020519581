import React from "react";
import { Row, Col, Button, Image } from 'react-bootstrap';

import config from "../config/config";

// image & css
import "./profileBlk.css";
import CountryLogo from "../assets/images/profile/country.svg";
import CountryDarkLogo from "../assets/images/profile/country-alt.svg";
import CrateLogo from "../assets/images/profile/crate.svg";
import CrateDarkLogo from "../assets/images/profile/crate-alt.svg";
import CapacityLogo from "../assets/images/profile/capacity.svg";
import CapacityDarkLogo from "../assets/images/profile/capacity-alt.svg";
import DateLogo from "../assets/images/profile/date.svg";
import DateDarkLogo from "../assets/images/profile/date-alt.svg";
import AddressLogo from "../assets/images/profile/address.svg";
import AddressDarkLogo from "../assets/images/profile/address-alt.svg";
import AreaLogo from "../assets/images/profile/area.svg";
import AreaDarkLogo from "../assets/images/profile/area-alt.svg";
import GreenLogo from "../assets/images/profile/green.svg";
import GreenDarkLogo from "../assets/images/profile/green-alt.svg";
import NextLogo from "../assets/images/profile/next.svg";
import NextDarkLogo from "../assets/images/profile/next-alt.svg";

function profileBlk(props) {

  const theme = localStorage.getItem("theme");
  const chart_title = props.chart_title;
  const heading = props.heading;

  const getCurrentSite = config.getCurrentSite;
  var data = {};
  props.data.company.forEach(function (item) {
      if(item["name"] == getCurrentSite()) {
          data = item;
      }
  });

  const removeWidgetClicked = props.removeWidgetClicked;
  const id = props.id;

  const showNumberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  /*
  <div>
    <div className="company-info-blk" >
      <Image
        src={theme === "light" ? NextLogo : NextDarkLogo}
        className="company-info-logo-address"
      />
    </div>
    <div className="company-info-blk company-info-blk-value" >
      <b><span>Next price adjustment</span></b>
      <p>{data.next_price_adjustment_fmt}</p>
    </div>
  </div>
  */

  return (
    <Row className={`rounded dprofiles-blk${theme === "light" ? "" : "-dark"}`}>
      <Row className="ms-0 dprofile-main-blk">
        <Col lg="10" md="10" sm="10" className="col-10 dprofile-sub-blk">
          <div className="dprofile-blk">
            <div className="dprofile-cell-blk dprofile-padding-blk padding-blk"></div>
            <h3 className={`dprofile-cell-blk dprofile-blk-header${theme === "light" ? "" : " dark"}`}>{heading}</h3>
          </div>
        </Col>
        <Col lg="2" md="2" sm="2" className="col-2 dprofile-sub-blk">
          <i className="bi bi-gear-fill settings-icon" role="button" id={id} data-bs-toggle="dropdown" aria-expanded="false"></i>
          <ul className="dropdown-menu" aria-labelledby={id}>
            <li><a className="dropdown-item" href="#" data-choice="day" data-id={id} onClick={removeWidgetClicked}>Remove</a></li>
          </ul>
        </Col>
      </Row>
      <Row className="ms-0" className="d-none">
        <p className="dprofile-blk-title">{chart_title}</p>
      </Row>
      <Row className="ms-0 dprofile-dprofile" style={{ overflowY: "auto" }}>
      <Col lg="6" md="6" sm="6" className="col-6">
        <div>
          <div className="company-info-blk" >
            <Image
              src={theme === "light" ? AddressLogo : AddressDarkLogo}
              className="company-info-logo-address"
            />
          </div>
          <div className="company-info-blk company-info-blk-value" style={{ width: "calc(100%-40px) !important"}} >
            <b><p style={{ lineHeight: "1em", marginBottom: "0.5em" }}>Address</p></b>
            <p style={{ marginBottom: "0px", lineHeight: "1.2em" }}>{data.address}</p>
          </div>
        </div>
        <div>
          <div className="company-info-blk" >
            <Image
              src={theme === "light" ? AreaLogo : AreaDarkLogo}
              className="company-info-logo"
            />
          </div>
          <div className="company-info-blk company-info-blk-value" >
            <b><p style={{ lineHeight: "1em", marginBottom: "0.5em" }}>GFA (sqft)</p></b>
            <p>{showNumberWithCommas(data.area)}</p>
          </div>
        </div>
        <div>
          <div className="company-info-blk" >
            <Image
              src={theme === "light" ? GreenLogo : GreenDarkLogo}
              className="company-info-logo"
            />
          </div>
          <div className="company-info-blk company-info-blk-value" >
            <b><p style={{ lineHeight: "1em", marginBottom: "0.5em" }}>Green building rating</p></b>
            <p>{data.rating}</p>
          </div>
        </div>
        <div>
          <div className="company-info-blk" >
            <Image
              src={theme === "light" ? DateLogo : DateDarkLogo}
              className="company-info-logo-address"
            />
          </div>
          <div className="company-info-blk company-info-blk-value" >
            <b><p style={{ lineHeight: "1em", marginBottom: "0.5em" }}>Green building rating expiry date</p></b>
            <p>{data.green_mark_expiry_fmt}</p>
          </div>
        </div>
      </Col>
        <Col lg="6" md="6" sm="6" className="col-6">
          <div>
            <div className="company-info-blk" >
              <Image
                src={theme === "light" ? CountryLogo : CountryDarkLogo}
                className="company-info-logo"
              />
            </div>
            <div className="company-info-blk company-info-blk-value" >
              <b><p style={{ lineHeight: "1em", marginBottom: "0.5em" }}>Country</p></b>
              <p>{data.country}</p>
            </div>
          </div>
          <div>
            <div className="company-info-blk" >
              <Image
                src={theme === "light" ? CrateLogo : CrateDarkLogo}
                className="company-info-logo"
              />
            </div>
            <div className="company-info-blk company-info-blk-value" >
              <b><p style={{ lineHeight: "1em", marginBottom: "0.5em" }}>Asset class</p></b>
              <p>{data.building_type}</p>
            </div>
          </div>
          <div>
            <div className="company-info-blk" >
              <Image
                src={theme === "light" ? CapacityLogo : CapacityDarkLogo}
                className="company-info-logo"
              />
            </div>
            <div className="company-info-blk company-info-blk-value" >
              <b><p style={{ lineHeight: "1em", marginBottom: "0.5em" }}>Installed capacity</p></b>
              <p>{showNumberWithCommas(data.capacity)} RT</p>
            </div>
          </div>
        </Col>
      </Row>
    </Row>
  )

}

export default profileBlk;
