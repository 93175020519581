const passwordRegex = [
  /[A-Z]/,
  /[0-9]/,
  /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
  /^.{8,}$/,
];

const api = "https://api.kaerconnect.com/kaer";

const getCurrentSite = () => {
  var str = window.location.href;
  if (str.endsWith("/")) {
    str = str.substring(0, str.length - 1);
  }
  str = str.substring(str.lastIndexOf('/') + 1);
  str = str.trim();
  return str;
};

const config = {
  regexes: passwordRegex,
  url: "https://api.kaerconnect.com/kaer",
  api_endpoint: {
    LOGIN_USER: "/auth/login/",
    LOGOUT_USER: "/auth/logout/",
    GET_CSRF_TOKEN: "/auth/csrf/",
    USER_DETAILS: "/api/user/details/",
    GET_DASHBOARD_DATA: "/api/dashboard/",
    GET_NOTIFICATION_DATA: "/api/notification/",
    GET_LIVE_DATA: "/api/live/",
    GET_ADD_LIVE_DATA: "/api/live/add/",
    GET_CONTACT_DATA: "/api/contact/",
    CHECK_SESSION: "/auth/session/",
    GET_BILLING_INVOICE: "/api/invoice/",
    GET_TOKEN: "/api/cdn_token/",
    GET_DAILY_DATA: "/api/data/",
    GET_DAILY_MIN_DATA: "/api/data/graph",
    SET_DASHBOARD_SETTING: "/api/dashboard/settings/",
    GET_SOURCES: "/api/resource/",
    FAVOURITE_RESOURCE: "/api/resource/favourite/",
    SET_LIVE_SETTING: "/api/live/settings/",
    COMPANY_DETAILS: "/api/company/",
    INVOICE: "/api/invoice/",
    RESOURCE: "/api/resource/",
    SET_NOTIFICATION_SETTING: "/api/notification/settings/",
    SET_RATING: "/api/user/rating/",
    CHANGE_PASSWORD: "/api/user/change_password/",
    RESET_PASSWORD: "/api/user/reset_password/",
    FORCE_RESET_PASSWORD: "/api/user/force_reset_password/",
    USER_LIST: "/api/user/",
    GET_GROUPS_LIST: "/api/group/",
    GET_SITE: "/api/site/",
    GET_RESET_USER: "/api/user/reset_user/",
    CHECK_PASSWORD: "/auth/check_password/",
    RESEND_MAIL: "/api/user/resent_reset_email/",
    CHECK_RESOURCE: "/api/check_resource/",
  },
  getCurrentSite: getCurrentSite,
  googleAPIKey: "AIzaSyCsvLI--q5rNFAGwq0Cgr7IWkh6y11x1wQ"
};

export default config;
