const loginUserActionTypes = {
  LOGIN_USER_DETAIL: "GET_LOGGED_IN_USER_DETAIL",
  LOGIN_USER_DETAIL_SUCCESS: "GET_LOGGED_IN_USER_DETAIL_SUCCESS",
  LOGIN_USER_DETAIL_FAIL: "GET_LOGGED_IN_USER_DETAIL_FAIL",
  USER_LOGOUT: "USER_LOGOUT",
  STORE_TOKEN_USER: "STORE_TOKEN_USER",
  STORE_CSRF_TOKEN: "STORE_CSRF_TOKEN",
  STORE_LOGGED_IN_FLAG: "STORE_LOGGED_IN_FLAG",
};
export default loginUserActionTypes;
