import React, { useState, useEffect } from "react";
import DesktopTable from "./DesktopTable";
import "./style.css";
import { useMediaQuery } from "react-responsive";
import MobileTable from "./MobileTable";
import {
  Dropdown,
  Pagination,
  Spinner,
  Modal,
  Form,
  Button,
} from "react-bootstrap";
import { useFormik, userFormik } from "formik";
import * as Yup from "yup";
import api from "../../config/api";
import config from "../../config/config";
import { connect } from "react-redux";
import { fetchUserList } from "../../store/actions/userManagmentAction";

const Index = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showAlertBox, setShowAlertBox] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const isTablet = useMediaQuery({ maxWidth: 1100 });
  const isMobile = useMediaQuery({ maxWidth: 800 });
  let theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";
  const [selectId, setSelectId] = useState(null);
  const [selectUser, setSelectUser] = useState(null);
  const [selectType, setSelectType] = useState("");
  const { USER_LIST, GET_GROUPS_LIST, RESEND_MAIL } = config.api_endpoint;

  const getCurrentSite = config.getCurrentSite;

  let items = [];
  for (let number = 1; number <= props.total_pages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => setCurrentPage(number)}
        className="user_management_pagination_item"
      >
        {number}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    props.fetchUserList(
      getCurrentSite(),
      currentPage
    );
  }, [currentPage]);

  useEffect(() => {
    api
      .get(GET_GROUPS_LIST)
      .then((res) => {
        if (res.status === "Success") {
          if (res.results) {
            createFormformik.setFieldValue("groups", res.results[0].id);

            for (let dept of res.results) {
              if (dept.name === 'Operations') {
                createFormformik.setFieldValue("groups", dept.id);
                break;
              }
            }
          }
          setGroupList(res.results);
        }
      })
      .then((err) => {
        console.log("err", err);
      });
  }, []);

  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const formValidationSchema = Yup.object({
    // username: Yup.string().required("Required Field."),
    name: Yup.string().required("Required Field."),
    email: Yup.string()
      .email("Invalid email format.")
      .required("Required Field.")
      .min(6, "Your password is too short."),
    // password: Yup.string().required("Required Field."),
    // confirm_password: Yup.string()
    //   .required("Required Field.")
    //   .oneOf([Yup.ref("password")], "Your passwords do not match."),

    img: Yup.mixed()
      // .required("Required Field.")
      .when("has_file", {
        is: true,
        then: Yup.mixed().test(
          "fileFormat",
          "Allow only JPG, JPEG, PNG",
          (value) => {
            return value && SUPPORTED_FORMATS.includes(value.type);
          }
        ),
      }),
  });

  const createFormformik = useFormik({
    initialValues: {
      username: "",
      name: "",
      email: "",
      password: "",
      confirm_password: "",
      groups: "",
      img: "",
      has_file: false,
    },
    onSubmit: (values) => {
      let fd = new FormData();
      // fd.append("username", values.username);
      fd.append("name", values.name);
      fd.append("email", values.email);
      // fd.append("password", values.password);
      fd.append("groups", values.groups);
      fd.append("img", values.img);
      fd.append("site_id", getCurrentSite());
      fd.append("is_active", true);
      // return console.log(">> ", ...fd);
      api
        .post(USER_LIST, fd, props.CSRFToken)
        .then((res) => {
          props.fetchUserList(
            getCurrentSite(),
            currentPage
          );
          createFormformik.resetForm();
          handleClose();
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    validationSchema: formValidationSchema,
  });

  const sortGroup = (groups) => {
    let operation_group = groups.find(x => x.name.toLowerCase() === 'operations');
    if (operation_group) {
      let newGroups = groups.filter(x => x.name.toLowerCase() !== 'operations');
      newGroups.unshift(operation_group)
      return newGroups;
    }
    return groups;
  }

  const modalForm = () => {
    return (
      <Modal
        show={showForm}
        onHide={() => {
          createFormformik.resetForm();
          handleClose();
        }}
        className={`modalForm ${theme}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Username*</Form.Label>
            <Form.Control
              type="text"
              name="username"
              onChange={createFormformik.handleChange}
              value={createFormformik.values.username}
              onBlur={createFormformik.handleBlur}
            />
            {createFormformik.touched.username &&
              createFormformik.errors.username && (
                <Form.Text style={{ color: "red" }}>
                  {createFormformik.errors.username}
                </Form.Text>
              )}
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email*</Form.Label>
            <Form.Control
              type="text"
              name="email"
              onChange={createFormformik.handleChange}
              value={createFormformik.values.email}
              onBlur={createFormformik.handleBlur}
            />
            {createFormformik.touched.email &&
              createFormformik.errors.email && (
                <Form.Text style={{ color: "red" }}>
                  {createFormformik.errors.email}
                </Form.Text>
              )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Display Name*</Form.Label>
            <Form.Control
              type="text"
              name="name"
              onChange={createFormformik.handleChange}
              value={createFormformik.values.name}
              onBlur={createFormformik.handleBlur}
            />
            {createFormformik.touched.name && createFormformik.errors.name && (
              <Form.Text style={{ color: "red" }}>
                {createFormformik.errors.name}
              </Form.Text>
            )}
          </Form.Group>

          {/* <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Password*</Form.Label>
            <Form.Control
              type="password"
              name="password"
              onChange={createFormformik.handleChange}
              value={createFormformik.values.password}
              onBlur={createFormformik.handleBlur}
            />
            {createFormformik.touched.password &&
              createFormformik.errors.password && (
                <Form.Text style={{ color: "red" }}>
                  {createFormformik.errors.password}
                </Form.Text>
              )}
          </Form.Group> */}

          {/* <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Confirm Password*</Form.Label>
            <Form.Control
              type="password"
              name="confirm_password"
              onChange={createFormformik.handleChange}
              value={createFormformik.values.confirm_password}
              onBlur={createFormformik.handleBlur}
            />
            {createFormformik.touched.confirm_password &&
              createFormformik.errors.confirm_password && (
                <Form.Text style={{ color: "red" }}>
                  {createFormformik.errors.confirm_password}
                </Form.Text>
              )}
          </Form.Group> */}

          <Form.Group className="mb-3">
            <Form.Label>Groups *</Form.Label>
            <Form.Select
              onChange={(e) => {
                createFormformik.setFieldValue("groups", e.target.value);
              }}
              defaultValue={createFormformik.values.groups}
            >
              {groupList &&
                sortGroup(groupList).map((g) => (
                  <option
                    value={g.id}
                    selected={
                      createFormformik.values.groups === g.id && "selected"
                    }
                  >
                    {g.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Profile Picture (Optional)</Form.Label>
            <div>
              {createFormformik.values.img && (
                <div className="modal_attach_input_file_name">
                  {createFormformik.values.img.name}
                </div>
              )}

              <div className="modal_attach_input_file">
                Upload
                <input
                  type="file"
                  name="img"
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      createFormformik.setFieldValue("has_file", true);
                      createFormformik.setFieldValue("img", e.target.files[0]);
                    } else {
                      createFormformik.setFieldValue("has_file", false);
                    }
                  }}
                  onBlur={createFormformik.handleBlur}
                />
              </div>
            </div>

            {createFormformik.touched.img && createFormformik.errors.img && (
              <Form.Text style={{ color: "red" }}>
                {createFormformik.errors.img}
              </Form.Text>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="button"
            onClick={() => {
              createFormformik.handleSubmit();
            }}
          >
            Save
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              createFormformik.resetForm();
              handleClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const handleClose = () => {
    console.log('handleClose.')
    setShowForm(false)
    setShowUpdateForm(false)
  };
  const handleShow = () => setShowForm(true);

  const updateformValidationSchema = Yup.object({
    name: Yup.string().required("Required Field."),
    email: Yup.string()
      .email("Invalid email format.")
      .required("Required Field.")
      .min(6, "Your password is too short."),

    img: Yup.mixed().when("has_file", {
      is: true,
      then: Yup.mixed()
        .required("Required Field.")
        .test("fileFormat", "Allow only JPG, JPEG, PNG", (value) => {
          return value && SUPPORTED_FORMATS.includes(value.type);
        }),
    }),
  });

  const updateFormformik = useFormik({
    initialValues: {
      name: "",
      email: "",
      groups: "",
      img: "",
      has_img: false,
      is_url_file: false,
    },
    onSubmit: (values) => {
      let fd = new FormData();
      fd.append("name", values.name);
      fd.append("email", values.email);
      fd.append("groups", values.groups);
      values.has_file && fd.append("img", values.img);

      fd.append("site_id", getCurrentSite());
      // fd.append("is_active", true);
      fd.append("id", selectId);
      console.log("fd", ...fd);
      api
        .post(USER_LIST, fd, props.CSRFToken)
        .then((res) => {
          props.fetchUserList(
            getCurrentSite(),
            currentPage
          );
          handleCloseUpdate();
          console.log("UPDATE : ", res);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    validationSchema: updateformValidationSchema,
  });

  const getDetailValue = (value) => {
    if (value) {
      updateFormformik.setFieldValue("name", value.full_name);
      updateFormformik.setFieldValue("email", value.email);
      updateFormformik.setFieldValue(
        "groups",
        value.groups.length > 0 ? value.groups[0].id : groupList[0].id
      );
      updateFormformik.setFieldValue("img", value.img);
      setSelectId(value.id);
      updateFormformik.setFieldValue("is_url_file", value.img && true);
    }
  };
  const handleCloseUpdate = () => setShowUpdateForm(false);
  const handleShowUpdate = () => setShowUpdateForm(true);

  const modalUpdateForm = () => {
    return (
      <Modal
        show={showUpdateForm}
        onHide={() => {
          updateFormformik.resetForm();
          handleCloseUpdate();
        }}
        className={`modalForm ${theme}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email*</Form.Label>
            <Form.Control
              type="text"
              name="email"
              onChange={updateFormformik.handleChange}
              value={updateFormformik.values.email}
              onBlur={updateFormformik.handleBlur}
            />
            {updateFormformik.touched.email &&
              updateFormformik.errors.email && (
                <Form.Text style={{ color: "red" }}>
                  {updateFormformik.errors.email}
                </Form.Text>
              )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Display Name*</Form.Label>
            <Form.Control
              type="text"
              name="name"
              onChange={updateFormformik.handleChange}
              value={updateFormformik.values.name}
              onBlur={updateFormformik.handleBlur}
            />
            {updateFormformik.touched.name && updateFormformik.errors.name && (
              <Form.Text style={{ color: "red" }}>
                {updateFormformik.errors.name}
              </Form.Text>
            )}
          </Form.Group>

          {/* <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Password*</Form.Label>
            <Form.Control
              type="password"
              name="password"
              onChange={updateFormformik.handleChange}
              value={updateFormformik.values.password}
              onBlur={updateFormformik.handleBlur}
            />
            {updateFormformik.touched.password &&
              updateFormformik.errors.password && (
                <Form.Text style={{ color: "red" }}>
                  {updateFormformik.errors.password}
                </Form.Text>
              )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Confirm Password*</Form.Label>
            <Form.Control
              type="password"
              name="confirm_password"
              onChange={updateFormformik.handleChange}
              value={updateFormformik.values.confirm_password}
              onBlur={updateFormformik.handleBlur}
            />
            {updateFormformik.touched.confirm_password &&
              updateFormformik.errors.confirm_password && (
                <Form.Text style={{ color: "red" }}>
                  {updateFormformik.errors.confirm_password}
                </Form.Text>
              )}
          </Form.Group> */}

          <Form.Group className="mb-3">
            <Form.Label>Groups *</Form.Label>
            <Form.Select
              onChange={(e) => {
                updateFormformik.setFieldValue("groups", e.target.value);
              }}
              defaultValue={updateFormformik.values.groups}
            >
              {groupList &&
                groupList.map((g) => (
                  <option
                    value={g.id}
                    selected={
                      updateFormformik.values.groups === g.id && "selected"
                    }
                  >
                    {g.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Profile Picture (Optional)</Form.Label>
            <div>
              {updateFormformik.values.has_file ? (
                updateFormformik.values.img &&
                updateFormformik.values.img.name && (
                  <div className="modal_attach_input_file_name">
                    {updateFormformik.values.img.name}
                  </div>
                )
              ) : updateFormformik.values.is_url_file ? (
                <div className="modal_attach_input_file_name">
                  {updateFormformik.values.img}
                </div>
              ) : null}

              <div className="modal_attach_input_file">
                Upload
                <input
                  type="file"
                  name="img"
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      updateFormformik.setFieldValue("img", e.target.files[0]);
                      updateFormformik.setFieldValue("has_file", true);
                    } else {
                      updateFormformik.setFieldValue("has_file", false);
                    }
                  }}
                  onBlur={updateFormformik.handleBlur}
                />
              </div>
            </div>

            {updateFormformik.touched.img && updateFormformik.errors.img && (
              <Form.Text style={{ color: "red" }}>
                {updateFormformik.errors.img}
              </Form.Text>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="button"
            onClick={() => {
              updateFormformik.handleSubmit();
            }}
          >
            Save
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              updateFormformik.resetForm();
              handleClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const alertBox = () => {
    return (
      <Modal
        show={showAlertBox}
        onHide={() => {
          handleCloseAletBox();
        }}
        className={`modalForm ${theme}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {selectType == "delete" ? (
            "Are you sure you want to delete this user?"
          ) : selectType == "mail_resend" ? (
            <>
              Are you sure you want to resend <b>{selectUser.email} </b>
              mail?
            </>
          ) : (
            `Are you sure you want to ${
              selectUser.is_active ? "disable" : "enable"
            } this user? `
          )}{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={`${selectType == "mail_resend" ? "primary" : "danger"}`}
            type="button"
            onClick={() => {
              if (selectType == "delete") {
                let fd = new FormData();
                fd.append("id", selectUser.id);
                api
                  .remove(USER_LIST, fd, props.CSRFToken)
                  .then((res) => {
                    console.log("DELETE: ", res);
                    if (res.status == "Success") {
                      handleCloseAletBox();
                      props.fetchUserList(
                        getCurrentSite(),
                        1
                      );
                    }
                  })
                  .catch((err) => {
                    console.log("err".err);
                  });
              } else if (selectType == "mail_resend") {
                let fd = new FormData();
                fd.append("user_id", selectUser.id);
                console.log("selectU", selectUser);
                api
                  .post(RESEND_MAIL, fd, props.CSRFToken)
                  .then((res) => {
                    handleCloseAletBox();
                    console.log("res", res);
                    if (res.status == "Error") {
                      return alert(res.details);
                    }
                  })
                  .catch((err) => {
                    console.log("err", err);
                  });
              } else {
                let fd = new FormData();
                fd.append("id", selectUser.id);

                fd.append("is_active", selectUser.is_active ? false : true);
                api
                  .post(USER_LIST, fd, props.CSRFToken)
                  .then((res) => {
                    console.log("Active: ", res);
                    if (res.status == "Success") {
                      handleCloseAletBox();
                      props.fetchUserList(
                        getCurrentSite(),
                        1
                      );
                    }
                  })
                  .catch((err) => {
                    console.log("err".err);
                  });
              }
            }}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              handleCloseAletBox();
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const handleCloseAletBox = () => setShowAlertBox(false);
  const handleShowAletBox = () => setShowAlertBox(true);

  return (
    <div className="mobile_down">
      {modalForm()}
      {modalUpdateForm()}
      {showAlertBox && alertBox()}
      <div className={`user_management  ${theme}`}>
        <div className="user_management_container_header">
          <div>
            <div className="main_title">User Management</div>
          </div>
          <div className="user_management_button">
            <button
              className="btn btn-dark d-flex justify-content-around align-items-center"
              onClick={handleShow}
            >
              <div>Create a User</div>
            </button>
          </div>
        </div>
        {props.loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 0px",
            }}
            className="resource_table_spinnser_container"
          >
            <Spinner animation="border" size="md" />
          </div>
        ) : isTablet ? (
          <MobileTable
            getDetailValue={getDetailValue}
            handleShowUpdate={handleShowUpdate}
            handleShowAletBox={handleShowAletBox}
            setSelectUser={setSelectUser}
            setSelectType={setSelectType}
          />
        ) : (
          <DesktopTable
            getDetailValue={getDetailValue}
            handleShowUpdate={handleShowUpdate}
            handleShowAletBox={handleShowAletBox}
            setSelectUser={setSelectUser}
            setSelectType={setSelectType}
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: isMobile && 30,
          }}
        >
          <Pagination>{items}</Pagination>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  CSRFToken: state.loginUserDetail.csrf,
  AuthDetail: state.loginUserDetail.userDetail,
  total_pages: state.usersList.total_pages,
  loading: state.usersList.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserList: (site_id, current_page) =>
    dispatch(fetchUserList(site_id, current_page)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Index);
