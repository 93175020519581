import React, { useEffect, useState } from "react";
// import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useMediaQuery } from "react-responsive";
import "../preMonthChart.css";
import { abbreviateNumber } from "js-abbreviation-number";

const PeakChart = React.memo(({ theme, data_count }) => {
  const [count, setCount] = useState([0, 0, 0, 0]);
  const isDesktop = useMediaQuery({ minWidth: 1268 });
  const isMobile = useMediaQuery({ maxWidth: 768 });
  // useEffect(() => {
  //   if (data_count && data_count.graph && data_count.graph.peak_cooling) {
  //     let datas = [];

  //     Object.entries(data_count.graph.peak_cooling).map(([key, value]) => {
  //       datas.push(value);
  //     });
  //     setCount(datas);
  //   }
  // }, [data_count]);
  useEffect(() => {
    if (data_count && data_count.graph && data_count.graph.peak_cooling) {
      let cloneCount = [];

      Object.entries(data_count.graph.peak_cooling).map(([key, value]) => {
        if (value !== 0) {
          cloneCount.push({
            uv: value,
            name: key,
          });
          // cloneMonthNames[search].uv = d.amount;
        } else {
          cloneCount.push({
            uv: 0,
            name: key,
          });
          // cloneCount[search].uv = 0;
        }
      });
      let result = cloneCount.map((y) => y.uv);
      let maxValue = Math.max.apply(null, result);

      cloneCount.map((c) => {
        c.pv = "100%";
      });

      setCount(cloneCount);
    }
  }, [data_count]);
  const formatter = (value) => `${abbreviateNumber(value).toLocaleString()}`;

  var tooltip;
  const CustomTooltip = ({ active, payload }) => {
    if (!active || !tooltip) return null;
    for (const bar of payload)
      if (bar.dataKey === tooltip) {
        return (
          <div
            style={{
              background: "rgba(0,0,0,.8)",
              borderRadius: 8,
              color: "white",
              fontWeight: "bold",
              fontSize: 11,
              padding: 8,
              minWidth: 50,
            }}
          >
            <div>{bar.payload.name}</div>
            {Number(bar.value).toLocaleString()}RT
          </div>
        );
      }

    return null;
  };

  return (
    <div
      className={`data_chart_container ${theme} ${
        count.length >= 12 ? "over_12_bars" : null
      }`}
    >
      <h1
        className="title"
        style={{
          width: "200",
          color: theme == "dark" && "#fff",
        }}
      >
        Peak cooling requirements (RT)
      </h1>
      <div style={{ height: 180 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={550}
            height={300}
            data={count}
            margin={{
              top: 20,
            }}
          >
            <CartesianGrid strokeDasharray="" />
            <XAxis
              dataKey="name"
              angle={isDesktop ? 0 : -45}
              interval={0}
              // dx={-7}
            />
            <YAxis
              tickFormatter={formatter}
              tick={{ fontSize: 13 }}
              width={50}
              domain={[0, `dataMax`]}
            />
            <Tooltip cursor={false} content={<CustomTooltip />} />

            <Bar
              dataKey="pv"
              stackId="a"
              barSize={10}
              fill="#E5E5F1"
              radius={[10, 10, 0, 0]}
            />
            <Bar
              dataKey="uv"
              stackId="a"
              fill="#00A6EF"
              barSize={10}
              radius={[10, 10, 0, 0]}
              onMouseOver={() => (tooltip = "uv")}
            />
          </BarChart>
        </ResponsiveContainer>
        {/* <Bar options={options} data={data(count)} /> */}
      </div>
    </div>
  );
});

const mapStateToProps = (state) => ({
  data_count: state.data.data_count,
});
export default connect(mapStateToProps, null)(PeakChart);
