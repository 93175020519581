import resourcesAcionTypes from "../actions/types/resourcesAcionTypes";

const { GET_RESOURCE, GET_RESOURCE_SUCCESS, GET_RESOURCE_FAIL } =
  resourcesAcionTypes;

const intialState = {
  resources_list: [],
  loading: false,
  total_pages: 1,
};

const resourceReducer = (state = intialState, action) => {
  if (action.type == "UPDATE_FAVOURITE_SUCCESS") {
    let cloneResourceList = state.resources_list;
    let searchIndex = cloneResourceList.findIndex(
      (c) => c.id === action.payload.resource_id
    );

    if (searchIndex !== -1) {
      cloneResourceList[searchIndex].is_favourite =
        !action.payload.is_favourite;
    }
  }
  switch (action.type) {
    case GET_RESOURCE:
      return {
        ...state,
        loading: true,
      };
    case GET_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        resources_list: action.payload.results,
        total_pages: Math.ceil(action.payload.total / action.pageSize),
      };
    case "UPDATE_FAVOURITE_SUCCESS":
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default resourceReducer;
