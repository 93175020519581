import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Modal, Image } from "react-bootstrap";
import * as wjCore from "wijmo/wijmo";
import api from "../../config/api";
import config from "../../config/config";
import { connect } from "react-redux";
import {
  userLogout,
  getUserLoginDetail,
} from "../../store/actions/loginUserAction";

// views
import StatsBlk from "../../components/liveStatsBlk";
import ChartBlk from "../../components/chartBlk";
import BlankBlk from "../../components/blankBlk";
import NotiBlk from "../../components/notificationBlk";
import ContactBlk from "../../components/contactBlk";
import DataBlk from "../../components/dataBlk";
import ProfileBlk from "../../components/profileBlk";
import BillingBlk from "../../components/billingBlk";
import LiveBlk from "../../components/liveBlk";
import ResourceBlk from "../../components/resourceBlk";

// css
import "./dashboard.css";

// images
import LiveIcon from "../../assets/images/navigation/live.svg";
import LiveAltIcon from "../../assets/images/navigation/live-alt.svg";
import DataIcon from "../../assets/images/navigation/data.svg";
import DataAltIcon from "../../assets/images/navigation/data-alt.svg";
import BillingIcon from "../../assets/images/navigation/billing.svg";
import BillingAltIcon from "../../assets/images/navigation/billing-alt.svg";
import ResourcesIcon from "../../assets/images/navigation/resources.svg";
import ResourcesAltIcon from "../../assets/images/navigation/resources-alt.svg";
import InsightsIcon from "../../assets/images/navigation/insights.svg";
import InsightsAltIcon from "../../assets/images/navigation/insights-alt.svg";
import NotificationsIcon from "../../assets/images/navigation/notifications.svg";
import NotificationsAltIcon from "../../assets/images/navigation/notifications-alt.svg";
import ContactIcon from "../../assets/images/navigation/contact.svg";
import ContactAltIcon from "../../assets/images/navigation/contact-alt.svg";
import Avatar from "../../assets/images/navigation/avatar.png";
import SpaceIcon from "../../assets/images/live/space.svg";
import SpaceDarkIcon from "../../assets/images/live/space-dark.svg";
import LayersIcon from "../../assets/images/live/layers.svg";
import LayersDarkIcon from "../../assets/images/live/layers-dark.svg";
import CubeIcon from "../../assets/images/live/cube.svg";
import CubeDarkIcon from "../../assets/images/live/cube-dark.svg";

function Dashboard(props) {
  function splitArr(arr) {
    var bigArr = [[], [], []];
    var count = 0;

    for (var i = 0; i < arr.length; i++) {
      bigArr[count].push(arr[i]);
      if (count === 2) {
        count = 0;
      } else {
        count += 1;
      }
    }

    return { arr: bigArr, count: count };
  }

  var theme = localStorage.getItem("theme");
  const [dashConfig, setDashConfig] = useState([]);
  const isInitialRender = useRef(true);
  const shouldUpdateSettings = useRef(true);

  const { GET_DASHBOARD_DATA, GET_NOTIFICATION_DATA, SET_DASHBOARD_SETTING } =
    config.api_endpoint;
  const [selected_tile, setSelectedTile] = useState("");
  const [showTile, setShowTile] = useState(false);
  const [colA, setColA] = useState([]);
  const [colB, setColB] = useState([]);
  const [colC, setColC] = useState([]);
  const [blankShouldBeHere, setBlankShouldBeHere] = useState(0);
  const [tiles, setTiles] = useState([]);
  const [liveData, setLiveData] = useState({ room_list: [] });
  const [tileMainData, setTileMainData] = useState({});

  // kaer air space blocks
  const [spaceCats, setSpaceCats] = useState(["Main"]);
  const [spaceFloors, setSpaceFloors] = useState(["All"]);
  const [spaceTypes, setSpaceTypes] = useState(["All"]);

  const [showSpaceDialog, setShowSpaceDialog] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedSpaceFloor, setSelectedSpaceFloor] = useState("All");
  const [selectedSpaceType, setSelectedSpaceType] = useState("All");
  const [selectedSpaceCat, setSelectedSpaceCat] = useState("Main");
  const [spaceBlocks, setSpaceBlocks] = useState([]);
  const [spaceClickedFrom, setSpaceClickedFrom] = useState("");
  const [airDialogClickedFrom, setAirDialogClickedFrom] = useState("");
  const [airDialogWidget, setAirDialogWidget] = useState("");
  const [loading, setLoading] = useState(false);

  const pauseSetInternval = useRef(false);

  var currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  currentDate =
    currentDate.getFullYear() +
    "-" +
    (currentDate.getMonth() + 1) +
    "-" +
    currentDate.getDate();

  var from_ts = currentDate;
  var to_ts = currentDate;
  const [selectedDataRange, setSelectedDataRange] = useState("Previous Day");

  const { AuthDetail, AuthLoggedIn, CSRFToken } = props;
  var dashboardInterval = null;

  var all_available_resource_types = {
    equipment_and_operations: "Equipment & operations",
    "as-built_drawings": "As-built drawings",
    maintenance_and_water_reports: "Maintenance & Water reports",
    cooling_tower_information: "Cooling tower information",
    iaq_reports: "IAQ reports",
  };

  const getCurrentSite = config.getCurrentSite;

  const calculateIndicatorDeg = (tmp, max) => {
    var per = (180 / max) * tmp;
    return parseInt(per);
  };

  const getDateRangeForData = (range) => {
    var current = new Date();
    var fromDate = "";
    var toDate = "";

    if (range === "day") {
      var tmp = new Date();
      tmp.setDate(current.getDate() - 1);
      fromDate =
        tmp.getFullYear() + "-" + (tmp.getMonth() + 1) + "-" + tmp.getDate();
      toDate =
        tmp.getFullYear() + "-" + (tmp.getMonth() + 1) + "-" + tmp.getDate();
      setSelectedDataRange("Previous Day");
    } else if (range === "week") {
      var tmp = new Date();
      tmp.setDate(current.getDate() - 1);
      var tmp2 = new Date();
      tmp2.setDate(current.getDate() - 7);
      fromDate =
        tmp2.getFullYear() + "-" + (tmp2.getMonth() + 1) + "-" + tmp2.getDate();
      toDate =
        tmp.getFullYear() + "-" + (tmp.getMonth() + 1) + "-" + tmp.getDate();
      setSelectedDataRange("Previous Week");
    } else if (range === "month") {
      var tmp = new Date();
      tmp.setDate(current.getDate() - 1);
      var tmp2 = new Date();
      tmp2.setDate(current.getDate() - 30);
      fromDate =
        tmp2.getFullYear() + "-" + (tmp2.getMonth() + 1) + "-" + tmp2.getDate();
      toDate =
        tmp.getFullYear() + "-" + (tmp.getMonth() + 1) + "-" + tmp.getDate();
      setSelectedDataRange("Previous Month");
    } else if (range === "year") {
      var tmp = new Date();
      tmp.setDate(current.getDate() - 1);
      var tmp2 = new Date();
      tmp2.setDate(current.getDate() - 365);
      fromDate =
        tmp2.getFullYear() + "-" + (tmp2.getMonth() + 1) + "-" + tmp2.getDate();
      toDate =
        tmp.getFullYear() + "-" + (tmp.getMonth() + 1) + "-" + tmp.getDate();
      setSelectedDataRange("Previous Year");
    }

    return { from: fromDate, to: toDate };
  };

  const resoChoiceClicked = (event) => {
    event.preventDefault();
    var choice = event.target.getAttribute("data-choice").trim();
    var relatedData = tileMainData["resources"][choice];
    var choiceElement =
      event.target.closest("ul").previousSibling.previousSibling;
    var id = choiceElement.id.replace("reso_", "");

    console.log(
      "=== resoChoiceClicked === ",
      id,
      choice,
      all_available_resource_types[choice]
    );

    var tmpTiles = tiles.slice();
    var pointer = 0;

    tmpTiles.forEach(function (item, index) {
      if (item["id"] == id) {
        tmpTiles[index]["data"] = relatedData;
        tmpTiles[index]["chosen_text"] = all_available_resource_types[choice];
        tmpTiles[index]["chosen_type"] = choice;

        document.getElementById("reso_" + id).innerText =
          all_available_resource_types[choice];

        pointer = index;
      }
    });

    pointer = pointer + 1;

    shouldUpdateSettings.current = true;

    var tmpDashConfig = dashConfig.slice();
    tmpDashConfig[pointer]["selected"] = choice;
    setDashConfig(tmpDashConfig);

    setTiles(tmpTiles);
    var tmp = splitArr(tmpTiles);
    setColA(tmp["arr"][0]);
    setColB(tmp["arr"][1]);
    setColC(tmp["arr"][2]);
    setBlankShouldBeHere(tmp["count"]);
  };

  const dataChoiceClicked = (event) => {
    event.preventDefault();
    var choice = event.target.getAttribute("data-choice").trim();
    var relatedData = tileMainData["data"][choice];
    var choiceElement =
      event.target.closest("ul").previousSibling.previousSibling;
    var id = choiceElement.id.replace("data_", "");

    // console.log("current text: ", document.getElementById("data_" + id).innerText, choice.charAt(0).toUpperCase() + choice.slice(1));
    // console.log("choiceElement: ", choice, choiceElement, id, relatedData);
    // console.log("new text: ", "Previous " + choice.charAt(0).toUpperCase() + choice.slice(1));

    var tmpTiles = tiles.slice();
    var pointer = 0;

    tmpTiles.forEach(function (item, index) {
      if (item["id"] == id) {
        tmpTiles[index]["data"] = relatedData;
        tmpTiles[index]["chosen_date"] =
          "Previous " + choice.charAt(0).toUpperCase() + choice.slice(1);

        document.getElementById("data_" + id).innerText =
          "Previous " + choice.charAt(0).toUpperCase() + choice.slice(1);
        document.getElementById("peak_cooling_" + id).innerHTML =
          relatedData["peak_cooling"];
        document.getElementById("electricity_used_" + id).innerHTML =
          relatedData["electricity_used"];
        document.getElementById("efficiency_" + id).innerHTML =
          relatedData["efficiency"];
        document.getElementById("cooling_delivered_" + id).innerHTML =
          relatedData["cooling_delivered"];
        document.getElementById("water_used_" + id).innerHTML =
          relatedData["water_used"];
        document.getElementById("carbon_emission_" + id).innerHTML =
          relatedData["carbon_emission"];

        pointer = index;
      }
    });

    pointer = pointer + 1;

    shouldUpdateSettings.current = true;

    var tmpDashConfig = dashConfig.slice();
    tmpDashConfig[pointer]["selected"] = choice;
    setDashConfig(tmpDashConfig);

    setTiles(tmpTiles);
    var tmp = splitArr(tmpTiles);
    setColA(tmp["arr"][0]);
    setColB(tmp["arr"][1]);
    setColC(tmp["arr"][2]);
    setBlankShouldBeHere(tmp["count"]);
  };

  const readNotification = (event) => {
    var id = event.target.getAttribute("data-id");

    if (id.includes(",")) {
      id = id.split(",");
    }

    setLoading(true);
    api
      .post(GET_NOTIFICATION_DATA, JSON.stringify({ noti_id: id }), CSRFToken)
      .then((res) => {
        console.log("Dashboard notification read: ", res);
        setLoading(false);
        loadDashboardData();
      })
      .catch((err) => {
        console.log("Dashboard notification read err", err);
        setLoading(false);
      });
  };

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // event
  const handleClose = (clicked) => {
    shouldUpdateSettings.current = true;

    if (clicked === "data") {
      setTiles([
        ...tiles,
        {
          id: Math.floor(Math.random() * 1000 + 1),
          tile_type: "Data",
          tile_heading: "Data",
          chart_type: "",
          chart_title: "",
          chosen_date:
            "Previous " + "day".charAt(0).toUpperCase() + "day".slice(1),
          data: tileMainData["data"]["day"],
        },
      ]);

      setDashConfig([...dashConfig, { name: "data", selected: "day" }]);
    } else if (clicked === "live") {
      var live_data = {
        power: "-",
        temp: 0,
        cooling: 0,
        min_cooling: 150,
        max_cooling: 2200,
        confer_temp: 0,
        water: 0,
      };

      if (tileMainData["live_kw"] && tileMainData["live_ka"]) {
        live_data["power"] = tileMainData["live_kw"]["system_status"];
        live_data["temp"] = tileMainData["live_kw"]["water_temp"];
        live_data["cooling"] = tileMainData["live_kw"]["cooling_amount"];
        live_data["rooms"] = [];
        live_data["confer_temp"] = "-";
        live_data["water"] = "-";
        live_data["chosen_room"] = "-";
      }

      if (tileMainData["live_ka"]["rooms"].length > 0) {
        live_data["chosen_room"] = tileMainData["live_ka"]["rooms"][0]["name"];
        live_data["rooms"] = tileMainData["live_ka"]["rooms"];
        live_data["confer_temp"] =
          tileMainData["live_ka"]["rooms"][0]["temperature"];
        live_data["water"] = tileMainData["live_ka"]["rooms"][0]["humidity"];
      }

      setTiles([
        ...tiles,
        {
          id: Math.floor(Math.random() * 1000 + 1),
          tile_type: "Live",
          tile_heading: "Live",
          chart_type: "",
          chart_title: "",
          data: live_data,
          selected: tileMainData["live_ka"]["rooms"][0]["name"],
        },
      ]);

      setDashConfig([
        ...dashConfig,
        { name: "live", selected: tileMainData["live_ka"]["rooms"][0]["name"] },
      ]);
    } else if (clicked === "notifications") {
      setTiles([
        ...tiles,
        {
          id: Math.floor(Math.random() * 1000 + 1),
          tile_type: "Notification",
          tile_heading: "Notifications",
          chart_type: "",
          chart_title: "Clear All",
          data: tileMainData["notifications"],
          readNotification: readNotification,
        },
      ]);

      setDashConfig([...dashConfig, { name: "notification", selected: "" }]);
    } else if (clicked === "contact") {
      setTiles([
        ...tiles,
        {
          id: Math.floor(Math.random() * 1000 + 1),
          tile_type: "Contact",
          tile_heading: "Contact",
          chart_type: "",
          chart_title: "",
          data: tileMainData["contacts"],
        },
      ]);

      setDashConfig([...dashConfig, { name: "contact", selected: "" }]);
    } else if (clicked === "billing") {
      setTiles([
        ...tiles,
        {
          id: Math.floor(Math.random() * 1000 + 1),
          tile_type: "Billing",
          tile_heading: "Billing",
          chart_type: "",
          chart_title: "",
          data: tileMainData["invoices"],
        },
      ]);

      setDashConfig([...dashConfig, { name: "billing", selected: "" }]);
    } else if (clicked === "profile") {
      setTiles([
        ...tiles,
        {
          id: Math.floor(Math.random() * 1000 + 1),
          tile_type: "Profile",
          tile_heading: "Profile",
          chart_type: "",
          chart_title: "",
          data: tileMainData["profile"],
        },
      ]);

      setDashConfig([...dashConfig, { name: "profile", selected: "" }]);
    } else if (clicked === "resource") {
      setTiles([
        ...tiles,
        {
          id: Math.floor(Math.random() * 1000 + 1),
          tile_type: "Resource",
          tile_heading: "Resources",
          chart_type: "",
          chart_title: "",
          chosen_type: "equipment_and_operations",
          chosen_text: "Equipment & operations",
          data: tileMainData["resources"]["equipment_and_operations"],
        },
      ]);

      setDashConfig([
        ...dashConfig,
        { name: "resource", selected: "equipment_and_operations" },
      ]);
    }

    var tmp = splitArr(tiles);
    setColA(tmp["arr"][0]);
    setColB(tmp["arr"][1]);
    setColC(tmp["arr"][2]);
    setBlankShouldBeHere(tmp["count"]);

    setSelectedTile("");
    setShowTile(false);
  };

  const handleShow = () => {
    setSelectedTile("");
    setShowTile(true);
  };

  const menuClicked = (event) => {
    var clicked = event.target.getAttribute("data-menu");

    if (clicked) {
      setSelectedTile(clicked);
      handleClose(clicked);
    }
  };

  const removeWidgetClicked = (event) => {
    event.preventDefault();
    var widgetID = parseInt(event.target.getAttribute("data-id"));
    var tmpTiles = tiles.slice();
    shouldUpdateSettings.current = true;

    tmpTiles.forEach(function (item, index) {
      if (item["id"] === widgetID) {
        tmpTiles.splice(index, 1);
        setTiles(tmpTiles);

        var tmpDashConfig = dashConfig.slice();
        tmpDashConfig.splice(index + 1, 1);
        setDashConfig(tmpDashConfig);
      }
    });
  };

  const getIndex = (e) => {
    const p = e.parentElement;
    for (let i = 0; i < p.children.length; i++) {
      if (p.children[i] === e) return i;
    }
    return -1;
  };

  const getColArr = (arr, str) => {
    if (str === "colA") {
      return arr[0];
    } else if (str === "colB") {
      return arr[1];
    } else {
      return arr[2];
    }
  };

  const setColArr = (str, arr, mainArr) => {
    if (str === "colA") {
      mainArr[0] = arr;
    } else if (str === "colB") {
      mainArr[1] = arr;
    } else {
      mainArr[2] = arr;
    }
  };

  const concatColArr = (arr) => {
    var tmp = [];
    for (let i = 0; i < arr[0].length; i++) {
      tmp.push(arr[0][i]);
      if (i < arr[1].length) {
        tmp.push(arr[1][i]);
      }
      if (i < arr[2].length) {
        tmp.push(arr[2][i]);
      }
    }
    return tmp;
  };

  let dragSource = null;
  let dropTarget = null;

  const dashboardDragStart = (e) => {
    console.log("dashboardDragStart e:", e);
    const target = wjCore.closest(e.target, ".tile");
    console.log("target:", target);
    if (
      target &&
      target.parentElement.parentElement ===
        document.querySelector(".dashboard")
    ) {
      dragSource = target;
      wjCore.addClass(dragSource, "drag-source");
      const dt = e.dataTransfer;
      dt.effectAllowed = "move";
      dt.setData("text", dragSource.innerHTML);
    }
  };

  const dashboardDragOver = (e) => {
    if (dragSource) {
      let tile = wjCore.closest(e.target, ".tile");
      if (tile === dragSource) {
        tile = null;
      }
      if (dragSource && tile && tile !== dragSource) {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
      }
      if (dropTarget !== tile) {
        wjCore.removeClass(dropTarget, "drag-over");
        dropTarget = tile;
        wjCore.addClass(dropTarget, "drag-over");
      }
    }
  };

  const dashboardDrop = (e) => {
    if (dragSource && dropTarget) {
      // finish drag/drop
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      e.preventDefault();

      // re-order HTML elements (optional here, we're updating the state later)
      const srcIndex = getIndex(dragSource);
      const dstIndex = getIndex(dropTarget);

      var dragSourceInd = 0;
      var dropTargetInd = 0;

      var tmpTiles = tiles.slice();
      tmpTiles.forEach((item, i) => {
        if (item["id"] == dragSource.getAttribute("data-id")) {
          dragSourceInd = i;
        }
        if (item["id"] == dropTarget.getAttribute("data-id")) {
          dropTargetInd = i;
        }
      });

      var cl = tmpTiles[dragSourceInd];
      tmpTiles[dragSourceInd] = tmpTiles[dropTargetInd];
      tmpTiles[dropTargetInd] = cl;
      setTiles(tmpTiles);

      var tmpDashConfig = dashConfig.slice();
      var swapper = tmpDashConfig[dragSourceInd + 1];
      tmpDashConfig[dragSourceInd + 1] = tmpDashConfig[dropTargetInd + 1];
      tmpDashConfig[dropTargetInd + 1] = swapper;

      shouldUpdateSettings.current = true;
      setDashConfig(tmpDashConfig);

      var clone = dropTarget.cloneNode(true);
      clone.classList.remove("drag-over");

      wjCore.removeClass(dragSource, "drag-source");
      wjCore.removeClass(dropTarget, "drag-over");

      // dragSource.parentElement.insertBefore(clone, dragSource);
      // dropTarget.parentElement.insertBefore(dragSource, dropTarget);
      // dropTarget.remove();
      // dragSource.focus();
    }
  };

  const dashboardDragEnd = () => {
    wjCore.removeClass(dragSource, "drag-source");
    wjCore.removeClass(dropTarget, "drag-over");

    dragSource = dropTarget = null;

    // var dragSourceIndex = tiles
    //   .map(function (e) {
    //     return e.id;
    //   })
    //   .indexOf(parseInt(dragSource.getAttribute("data-id")));
    // var dropTargetIndex = tiles
    //   .map(function (e) {
    //     return e.id;
    //   })
    //   .indexOf(parseInt(dropTarget.getAttribute("data-id")));
    //
    // var tmpDashConfig = dashConfig.slice();
    // var swapper = tmpDashConfig[dragSourceIndex + 1];
    // tmpDashConfig[dragSourceIndex + 1] = tmpDashConfig[dropTargetIndex + 1];
    // tmpDashConfig[dropTargetIndex + 1] = swapper;
    //
    // shouldUpdateSettings.current = true;
    // setDashConfig(tmpDashConfig);
    //
    // dragSource = dropTarget = null;

    var elements = document.getElementsByClassName("liveStatsDrop");

    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", showSpaceDialogClicked, false);
    }

    var elements = document.getElementsByClassName("dropdown-item");

    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", removeWidgetClicked, false);
    }
  };

  const addTileMouseHover = (event) => {
    var clicked = event.target.getAttribute("data-menu");

    if (clicked) {
      setSelectedTile(clicked);
    }
  };

  const addTileMouseLeave = (event) => {
    setSelectedTile("");
  };

  /* dragstart, dragover, drop, dragend
  const enableItemReorder = (panel) => {
        let dragSource = null;
        let dropTarget = null;
        // add drag/drop event listeners
        panel.addEventListener('dragstart', (e) => {
            const target = wjCore.closest(e.target, '.tile');
            if (target && target.parentElement.parentElement === panel) {
                dragSource = target;
                wjCore.addClass(dragSource, 'drag-source');
                const dt = e.dataTransfer;
                dt.effectAllowed = 'move';
                dt.setData('text', dragSource.innerHTML);
            }
        });
        panel.addEventListener('dragover', (e) => {
            if (dragSource) {
                let tile = wjCore.closest(e.target, '.tile');
                if (tile === dragSource) {
                    tile = null;
                }
                if (dragSource && tile && tile !== dragSource) {
                    e.preventDefault();
                    e.dataTransfer.dropEffect = 'move';
                }
                if (dropTarget !== tile) {
                    wjCore.removeClass(dropTarget, 'drag-over');
                    dropTarget = tile;
                    wjCore.addClass(dropTarget, 'drag-over');
                }
            }
        });
        panel.addEventListener('drop', (e) => {
            if (dragSource && dropTarget) {
                // finish drag/drop
                e.stopPropagation();
                e.stopImmediatePropagation();
                e.preventDefault();
                // re-order HTML elements (optional here, we're updating the state later)
                const srcIndex = getIndex(dragSource);
                const dstIndex = getIndex(dropTarget);;

                console.log("tiles: ", getTilesFromMethod(), ", srcIndex: ", srcIndex, ", dstIndex: ", dstIndex);

                const dragColName = dragSource.parentElement.getAttribute("data-col");
                const dropColName = dropTarget.parentElement.getAttribute("data-col");

                var clone = dropTarget.cloneNode(true);
                clone.classList.remove("drag-over");
                dragSource.parentElement.insertBefore(clone, dragSource);
                dropTarget.parentElement.insertBefore(dragSource, dropTarget);
                dropTarget.remove();
                dragSource.focus();

                // console.log("original tiles: ", tiles);
                // var tmpArr = splitArr(tiles)['arr'];
                // var dragCol = getColArr(tmpArr, dragColName);
                // var dropCol = getColArr(tmpArr, dropColName);
                // var tmp = dragCol[srcIndex];
                // dragCol[srcIndex] = dropCol[dstIndex];
                // dropCol[dstIndex] = tmp;
                //
                // var cols = [dragColName];
                // if (dragColName !== dropColName) {
                //   cols.push(dropColName);
                // }
                //
                // setColArr(dragColName, dragCol, tmpArr);
                // setColArr(dropColName, dropCol, tmpArr);
                // var _tiles = concatColArr(tmpArr);
                // console.log("after tiles: ", _tiles);
                // setTiles(_tiles);
            }
        });
        panel.addEventListener('dragend', () => {
            wjCore.removeClass(dragSource, 'drag-source');
            wjCore.removeClass(dropTarget, 'drag-over');
            dragSource = dropTarget = null;
        });
    };
  */
  const TilesMenus = () => {
    var menutiles = [
      {
        title: "Live",
        name: "live",
        icon: LiveIcon,
        icon_alt: LiveAltIcon,
      },
      {
        title: "Data",
        name: "data",
        icon: DataIcon,
        icon_alt: DataAltIcon,
      },
      {
        title: "Billing",
        name: "billing",
        icon: BillingIcon,
        icon_alt: BillingAltIcon,
      },
      {
        title: "Resources",
        name: "resource",
        icon: ResourcesIcon,
        icon_alt: ResourcesAltIcon,
      },
      // {
      //   title: "Insights",
      //   name: "insights",
      //   icon: InsightsIcon,
      //   icon_alt: InsightsAltIcon,
      // },
      {
        title: "Notifications",
        name: "notifications",
        icon: NotificationsIcon,
        icon_alt: NotificationsAltIcon,
      },
      {
        title: "Contact",
        name: "contact",
        icon: ContactIcon,
        icon_alt: ContactAltIcon,
      },
      {
        title: "Profile",
        name: "profile",
        icon: Avatar,
        icon_alt: Avatar,
      },
    ];

    var access_billing_module = true;
    var access_billing_module_id = -1;

    if (AuthDetail.permissions.indexOf("access_billing_module") < 0) {
      access_billing_module = false;
    }

    if (access_billing_module == false) {
      for (var xx = 0; xx < menutiles.length; xx++) {
        if (menutiles[xx]["name"] == "billing") {
          access_billing_module_id = xx;
        }
      }

      menutiles.splice(access_billing_module_id, 1);
    }

    if (liveData.room_list.length == 0) {
      menutiles.shift();
    }

    return (
      <Row>
        {menutiles.length > 0 ? (
          menutiles.map((_, index) => {
            return (
              <Col md="3" sm="6" className="add-tile-modal col-6" key={index}>
                <div
                  className={`rounded add-tile-modal-child${
                    selected_tile === _.name ? " selected" : ""
                  }`}
                  onClick={menuClicked}
                  onMouseOver={addTileMouseHover}
                  onMouseLeave={addTileMouseLeave}
                  data-menu={_.name}
                >
                  <div
                    className="add-tile-img-holder"
                    onMouseOver={addTileMouseHover}
                    data-menu={_.name}
                  >
                    <Image
                      onClick={menuClicked}
                      onMouseOver={addTileMouseHover}
                      data-menu={_.name}
                      className="navigation-icon"
                      src={
                        theme === "light" && selected_tile !== _.name
                          ? _.icon
                          : theme === "light" && selected_tile === _.name
                          ? _.icon_alt
                          : theme === "dark" && selected_tile !== _.name
                          ? _.icon_alt
                          : _.icon_alt
                      }
                    />
                    <h6
                      className="tile-menu-title"
                      onMouseOver={addTileMouseHover}
                      data-menu={_.name}
                    >
                      {_.title}
                    </h6>
                  </div>
                </div>
              </Col>
            );
          })
        ) : (
          <div></div>
        )}
      </Row>
    );
  };

  const loadDashboardData = () => {
    setLoading(true);
    api
      .get(GET_DASHBOARD_DATA + "?site_id=" + getCurrentSite())
      .then((res) => {
        console.log("dashboard res: ", res);

        if (res["detail"] == "Authentication credentials were not provided.") {
          window.location.href = "/";
        }

        var dashSet = res["results"]["dashboard_settings"]["setting"];

        var tmp_tiles = [];
        var floors = ["All"];
        var ftypes = ["All"];
        var cats = [];

        if (res["status"] === "Success") {
          setTileMainData(res["results"]);
          var live_data = {
            power: "-",
            temp: 0,
            cooling: 0,
            temp_limit: 0,
            min_cooling: 150,
            max_cooling: 2200,
            confer_temp: 0,
            water: 0,
            show_space_temp: res["results"]["show_space_temp"],
            deg: 0,
          };

          if (res["results"]["limit"]) {
            live_data["temp_limit"] =
              res["results"]["limit"]["chilled_water_limit"];
            live_data["min_cooling"] =
              res["results"]["limit"]["cooling_delivered_min"];
            live_data["max_cooling"] =
              res["results"]["limit"]["cooling_delivered_max"];
          }

          if (res["results"]["live_kw"] && res["results"]["live_ka"]) {
            live_data["power"] = res["results"]["live_kw"]["system_status"];
            live_data["temp"] = res["results"]["live_kw"]["water_temp"];
            live_data["cooling"] = res["results"]["live_kw"]["cooling_amount"];
            live_data["deg"] = calculateIndicatorDeg(
              live_data["cooling"],
              live_data["max_cooling"]
            );
            live_data["rooms"] = [];
            live_data["room_list"] = [];
            live_data["confer_temp"] = "-";
            live_data["water"] = "-";
            live_data["chosen_room"] = "-";
          }

          if (res["results"]["live_ka"]["rooms"].length > 0) {
            live_data["room_list"] = res["results"]["live_ka"]["room_list"];
            live_data["rooms"] = res["results"]["live_ka"]["rooms"];

            if (dashSet.length > 0) {
              if (dashSet[0]["selected"]) {
                res["results"]["live_ka"]["rooms"].forEach(function (
                  item,
                  index
                ) {
                  // console.log("NAME ==> ", dashSet[0]["name"], "Selected ==> ",  dashSet[0]["selected"], "Item Name ==> ", item["name"]);
                  if (
                    dashSet[0]["name"] == "main" &&
                    dashSet[0]["selected"].toUpperCase() ==
                      item["name"].toUpperCase()
                  ) {
                    live_data["chosen_room"] = item["name"];
                    live_data["confer_temp"] = item["temperature"];
                    live_data["water"] = item["humidity"];
                  }
                });
              }
            }
            if (live_data["chosen_room"] == "-") {
              live_data["chosen_room"] =
                res["results"]["live_ka"]["rooms"][0]["name"];
              live_data["confer_temp"] =
                res["results"]["live_ka"]["rooms"][0]["temperature"];
              live_data["water"] =
                res["results"]["live_ka"]["rooms"][0]["humidity"];
            }
          }
          setLiveData(live_data);
          setDashConfig(dashSet);

          dashSet.forEach(function (item, index) {
            if (item["name"] === "data") {
              var sel = item["selected"] || "day";
              // console.log("selected: ", sel, res["results"]["data"][sel]);

              tmp_tiles.push({
                id: Math.floor(Math.random() * 1000 + 1),
                tile_type: "Data",
                tile_heading: "Data",
                chart_type: "",
                chart_title: "",
                chosen_date:
                  "Previous " + sel.charAt(0).toUpperCase() + sel.slice(1),
                data: res["results"]["data"][sel],
              });
            } else if (item["name"] === "notification") {
              tmp_tiles.push({
                id: Math.floor(Math.random() * 1000 + 1),
                tile_type: "Notification",
                tile_heading: "Notifications",
                chart_type: "",
                chart_title: "Clear All",
                data: res["results"]["notifications"],
                readNotification: readNotification,
              });
            } else if (item["name"] === "contact") {
              tmp_tiles.push({
                id: Math.floor(Math.random() * 1000 + 1),
                tile_type: "Contact",
                tile_heading: "Contact",
                chart_type: "",
                chart_title: "",
                data: res["results"]["contacts"],
              });
            } else if (item["name"] === "profile") {
              tmp_tiles.push({
                id: Math.floor(Math.random() * 1000 + 1),
                tile_type: "Profile",
                tile_heading: "Profile",
                chart_type: "",
                chart_title: "",
                data: res["results"]["profile"],
              });
            } else if (item["name"] === "billing") {
              tmp_tiles.push({
                id: Math.floor(Math.random() * 1000 + 1),
                tile_type: "Billing",
                tile_heading: "Billing",
                chart_type: "",
                chart_title: "",
                data: res["results"]["invoices"],
              });
            } else if (item["name"] === "resource") {
              var sel = item["selected"] || "equipment_and_operations";
              tmp_tiles.push({
                id: Math.floor(Math.random() * 1000 + 1),
                tile_type: "Resource",
                tile_heading: "Resources",
                chart_type: "",
                chart_title: "",
                chosen_type: sel,
                chosen_text: all_available_resource_types[sel],
                data: res["results"]["resources"][sel],
              });
            } else if (item["name"] === "live") {
              var tmpLiveData = { ...live_data };
              res["results"]["live_ka"]["rooms"].forEach(function (it, ind) {
                if (item["selected"] == it["name"]) {
                  tmpLiveData["chosen_room"] = it["name"];
                  tmpLiveData["confer_temp"] = it["temperature"];
                  tmpLiveData["water"] = it["humidity"];
                }
              });

              tmp_tiles.push({
                id: Math.floor(Math.random() * 1000 + 1),
                tile_type: "Live",
                tile_heading: "Live",
                chart_type: "",
                chart_title: "",
                data: tmpLiveData,
              });
            }
          });

          console.log("=== selectedSpaceCat === ", selectedSpaceCat);

          Object.keys(res["results"]["live_ka"]["room_map"]).forEach(function (
            key
          ) {
            if (key == selectedSpaceCat) {
              Object.keys(res["results"]["live_ka"]["room_map"][key]).forEach(
                function (key1) {
                  ftypes.push(key1);

                  Object.keys(
                    res["results"]["live_ka"]["room_map"][key][key1]
                  ).forEach(function (key2) {
                    floors.push(key2);
                  });
                }
              );
            }
          });

          ftypes = ftypes.sort();
          floors = [...new Set(floors)];
          floors.sort(
            (a, b) =>
              res["results"]["live_ka"]["floor_list"].indexOf(a) -
              res["results"]["live_ka"]["floor_list"].indexOf(b)
          );

          res["results"]["live_ka"]["building_list"].forEach((it, ind) => {
            cats.push(it);
          });

          isInitialRender.current = false;
          shouldUpdateSettings.current = false;

          setTiles(tmp_tiles);

          console.log("=== floors === ", floors);
          console.log("=== ftypes === ", ftypes);
          console.log("=== cats === ", cats);
          setSpaceFloors(floors);
          setSpaceTypes(ftypes);
          setSpaceCats(cats);
          loadDialogBlocks(
            selectedSpaceType,
            selectedSpaceFloor,
            selectedSpaceCat,
            res["results"]
          );

          setLoading(false);
        } else {
          setLoading(false);
          alert("Error retrieving dasboard data.");
        }
      })
      .catch((err) => {
        console.log("dashboard err", err);
        setLoading(false);
      });
    // const panel = document.querySelector('.dashboard');
    // enableItemReorder(panel);
  };

  const intervalTimer = () => {
    console.log("dashboard setInterval running...");
    console.log("Pause Set Interval: ", pauseSetInternval.current);
    if (pauseSetInternval.current == false) {
      loadDashboardData();
      console.log("Ran set interval...");
    } else {
      console.log("Paused set interval...");
    }
  };

  useEffect(() => {
    if (props.AuthLoggedIn === false) {
      return;
    }
    console.log("refresh_interval: ", AuthDetail.refresh_interval * 60 * 1000);
    loadDashboardData();
    dashboardInterval = setInterval(
      intervalTimer,
      AuthDetail.refresh_interval * 60 * 1000
    );

    return () => {
      setLoading(false);
      clearInterval(dashboardInterval);
    };
  }, []);

  useEffect(() => {
    if (!isInitialRender.current && shouldUpdateSettings.current) {
      api
        .post(
          SET_DASHBOARD_SETTING,
          JSON.stringify({ settings: dashConfig, site_id: getCurrentSite() }),
          CSRFToken
        )
        .then((res) => {
          console.log("Dashboard settings update: ", res);
          var copiedMainData = tileMainData;
          copiedMainData["dashboard_settings"] = res["results"];
          setTileMainData(copiedMainData);

          shouldUpdateSettings.current = false;
        })
        .catch((err) => {
          console.log("Dashboard settings update err", err);
          shouldUpdateSettings.current = false;
        });
    }
  }, [dashConfig]);

  useEffect(() => {
    console.log("useEffect ==> ", tiles);

    var tmp = splitArr(tiles);
    setColA(tmp["arr"][0]);
    setColB(tmp["arr"][1]);
    setColC(tmp["arr"][2]);
    setBlankShouldBeHere(tmp["count"]);

    // const panel = document.querySelector('.dashboard');
    // enableItemReorder(panel);
  }, [tiles]);

  const [showLiveValueModal, setShowLiveValueModal] = useState(false);
  const [showLiveType, setShowLiveType] = useState("");

  const showMoreIconClicked = (event) => {
    event.preventDefault();
    console.log("showMoreIconClicked: ", event.target);

    var choice = event.target.getAttribute("data-type");

    if (choice === "water") {
      setShowLiveType("water");
    } else {
      setShowLiveType("cooling");
    }
    setShowLiveValueModal(true);
  };

  const spaceCatClicked = (event) => {
    console.log("=== spaceCatClicked === ", event.target.nextSibling.innerHTML);

    var floors = ["All"];
    var types = ["All"];

    Object.keys(tileMainData["live_ka"]["room_map"]).forEach(function (key) {
      if (key == event.target.nextSibling.innerHTML) {
        Object.keys(tileMainData["live_ka"]["room_map"][key]).forEach(function (
          key1
        ) {
          types.push(key1);
          Object.keys(tileMainData["live_ka"]["room_map"][key][key1]).forEach(
            function (key2) {
              floors.push(key2);
            }
          );
        });
      }
    });

    floors.sort(
      (a, b) =>
        tileMainData["live_ka"]["floor_list"].indexOf(a) -
        tileMainData["live_ka"]["floor_list"].indexOf(b)
    );
    floors = floors.filter(onlyUnique);
    console.log("spaceCatClicked types: ", types);
    setSpaceTypes(types);
    console.log("spaceCatClicked floors: ", floors);
    setSpaceFloors(floors);
    setSelectedSpaceType("All");
    setSelectedSpaceFloor("All");

    setSelectedSpaceCat(event.target.nextSibling.innerHTML);
    console.log(
      "spaceCatClicked catetory: ",
      event.target.nextSibling.innerHTML
    );
    // loadDashboardData();
  };

  const spaceFloorClicked = (event) => {
    console.log("=== spaceFloorClicked === ", event.target.innerHTML);
    setSelectedSpaceFloor(event.target.innerHTML);
  };

  const spaceFloorPagingClicked = () => {
    var ind = spaceFloors.indexOf(selectedSpaceFloor);
    if (ind === spaceFloors.length - 1) {
      setSelectedSpaceFloor(spaceFloors[0]);
      console.log("=== spaceFloorPagingClicked === ", spaceFloors[0]);
    } else {
      setSelectedSpaceFloor(spaceFloors[ind + 1]);
      console.log("=== spaceFloorPagingClicked === ", spaceFloors[ind + 1]);
    }
  };

  const addSpaceBlkMouseHover = (event) => {
    var clicked = event.target.getAttribute("data-id");

    if (clicked) {
      setSelectedBlock(clicked);
    }
  };

  const addSpaceBlkMouseLeave = (event) => {
    setSelectedBlock("");
  };

  const spaceBlkClicked = (event) => {
    console.log("=== spaceBlkClicked ===");
    if (event.target.classList.contains("space-floor-blk")) {
      if (event.target.classList.contains("disabled")) {
        return;
      }
    } else {
      var tmp = event.target.closest(".space-floor-blk");
      if (tmp.classList.contains("disabled")) {
        return;
      }
    }

    var copyLiveData = { ...liveData };
    isInitialRender.current = false;
    shouldUpdateSettings.current = true;

    if (airDialogClickedFrom == "live-main") {
      copyLiveData["rooms"].forEach(function (item, index) {
        if (item["name"] == selectedBlock) {
          copyLiveData["chosen_room"] = selectedBlock;
          copyLiveData["confer_temp"] = item["temperature"];
          copyLiveData["water"] = item["humidity"];
        }
      });

      var tmpDashConfig = dashConfig.slice();
      tmpDashConfig[0]["selected"] = selectedBlock;
      setDashConfig(tmpDashConfig);

      setLiveData(copyLiveData);
    } else {
      var live_data = {
        power: "-",
        temp: 0,
        cooling: 0,
        min_cooling: 150,
        max_cooling: 2200,
        confer_temp: 0,
        water: 0,
      };

      if (tileMainData["live_kw"] && tileMainData["live_ka"]) {
        live_data["power"] = tileMainData["live_kw"]["system_status"];
        live_data["temp"] = tileMainData["live_kw"]["water_temp"];
        live_data["cooling"] = tileMainData["live_kw"]["cooling_amount"];
        live_data["rooms"] = [];
        live_data["confer_temp"] = "-";
        live_data["water"] = "-";
        live_data["chosen_room"] = "-";
      }

      if (tileMainData["live_ka"]["rooms"].length > 0) {
        live_data["rooms"] = tileMainData["live_ka"]["rooms"];
        tileMainData["live_ka"]["rooms"].forEach(function (item, index) {
          if (item["name"] == selectedBlock) {
            live_data["chosen_room"] = selectedBlock;
            live_data["confer_temp"] = item["temperature"];
            live_data["water"] = item["humidity"];
          }
        });
      }

      var tmpTiles = tiles.slice();
      var pointer = 0;

      tmpTiles.forEach(function (item, index) {
        if (item["id"] == airDialogWidget) {
          tmpTiles[index]["data"] = live_data;
          // tmpTiles[index]["selected"] = selectedBlock;

          document.getElementById("room-" + item["id"]).innerText =
            selectedBlock;
          document.getElementById("confer_temp-" + item["id"]).innerText =
            live_data["confer_temp"];
          document.getElementById("water-" + item["id"]).innerText =
            live_data["water"];
          pointer = index;
        }
      });

      pointer = pointer + 1;

      var tmpDashConfig = dashConfig.slice();
      tmpDashConfig[pointer]["selected"] = selectedBlock;
      setDashConfig(tmpDashConfig);

      setTiles(tmpTiles);
      var tmp = splitArr(tmpTiles);
      setColA(tmp["arr"][0]);
      setColB(tmp["arr"][1]);
      setColC(tmp["arr"][2]);
      setBlankShouldBeHere(tmp["count"]);
    }

    document.getElementById("btnMain0").click();
    setShowSpaceDialog(false);
    pauseSetInternval.current = false;
    console.log(
      "spaceBlkClicked pauseSetInternval ==> ",
      pauseSetInternval.current
    );
  };

  const spaceTypeClicked = (event) => {
    console.log("=== spaceTypeClicked === ", event.target.innerHTML);
    setSelectedSpaceType(event.target.innerHTML);

    var floors = ["All"];

    Object.keys(tileMainData["live_ka"]["room_map"]).forEach(function (key) {
      if (key == selectedSpaceCat) {
        Object.keys(tileMainData["live_ka"]["room_map"][key]).forEach(function (
          key1
        ) {
          if (
            key1 == event.target.innerHTML ||
            event.target.innerHTML == "All"
          ) {
            Object.keys(tileMainData["live_ka"]["room_map"][key][key1]).forEach(
              function (key2) {
                console.log("=== key1: ", key1, key2);
                floors.push(key2);
              }
            );
          }
        });
      }
    });

    floors = [...new Set(floors)];
    floors.sort(
      (a, b) =>
        tileMainData["live_ka"]["floor_list"].indexOf(a) -
        tileMainData["live_ka"]["floor_list"].indexOf(b)
    );
    setSpaceFloors(floors);

    setSelectedSpaceFloor("All");
  };

  const sortRooms = (a, b) => {
    var val_a = a["level_raw"];
    var val_b = b["level_raw"];

    if (isNaN(parseInt(val_a))) {
      val_a = 0;
    } else {
      val_a = parseInt(val_a);
    }

    if (isNaN(parseInt(val_b))) {
      val_b = 0;
    } else {
      val_b = parseInt(val_b);
    }

    if (val_a < val_b) {
      return -1;
    }
    if (val_a > val_b) {
      return 1;
    }
    return 0;
  };

  const [allChosenSpaces, setAllChosenSpaces] = useState([]);

  const loadDialogBlocks = (_type, _floor, _cat, _data) => {
    var selectedData = _data["live_ka"]["room_map"][_cat];
    var chosenData = [];

    if (!selectedData) return;

    if (_type === "All") {
      if (_floor === "All") {
        Object.keys(selectedData).forEach(function (key) {
          // console.log(key + " " + selectedData[key]);

          Object.keys(selectedData[key]).forEach(function (key2) {
            // console.log(key2 + " " + selectedData[key][key2]);

            chosenData.push(...selectedData[key][key2]);
          });
        });
      } else {
        Object.keys(selectedData).forEach(function (key) {
          if (_floor in selectedData[key]) {
            chosenData.push(...selectedData[key][_floor]);
          }
        });
      }
    } else {
      if (_floor === "All") {
        if (selectedData[_type]) {
          Object.keys(selectedData[_type]).forEach(function (key2) {
            chosenData.push(...selectedData[_type][key2]);
          });
        }
      } else {
        if (selectedData[_type]) {
          if (_floor in selectedData[_type]) {
            chosenData.push(...selectedData[_type][_floor]);
          }
        }
      }
    }

    chosenData = chosenData.sort(sortRooms);

    setSpaceBlocks(chosenData);
  };

  useEffect(() => {
    console.log("useEffect selectedSpaceType ==> ", selectedSpaceType);
    console.log("useEffect selectedSpaceFloor ==> ", selectedSpaceFloor);
    console.log("useEffect selectedSpaceCat ==> ", selectedSpaceCat);

    console.log("tileMainData ==> ", tileMainData);
    if (!isInitialRender.current) {
      loadDialogBlocks(
        selectedSpaceType,
        selectedSpaceFloor,
        selectedSpaceCat,
        tileMainData
      );
    }
  }, [selectedSpaceType, selectedSpaceFloor, selectedSpaceCat]);

  const showSpaceDialogClicked = (event) => {
    event.preventDefault();
    loadDialogBlocks("All", "All", "Main", tileMainData);

    var widget = event.target.getAttribute("data-widget");
    var clickedId = event.target.getAttribute("data-id");

    var tmpArr = [];
    console.log(
      'tileMainData["dashboard_settings"]: ',
      tileMainData["dashboard_settings"]
    );
    if (tileMainData["dashboard_settings"]) {
      tileMainData["dashboard_settings"]["setting"].forEach((item, i) => {
        if (item["name"] == "main") {
          if (item["selected"] == "") {
            tmpArr.push(tileMainData["live_ka"]["rooms"][0]["name"]);
          } else {
            tmpArr.push(item["selected"]);
          }
        }
        if (item["name"] == "live") {
          tmpArr.push(item["selected"]);
        }
      });
    }
    console.log("tmpArr: ", tmpArr);
    setAllChosenSpaces(tmpArr);
    setAirDialogClickedFrom(widget);
    setShowSpaceDialog(true);
    pauseSetInternval.current = true;
    console.log(
      "showSpaceDialogClicked pauseSetInternval ==> ",
      pauseSetInternval.current
    );

    if (clickedId) {
      setAirDialogWidget(clickedId);
    }
  };

  const handleCloseSpaceDialog = (event) => {
    setSelectedBlock("");
    setSelectedSpaceType("All");
    setSelectedSpaceFloor("All");
    document.getElementById("btnMain0").click();
    setShowSpaceDialog(false);
    pauseSetInternval.current = false;
    console.log(
      "handleCloseSpaceDialog pauseSetInternval ==> ",
      pauseSetInternval.current
    );
  };

  // <input type="radio" className="btn-check" name="btnLDC" id="btnLDC" autoComplete="off"
  //   onClick={spaceCatClicked} />
  // <label className={`btn add-space-type ${selectedSpaceCat === "LDC" && "active"} ${theme === "light" ? "" : "dark"}`}
  //   htmlFor="btnLDC">LDC</label>

  const SpaceDialogContent = () => {
    return (
      <>
        {spaceCats.map((value, index) => {
          return (
            <div
              key={index}
              className="btn-group"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input
                type="radio"
                className="btn-check"
                name={`btnMain${index}`}
                id={`btnMain${index}`}
                autoComplete="off"
                onClick={spaceCatClicked}
              />
              <label
                className={`btn add-space-type-dashboard ${
                  selectedSpaceCat === value && "active"
                } ${theme === "light" ? "" : "dark"}`}
                htmlFor={`btnMain${index}`}
              >
                {value}
              </label>
            </div>
          );
        })}
        <hr className="d-none d-lg-block"></hr>
        <br className="d-block d-lg-none"></br>
        <br className="d-block d-lg-none"></br>
        <Row>
          <Col lg="2" md="2">
            <div
              className={`d-none d-lg-block space-type-chooser ${
                theme === "light" ? "" : "dark-blk"
              }`}
            >
              <Image
                className="img-fluid"
                src={theme === "light" ? SpaceIcon : SpaceDarkIcon}
                style={{ paddingBottom: "3px" }}
              />
              <p
                style={{
                  display: "inline-block",
                  fontSize: "15px",
                  paddingLeft: "10px",
                }}
              >
                <b>Type</b>
              </p>
              {spaceTypes.map((value, index) => {
                return (
                  <div key={index}>
                    {index !== 0 && <hr></hr>}
                    <p
                      className={`space-type-info ${
                        selectedSpaceType === value && "active"
                      }`}
                      onClick={spaceTypeClicked}
                    >
                      {value}
                    </p>
                  </div>
                );
              })}
            </div>
            <div
              className="d-block d-lg-none accordion accordion-flush space-type-chooser-accordion"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <Image
                      className="img-fluid"
                      src={theme === "light" ? SpaceIcon : SpaceDarkIcon}
                    />
                    <p
                      style={{
                        display: "inline-block",
                        fontSize: "15px",
                        paddingLeft: "10px",
                        marginBottom: "0px",
                      }}
                    >
                      <b>Type</b>
                    </p>
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    {spaceTypes.map((value, index) => {
                      return (
                        <div key={index}>
                          {index !== 0 && <hr></hr>}
                          <p
                            className={`space-type-info ${
                              selectedSpaceType === value && "active"
                            }`}
                            onClick={spaceTypeClicked}
                          >
                            {value}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="10" md="10">
            <div style={{ paddingTop: "10px" }}>
              <Image
                className="img-fluid"
                style={{ verticalAlign: "text-bottom" }}
                src={theme === "light" ? LayersIcon : LayersDarkIcon}
              />
              <p
                style={{
                  display: "inline-block",
                  fontSize: "15px",
                  fontWeight: "400",
                  paddingLeft: "10px",
                  verticalAlign: "-webkit-baseline-middle",
                }}
              >
                <b>Floor</b>
              </p>
              <div className="middle-padding-space-blk d-none d-lg-inline-flex">
                <hr></hr>
              </div>
              <br className="d-block d-lg-none"></br>
              <p className="paging-space-blk d-none d-lg-inline-block">
                {spaceFloors.map((value, index) => {
                  return (
                    <span key={index}>
                      <span
                        className={`paging-space-page ${
                          value === selectedSpaceFloor && "active"
                        }`}
                        onClick={spaceFloorClicked}
                      >
                        {value}
                      </span>
                    </span>
                  );
                })}
                <span
                  className="paging-space-page"
                  onClick={spaceFloorPagingClicked}
                >
                  >
                </span>
              </p>
              <p className="paging-space-blk-mobile d-block d-lg-none">
                {spaceFloors.map((value, index) => {
                  return (
                    <span key={index}>
                      <span
                        className={`paging-space-page-mobile ${
                          value === selectedSpaceFloor && "active"
                        }`}
                        onClick={spaceFloorClicked}
                      >
                        {value}
                      </span>
                    </span>
                  );
                })}
                <span
                  className="paging-space-page"
                  onClick={spaceFloorPagingClicked}
                >
                  >
                </span>
              </p>
            </div>
            <div>
              <Row>
                {spaceBlocks !== undefined ? (
                  spaceBlocks.map((_, index) => {
                    return (
                      <Col md="3" sm="6" className="spacer col-6" key={index}>
                        <div
                          className={`rounded space-floor-blk ${
                            selectedBlock === _.name &&
                            allChosenSpaces.indexOf(selectedBlock) < 0 &&
                            "active"
                          } ${
                            allChosenSpaces.indexOf(_.name) > -1 && "disabled"
                          }`}
                          disabled={
                            allChosenSpaces.indexOf(_.name) > -1
                              ? "disabled"
                              : ""
                          }
                          onClick={spaceBlkClicked}
                          onMouseOver={addSpaceBlkMouseHover}
                          onMouseLeave={addSpaceBlkMouseLeave}
                          data-id={_.name}
                        >
                          <Image
                            className="img-fluid"
                            onMouseOver={addSpaceBlkMouseHover}
                            data-id={_.name}
                            src={
                              theme === "light" &&
                              selectedBlock === _.name &&
                              allChosenSpaces.indexOf(selectedBlock) < 0
                                ? CubeDarkIcon
                                : theme === "light" && selectedBlock !== _.name
                                ? CubeIcon
                                : CubeIcon
                            }
                          />
                          <p
                            className="space-blk-level"
                            style={{
                              paddingLeft: "10px",
                              display: "inline-block",
                              lineHeight: "0.5em",
                            }}
                          >
                            <b>{_.level}</b>
                          </p>
                          <p
                            className="space-blk-name"
                            data-id={_.name}
                            style={{ lineHeight: "1em", textAlign: "left" }}
                          >
                            {_.name}
                          </p>
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <div></div>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      {loading && (
        <Modal
          show={loading}
          data-backdrop="static"
          data-keyboard="false"
          className="loader"
        >
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </Modal>
      )}
      <Modal show={showSpaceDialog} onHide={handleCloseSpaceDialog}>
        <Modal.Header
          closeButton
          className={`${theme === "light" ? "" : "dark dark-blk"}`}
        >
          <Modal.Title>
            <div className="modal-padding-blk"></div>{" "}
            <div className="modal-title-blk">
              <span className={`${theme === "light" ? "" : "dark"}`}>
                Add a space
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${theme === "light" ? "" : "dark dark-blk"}`}>
          <SpaceDialogContent />
        </Modal.Body>
      </Modal>
      <Row style={{ marginLeft: "1em", marginRight: "1em" }}>
        {liveData && (
          <StatsBlk
            heading="Live"
            data={liveData}
            showLiveValueModal={showLiveValueModal}
            setShowLiveValueModal={setShowLiveValueModal}
            showLiveType={showLiveType}
            setShowLiveType={setShowLiveType}
            showMoreIconClicked={showMoreIconClicked}
            showSpaceDialogClicked={showSpaceDialogClicked}
            hideKaerAir={
              (!liveData.show_space_temp && getCurrentSite() !== "insead") ||
              (liveData.room_list.length < 1 && getCurrentSite() !== "insead")
                ? true
                : false
            }
          />
        )}
      </Row>
      <Row
        className="pt-2 ps-4 pe-0 dashboard d-none d-lg-inline-flex"
        onDragStart={dashboardDragStart}
        onDragOver={dashboardDragOver}
        onDrop={dashboardDrop}
        onDragEnd={dashboardDragEnd}
      >
        <Col lg="4" md="12" data-col="colA">
          {colA.length > 0 ? (
            colA.map((_, index) => {
              if (_.tile_type === "Chart") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                  >
                    <ChartBlk
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                    />
                  </div>
                );
              } else if (_.tile_type === "Data") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <DataBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      chosen_date={_.chosen_date}
                      dataChoiceClicked={dataChoiceClicked}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Notification") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <NotiBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      readNotification={_.readNotification}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Resource") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <ResourceBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      removeWidgetClicked={removeWidgetClicked}
                      chosen_type={_.chosen_type}
                      chosen_text={_.chosen_text}
                      resource_types={all_available_resource_types}
                      resoChoiceClicked={resoChoiceClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Contact") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <ContactBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Profile") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <ProfileBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type=""
                      chart_title=""
                      data={_.data}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Billing") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <BillingBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Live") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <LiveBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      selected={_.selected}
                      showSpaceDialogClicked={showSpaceDialogClicked}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              }
            })
          ) : (
            <div></div>
          )}
          {blankShouldBeHere === 0 && (
            <div style={{ padding: "5px" }}>
              <BlankBlk handleShow={handleShow} />
              <Modal
                show={showTile}
                onHide={function () {
                  setShowTile(false);
                }}
              >
                <Modal.Header
                  closeButton
                  className={`${theme === "light" ? "" : "dark dark-blk"}`}
                >
                  <Modal.Title>
                    <div className="modal-padding-blk"></div>{" "}
                    <div className="modal-title-blk">
                      <span className={`${theme === "light" ? "" : "dark"}`}>
                        Add new widget
                      </span>
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  className={`${theme === "light" ? "" : "dark dark-blk"}`}
                >
                  <TilesMenus />
                </Modal.Body>
              </Modal>
            </div>
          )}
        </Col>
        <Col lg="4" md="12" data-col="colB">
          {colB.length > 0 ? (
            colB.map((_, index) => {
              if (_.tile_type === "Chart") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                  >
                    <ChartBlk
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                    />
                  </div>
                );
              } else if (_.tile_type === "Data") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <DataBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      chosen_date={_.chosen_date}
                      dataChoiceClicked={dataChoiceClicked}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Notification") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <NotiBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      readNotification={_.readNotification}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Resource") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <ResourceBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      removeWidgetClicked={removeWidgetClicked}
                      chosen_type={_.chosen_type}
                      chosen_text={_.chosen_text}
                      resource_types={all_available_resource_types}
                      resoChoiceClicked={resoChoiceClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Contact") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <ContactBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Profile") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <ProfileBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type=""
                      chart_title=""
                      data={_.data}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Billing") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <BillingBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Live") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <LiveBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      selected={_.selected}
                      showSpaceDialogClicked={showSpaceDialogClicked}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              }
            })
          ) : (
            <div></div>
          )}
          {blankShouldBeHere === 1 && (
            <div style={{ padding: "5px" }}>
              <BlankBlk
                handleShow={handleShow}
                key={Math.floor(Math.random() * 1000 + 1)}
              />
              <Modal
                show={showTile}
                onHide={function () {
                  setShowTile(false);
                }}
              >
                <Modal.Header
                  closeButton
                  className={`${theme === "light" ? "" : "dark dark-blk"}`}
                >
                  <Modal.Title>
                    <div className="modal-padding-blk"></div>{" "}
                    <div className="modal-title-blk">
                      <span className={`${theme === "light" ? "" : "dark"}`}>
                        Add new widget
                      </span>
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  className={`${theme === "light" ? "" : "dark dark-blk"}`}
                >
                  <TilesMenus />
                </Modal.Body>
              </Modal>
            </div>
          )}
        </Col>
        <Col lg="4" md="12" data-col="colC">
          {colC.length > 0 ? (
            colC.map((_, index) => {
              if (_.tile_type === "Chart") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                  >
                    <ChartBlk
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                    />
                  </div>
                );
              } else if (_.tile_type === "Data") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <DataBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      chosen_date={_.chosen_date}
                      dataChoiceClicked={dataChoiceClicked}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Notification") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <NotiBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      readNotification={_.readNotification}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Resource") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <ResourceBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      removeWidgetClicked={removeWidgetClicked}
                      chosen_type={_.chosen_type}
                      chosen_text={_.chosen_text}
                      resource_types={all_available_resource_types}
                      resoChoiceClicked={resoChoiceClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Contact") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <ContactBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Profile") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <ProfileBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type=""
                      chart_title=""
                      data={_.data}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Billing") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <BillingBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              } else if (_.tile_type === "Live") {
                return (
                  <div
                    style={{ padding: "5px" }}
                    key={_.id}
                    draggable="true"
                    className="tile"
                    data-id={_.id}
                  >
                    <LiveBlk
                      id={_.id}
                      heading={_.tile_heading}
                      chart_type={_.chart_type}
                      chart_title={_.chart_title}
                      data={_.data}
                      selected={_.selected}
                      showSpaceDialogClicked={showSpaceDialogClicked}
                      removeWidgetClicked={removeWidgetClicked}
                    />
                  </div>
                );
              }
            })
          ) : (
            <div></div>
          )}
          {blankShouldBeHere === 2 && (
            <div style={{ padding: "5px" }}>
              <BlankBlk
                handleShow={handleShow}
                key={Math.floor(Math.random() * 1000 + 1)}
              />
              <Modal
                show={showTile}
                onHide={function () {
                  setShowTile(false);
                }}
              >
                <Modal.Header
                  closeButton
                  className={`${theme === "light" ? "" : "dark dark-blk"}`}
                >
                  <Modal.Title>
                    <div className="modal-padding-blk"></div>{" "}
                    <div className="modal-title-blk">
                      <span className={`${theme === "light" ? "" : "dark"}`}>
                        Add new widget
                      </span>
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  className={`${theme === "light" ? "" : "dark dark-blk"}`}
                >
                  <TilesMenus />
                </Modal.Body>
              </Modal>
            </div>
          )}
        </Col>
      </Row>
      <Row
        className="pt-2 dashboard d-block d-lg-none"
        style={{ paddingLeft: "2.1em", paddingRight: "0.2em" }}
      >
        {tiles.length > 0 ? (
          tiles.map((_, index) => {
            if (_.tile_type === "Chart") {
              return (
                <div
                  style={{ padding: "5px" }}
                  key={_.id}
                  draggable="true"
                  className="tile"
                >
                  <ChartBlk
                    heading={_.tile_heading}
                    chart_type={_.chart_type}
                    chart_title={_.chart_title}
                    data={_.data}
                  />
                </div>
              );
            } else if (_.tile_type === "Data") {
              return (
                <div
                  style={{ padding: "5px" }}
                  key={_.id}
                  draggable="true"
                  className="tile"
                  data-id={_.id}
                >
                  <DataBlk
                    id={_.id}
                    heading={_.tile_heading}
                    chart_type={_.chart_type}
                    chart_title={_.chart_title}
                    data={_.data}
                    chosen_date={_.chosen_date}
                    dataChoiceClicked={dataChoiceClicked}
                    removeWidgetClicked={removeWidgetClicked}
                  />
                </div>
              );
            } else if (_.tile_type === "Notification") {
              return (
                <div
                  style={{ padding: "5px" }}
                  key={_.id}
                  draggable="true"
                  className="tile"
                  data-id={_.id}
                >
                  <NotiBlk
                    id={_.id}
                    heading={_.tile_heading}
                    chart_type={_.chart_type}
                    chart_title={_.chart_title}
                    data={_.data}
                    readNotification={_.readNotification}
                    removeWidgetClicked={removeWidgetClicked}
                  />
                </div>
              );
            } else if (_.tile_type === "Resource") {
              return (
                <div
                  style={{ padding: "5px" }}
                  key={_.id}
                  draggable="true"
                  className="tile"
                  data-id={_.id}
                >
                  <ResourceBlk
                    id={_.id}
                    heading={_.tile_heading}
                    chart_type={_.chart_type}
                    chart_title={_.chart_title}
                    data={_.data}
                    removeWidgetClicked={removeWidgetClicked}
                    chosen_type={_.chosen_type}
                    chosen_text={_.chosen_text}
                    resource_types={all_available_resource_types}
                    resoChoiceClicked={resoChoiceClicked}
                  />
                </div>
              );
            } else if (_.tile_type === "Contact") {
              return (
                <div
                  style={{ padding: "5px" }}
                  key={_.id}
                  draggable="true"
                  className="tile"
                  data-id={_.id}
                >
                  <ContactBlk
                    id={_.id}
                    heading={_.tile_heading}
                    chart_type={_.chart_type}
                    chart_title={_.chart_title}
                    data={_.data}
                    removeWidgetClicked={removeWidgetClicked}
                  />
                </div>
              );
            } else if (_.tile_type === "Profile") {
              return (
                <div
                  style={{ padding: "5px" }}
                  key={_.id}
                  draggable="true"
                  className="tile"
                  data-id={_.id}
                >
                  <ProfileBlk
                    id={_.id}
                    heading={_.tile_heading}
                    chart_type=""
                    chart_title=""
                    data={_.data}
                    removeWidgetClicked={removeWidgetClicked}
                  />
                </div>
              );
            } else if (_.tile_type === "Billing") {
              return (
                <div
                  style={{ padding: "5px" }}
                  key={_.id}
                  draggable="true"
                  className="tile"
                  data-id={_.id}
                >
                  <BillingBlk
                    id={_.id}
                    heading={_.tile_heading}
                    chart_type={_.chart_type}
                    chart_title={_.chart_title}
                    data={_.data}
                    removeWidgetClicked={removeWidgetClicked}
                  />
                </div>
              );
            } else if (_.tile_type === "Live") {
              return (
                <div
                  style={{ padding: "5px" }}
                  key={_.id}
                  draggable="true"
                  className="tile"
                  data-id={_.id}
                >
                  <LiveBlk
                    id={_.id}
                    heading={_.tile_heading}
                    chart_type={_.chart_type}
                    chart_title={_.chart_title}
                    data={_.data}
                    selected={_.selected}
                    showSpaceDialogClicked={showSpaceDialogClicked}
                    removeWidgetClicked={removeWidgetClicked}
                  />
                </div>
              );
            }
          })
        ) : (
          <div></div>
        )}
      </Row>
      <Row className="extra-blank d-block d-lg-none">
        <BlankBlk
          handleShow={handleShow}
          key={Math.floor(Math.random() * 1000 + 1)}
        />
        <Modal
          show={showTile}
          onHide={function () {
            setShowTile(false);
          }}
        >
          <Modal.Header
            closeButton
            className={`${theme === "light" ? "" : "dark dark-blk"}`}
          >
            <Modal.Title>
              <div className="modal-padding-blk"></div>{" "}
              <div className="modal-title-blk">
                <span className={`${theme === "light" ? "" : "dark"}`}>
                  Add new widget
                </span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={`${theme === "light" ? "" : "dark dark-blk"}`}>
            <TilesMenus />
          </Modal.Body>
        </Modal>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  AuthDetail: state.loginUserDetail.userDetail,
  AuthLoggedIn: state.loginUserDetail.loggedIn,
  CSRFToken: state.loginUserDetail.csrf,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
