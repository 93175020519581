import billingActionType from "../actions/types/billingActionType";

const { GET_INVOCIES, GET_INVOCIES_SUCCESS, GET_INVOCIES_FAIL } =
  billingActionType;

const intialState = {
  invoices: [],
  loading: false,
};
const billingReducer = (state = intialState, action) => {
  switch (action.type) {
    case GET_INVOCIES:
      return {
        ...state,
        invoices: [],
        loading: true,
      };
    case GET_INVOCIES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        loading: false,
      };
    case GET_INVOCIES_FAIL:
      return {
        ...state,
        invoices: [],
        loading: false,
      };
    default:
      return state;
  }
};
export default billingReducer;
