import React, { useEffect, useRef, useState } from "react";
import { Image, Button } from "react-bootstrap";
import GoogleMapReact from "google-map-react";

import api from "../../config/api";
import config from "../../config/config";

import EditLogo from "../../assets/images/profile/edit-profile-img.svg";
import ProfileHolder from "../../assets/images/profile/profile-holder.svg";

function ProfileDetails({
  theme,
  token,
  csrf_token,
  AuthDetail,
  storeCSRF,
  getUserLoginDetail,
}) {
  const {
    USER_DETAILS,
    CHANGE_PASSWORD,
    LOGIN_USER,
    CHECK_PASSWORD,
    GET_CSRF_TOKEN,
  } = config.api_endpoint;
  const getCurrentSite = config.getCurrentSite;
  const profileImgRef = useRef(null);
  const gApi = config.googleAPIKey;

  const [disableInput, setDisableInput] = useState(true);
  const [profileError, setProfileError] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [editUserProfile, setEditUserProfile] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profileImg, setProfileImg] = useState(null);
  const [fullName, setFullName] = useState("");
  const [changePasswordEdit, setChangePasswordEdit] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [reNewPasswordError, setReNewPasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showReNewPassword, setShowReNewPassword] = useState(false);

  const [nameError, setNameError] = useState(false);

  // method

  const resetValues = () => {
    console.log("resetValues:", userProfile.full_name);
    setFullName(userProfile.full_name);
    setProfileImg(userProfile.img);
  };

  const resetErrors = () => {
    setNameError(false);
    setProfileError("");
  };

  const editProfileClicked = () => {
    console.log("editProfileClicked");
    setDisableInput(false);
  };

  const cancelProfileClicked = () => {
    console.log("cancelProfileClicked");
    setDisableInput(true);
    setEditUserProfile(userProfile);
    resetValues();
    resetErrors();
  };

  const validateUserProfile = () => {
    const name = editUserProfile.full_name;
    var errors = [];

    if (name === "") {
      setNameError(true);
      errors.push("name");
    } else {
      setNameError(false);
    }

    return errors;
  };

  const saveProfileClicked = () => {
    console.log("saveProfileClicked");
    let errors = validateUserProfile();
    if (errors.length > 0) {
      setProfileError("Invalid value on " + errors.toString() + ".");
    } else {
      resetErrors();
      setLoading(true);
      let formData = new FormData();
      formData.append("username", AuthDetail.username);
      formData.append("name", editUserProfile.full_name);
      formData.append("img", editUserProfile.img);

      api
        .post(USER_DETAILS, formData, csrf_token)
        .then((res) => {
          console.log("User details save: ", res);
          if (res["status"] == "Success") {
            alert("Successfully saved user data.");
          } else {
            alert("Unable to save user data.");
          }
          cancelProfileClicked();
          getUserLoginDetail(AuthDetail.username);
          loadData();
        })
        .catch((err) => {
          console.log("User details err", err);
          setLoading(false);
          alert("Unable to save user data.");
        });
    }
  };

  const onChangeUserProfile = (key, value) => {
    setEditUserProfile({
      ...editUserProfile,
      [key]: value,
    });
  };

  const profileImgChanged = (event) => {
    console.log("profileImgChanged");
    onChangeUserProfile("img", event.target.files[0]);
    setProfileImg(event.target.files[0]);
  };

  const profileImgClicked = (event) => {
    console.log("profileImgClicked");
    event.preventDefault();
    profileImgRef.current.click();
  };

  const changePasswordClicked = () => {
    console.log("changePasswordClicked");
    setChangePasswordEdit(true);
  };

  const cancelChangePasswordEdit = () => {
    console.log("cancelChangePasswordEdit");
    setOldPassword("");
    setOldPasswordError(false);
    setNewPassword("");
    setNewPasswordError(false);
    setReNewPassword("");
    setReNewPasswordError(false);
    setPasswordError("");
    setChangePasswordEdit(false);

    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowReNewPassword(false);
  };

  const saveChangePassword = () => {
    setOldPasswordError(false);
    setNewPasswordError(false);
    setReNewPasswordError(false);
    setPasswordError("");

    var errors = [];

    if (oldPassword == "") {
      errors.push("Old password cannot be blank.");
      setOldPasswordError(true);
    }

    if (newPassword == "") {
      errors.push("New password cannot be blank.");
      setNewPasswordError(true);
    }

    if (reNewPassword == "") {
      errors.push("Retype new password cannot be blank.");
      setReNewPasswordError(true);
    }

    if (newPassword != reNewPassword) {
      errors.push("Passwords do not match.");
      setNewPasswordError(true);
      setReNewPasswordError(true);
    }

    var regexes = config.regexes;
    var flag = true;
    for (var i = 0; i < regexes.length; i++) {
      if (!regexes[i].test(newPassword)) {
        flag = false;
      }
    }

    if (!flag) {
      errors.push("Password does not fullfil minimum criteria.");
      setNewPasswordError(true);
      setReNewPasswordError(true);
    }

    if (errors.length > 0) {
      errors = errors.toString();
      errors = errors.replaceAll(",", " ");
      setPasswordError(errors);
    } else {
      setLoading(true);

      api
        .post(
          CHECK_PASSWORD,
          JSON.stringify({
            username: AuthDetail.username,
            password: oldPassword,
          })
        )
        .then((res) => {
          console.log("Check password: ", res);
          if (res["status"] != "Success") {
            setLoading(false);
            setPasswordError("Incorrect password.");
            setOldPasswordError(true);
          } else {
            var formData = new FormData();
            formData.append("password ", newPassword);

            api
              .post(CHANGE_PASSWORD, formData, csrf_token)
              .then((res) => {
                console.log("Change password save: ", res);

                if (res["status"] == "Success") {
                  // alert("Successfully changed password. You will need to login with the new password again.");
                  // window.location.href = "/";
                  api
                    .post(
                      LOGIN_USER,
                      JSON.stringify({
                        username: AuthDetail.username,
                        password: newPassword,
                      })
                    )
                    .then((result) => {
                      api
                        .get(GET_CSRF_TOKEN)
                        .then((res) => {
                          storeCSRF(res["csrf"]);
                          getUserLoginDetail(AuthDetail.username);
                        })
                        .catch((err) => console.log("err", err));

                      setLoading(false);
                      alert("Your password has been updated successfully.");
                    })
                    .catch((err) => {
                      console.log("err", err);
                      setLoading(false);
                    });
                } else {
                  alert("Unable to change password.");
                }
              })
              .catch((err) => {
                console.log("Change password err: ", err);
                setLoading(false);
                alert("Unable to change password.");
              })
              .finally(() => {
                cancelChangePasswordEdit();
              });
          }
        })
        .catch((err) => {
          console.log("Check password:", err);
        });
    }
  };

  // useEffect
  const loadData = () => {
    setLoading(true);
    api
      .get(USER_DETAILS + "?username=" + AuthDetail.username)
      .then((res) => {
        console.log("Profile res: ", res);
        setUserProfile(res["results"]);
        setEditUserProfile(res["results"]);
        setFullName(res["results"]["full_name"]);
        setProfileImg(res["results"]["img"]);

        for (var ix = 0; ix < res["results"].company.length; ix++) {
          if (res["results"].company[ix]["name"] == getCurrentSite()) {
            setCompany(res["results"].company[ix]);
            if (
              res["results"].company[ix]["lat"] &&
              res["results"].company[ix]["lng"]
            ) {
              setShowMap(true);
            } else {
              setShowMap(false);
            }
            break;
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log("Profile err", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const AnyReactComponent = (text) => {
    return (
      <>
        <div className="pin"></div>
        <div className="pulse"></div>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <div className="text-center company-loading">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <div className="profile-details">
          <br></br>
          <b>
            <label className="profile-label-head">My details</label>
          </b>
          {disableInput && (
            <Button
              className="btn edit-profile-btn"
              type="button"
              onClick={editProfileClicked}
            >
              <i className="bi bi-pencil-square"></i> Edit
            </Button>
          )}

          {!disableInput && (
            <>
              <Button
                className="btn cancel-profile-btn"
                type="button"
                onClick={cancelProfileClicked}
              >
                <i className="bi bi-x-circle"></i> Cancel
              </Button>
              <Button
                className="btn save-profile-btn"
                type="button"
                onClick={saveProfileClicked}
              >
                <i className="bi bi-save"></i> Save
              </Button>
            </>
          )}

          <br></br>
          <br></br>
          {profileError && (
            <div
              className="alert alert-danger d-flex align-items-center"
              role="alert"
            >
              <i className="bi bi-exclamation-triangle-fill"></i> &nbsp;&nbsp;
              <div>{profileError}</div>
            </div>
          )}
          <Image
            id="user-profile-img"
            className="img-fluid profile-img"
            src={
              typeof profileImg === "object"
                ? URL.createObjectURL(profileImg)
                : typeof profileImg === "string"
                ? profileImg + token
                : ProfileHolder
            }
            disabled={disableInput ? "disabled" : ""}
          />
          <Image
            src={EditLogo}
            className={`edit-profile-img ${disableInput && "hidden"}`}
            onClick={profileImgClicked}
          />
          <hr></hr>
          <input
            id="profileImg"
            type="file"
            name="profileImg"
            placeholder="Photo"
            required=""
            capture
            accept="image/*"
            style={{ display: "none" }}
            disabled={disableInput ? "disabled" : ""}
            onChange={profileImgChanged}
            ref={profileImgRef}
          />

          <label className="profile-label">Email:</label>
          <span className="profile-value">{editUserProfile.email}</span>
          <hr></hr>

          <label className="profile-label">Display Name:</label>
          <span className="profile-input">
            <input
              id="user_fullname"
              type="text"
              value={fullName}
              disabled={disableInput ? "disabled" : ""}
              className={`${nameError && "input_error_cls"}`}
              onChange={(e) => {
                onChangeUserProfile("full_name", e.target.value);
                setFullName(e.target.value);
              }}
            />
          </span>
          <hr></hr>

          <label className="profile-label">Company:</label>
          <span className="profile-value">{company.title}</span>
          <hr></hr>

          {!changePasswordEdit && (
            <Button
              type="button"
              className="btn change-password"
              onClick={changePasswordClicked}
            >
              Change password
            </Button>
          )}

          {changePasswordEdit && (
            <>
              <Button
                className="btn cancel-profile-btn"
                style={{ marginLeft: "0px" }}
                type="button"
                onClick={cancelChangePasswordEdit}
              >
                <i className="bi bi-x-circle"></i> Cancel
              </Button>
              <Button
                className="btn save-profile-btn"
                type="button"
                onClick={saveChangePassword}
              >
                <i className="bi bi-save"></i> Save
              </Button>
              <br></br>
              <br></br>

              {passwordError && (
                <div
                  className="alert alert-danger d-flex align-items-center"
                  role="alert"
                >
                  <i className="bi bi-exclamation-triangle-fill"></i>{" "}
                  &nbsp;&nbsp;
                  <div>{passwordError}</div>
                </div>
              )}

              <p>
                Please note that your password must contain at least 8
                characters, one number, one capital letter & one special
                character
              </p>
              <label className="profile-label">Confirm Old Password:</label>
              <span className="profile-input">
                <input
                  id="old_password"
                  type={!showOldPassword ? "password" : "text"}
                  defaultValue={oldPassword}
                  className={`${oldPasswordError && "input_error_cls"}`}
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                />
                <i
                  className={`bi ${
                    showOldPassword ? "bi-eye-slash" : "bi-eye"
                  } password-icons`}
                  onClick={(e) => {
                    if (showOldPassword) {
                      setShowOldPassword(false);
                    } else {
                      setShowOldPassword(true);
                    }
                  }}
                ></i>
              </span>
              <label className="profile-label">New Password:</label>
              <span className="profile-input">
                <input
                  id="new_password"
                  type={!showNewPassword ? "password" : "text"}
                  defaultValue={newPassword}
                  className={`${newPasswordError && "input_error_cls"}`}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
                <i
                  className={`bi ${
                    showNewPassword ? "bi-eye-slash" : "bi-eye"
                  } password-icons`}
                  onClick={(e) => {
                    if (showNewPassword) {
                      setShowNewPassword(false);
                    } else {
                      setShowNewPassword(true);
                    }
                  }}
                ></i>
              </span>
              <label className="profile-label">Retype New Password:</label>
              <span className="profile-input">
                <input
                  id="re_new_password"
                  type={!showReNewPassword ? "password" : "text"}
                  defaultValue={reNewPassword}
                  className={`${reNewPasswordError && "input_error_cls"}`}
                  onChange={(e) => {
                    setReNewPassword(e.target.value);
                  }}
                />
                <i
                  className={`bi ${
                    showReNewPassword ? "bi-eye-slash" : "bi-eye"
                  } password-icons`}
                  onClick={(e) => {
                    if (showReNewPassword) {
                      setShowReNewPassword(false);
                    } else {
                      setShowReNewPassword(true);
                    }
                  }}
                ></i>
              </span>
            </>
          )}
          <br></br>
          <br></br>
          {showMap && (
            <div className="gmap-wrapper">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: gApi,
                }}
                options={{
                  fullscreenControl: false,
                }}
                center={{ lat: company.lat, lng: company.lng }}
                zoom={16}
              >
                <AnyReactComponent
                  lat={company.lat}
                  lng={company.lng}
                  text="My Marker"
                />
              </GoogleMapReact>
            </div>
          )}
          <br></br>
          <br></br>
        </div>
      )}
    </>
  );
}

export default ProfileDetails;
