import moment from "moment";

export const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
let monthNames = [
  {
    name: "January",
    amount: 0,
  },
  {
    name: "February",
    amount: 0,
  },
  {
    name: "March",
    amount: 0,
  },
  {
    name: "April",
    amount: 0,
  },
  {
    name: "May",
    amount: 0,
  },
  {
    name: "June",
    amount: 0,
  },
  {
    name: "July",
    amount: 0,
  },
  {
    name: "August",
    amount: 0,
  },
  {
    name: "September",
    amount: 0,
  },
  {
    name: "October",
    amount: 0,
  },
  {
    name: "November",
    amount: 0,
  },
  {
    name: "December",
    amount: 0,
  },
];

export const data = (data) => {
  let modifyData = [];
  data.map((d) => {
    let formatMon = moment(d.timestamp).format("MMMM");
    let search = monthNames.findIndex((m) => m.name == formatMon);

    monthNames[search].amount = d.amount;
    // monthNames[search].amount = 100000000;
  });

  monthNames.map((_) => {
    modifyData.push(_.amount);
  });

  return {
    labels,
    labelBackgrounds: [
      "rgba(120,220,220,0.2)",
      "rgba(220,120,220,0.2)",
      "rgba(220,220,120,0.2)",
      "rgba(120,120,220,0.2)",
      "rgba(120,220,120,0.2)",
      "rgba(220,120,120,0.2)",
      "rgba(120,120,120,0.2)",
    ],
    datasets: [
      {
        // label: "Pokemon Stats",
        backgroundColor: "#11C126",

        highlightFill: "orange",
        data: modifyData,
        borderWidth: 0,
        borderRadius: 50,
        borderSkipped: false,
        barPercentage: 0.2,
        enabled: true,
      },
    ],
  };
};

export const options = {
  elements: {
    point: {
      radius: 0,
    },
  },
  hover: {
    intersect: false,
  },
  tooltips: {
    enabled: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
    legend: {
      display: false,
    },
    annotation: {
      annotations: [
        {
          type: "box",
          yScaleID: "y-axis-0",
          xMin: -0.1,
          xMax: 0.1,
          borderWidth: 0,
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderRadius: 50,
        },
        {
          type: "box",
          drawTime: "afterDatasetsDraw",
          yScaleID: "y-axis-2",
          xMin: 0.9,
          xMax: 1.1,
          borderWidth: 0,
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderRadius: 50,
        },
        {
          type: "box",
          drawTime: "afterDatasetsDraw",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderWidth: 0,
          xMin: 1.9,
          xMax: 2,
          borderRadius: 50,
          scaleID: "y-axis-0",
        },
        // {
        //   type: "box",
        //   yScaleID: "y-axis-0",
        //   xMin: 1.9,
        //   xMax: 2.1,
        //   borderWidth: 0,
        //   backgroundColor: "rgba(0, 0, 0, 0.1)",
        //   borderRadius: 50,
        // },
        {
          type: "box",
          yScaleID: "y-axis-0",
          xMin: 2.9,
          xMax: 3.1,
          borderWidth: 0,
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderRadius: 50,
        },
      ],
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    yAxes: [
      {
        stacked: true,
        display: false,
      },
    ],
    y: {
      stacked: true,
      beginAtZero: true,
      grace: "1",
      grid: {
        display: true,
      },
      ticks: {
        callback: function (value) {
          return "$" + value;
        },
      },
    },
  },
};
