import React, { useEffect, useState, useRef } from "react";
import { Button, FloatingLabel, Form, Modal, InputGroup } from "react-bootstrap";
import { useParams } from 'react-router-dom'
import { useMediaQuery } from "react-responsive";

import api from "../../config/api";
import config from "../../config/config";
import { connect } from "react-redux";

import { getUserLoginDetail, storeCSRF } from "../../store/actions/loginUserAction";

import "./forceResetPassword.css";

import PdfViewer from "../../components/PdfViewer";

function ForceResetPassword(props) {

  const theme = localStorage.getItem("theme");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirectLoading, setRedirectLoading] = useState(false);
  const [resetUser, setResetUser] = useState("");

  const [tncChecked, setTncChecked] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [showTNC, setShowTNC] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 765 });

  let useParams = window.location.search;
  let searchParams = new URLSearchParams(useParams);

  const { FORCE_RESET_PASSWORD, LOGIN_USER, GET_CSRF_TOKEN, GET_SITE, GET_RESET_USER, GET_TOKEN } = config.api_endpoint;

  const resetFunc = (e) => {
    e.preventDefault();
    var reset_code = searchParams.get('reset_code') || props.AuthDetail.reset_code;

    setErrorMessage("");
    setError(false);

    if (!reset_code) {
      setErrorMessage("Reset code not available.");
      setError(true);
    }
    else if (password == "" || confirmPassword == "") {
      setErrorMessage("Passwords do not match.");
      setError(true);
    }
    else if (password != confirmPassword) {
      setErrorMessage("Passwords do not match.");
      setError(true);
    }
    else if (!tncChecked) {
      setErrorMessage("Please read and accept the ToS.");
      setError(true);
    }
    else {

      var regexes = config.regexes;
      var flag = true;
      for (var i = 0; i < regexes.length; i++) {
        if (!regexes[i].test(password)) {
          flag = false;
        }
      }

      if (!flag) {
        setErrorMessage("Password does not fullfil minimum criteria.");
        setError(true);
      }
      else {
        setLoading(true);
        api
          .post(
            FORCE_RESET_PASSWORD,
            JSON.stringify({
              password: password,
              reset_code: reset_code
            })
          )
          .then((result) => {
            console.log("result: ", result);
            setLoading(false);
            if (result["status"] === "Error") {
              setErrorMessage(result["details"]);
              setError(true);
              props.setSignIn(false);
            }
            else {
              setErrorMessage("");
              setError(false);
              setLoading(false);

              setRedirectLoading(true);

              // Login again
              api
                .post(
                  LOGIN_USER,
                  JSON.stringify({
                    username: resetUser,
                    password: password
                  })
                )
                .then((result) => {
                  console.log("result: ", result);
                  if (result["status"] != "Error") {

                    if(result["results"]["company"].length > 0) {
                      document.getElementById("kaer_current_now").value = result["results"]["company"][0]["name"];
                    }

                    api
                      .get(GET_CSRF_TOKEN)
                      .then((res) => {
                        props.storeCSRF(res["csrf"]);

                        props.getUserLoginDetail(resetUser);
                        props.setSignIn(true);
                      })
                      .catch((err) => console.log("err", err));

                      api
                        .get(GET_SITE)
                        .then((res) => {
                          var re = [];
                          res["results"].forEach((item, i) => {
                            re.push(item["name"]);
                          });
                          localStorage.setItem("all_sites", re);

                        })
                        .catch((err) => console.log("err", err));

                        setTimeout(function () {
                          setRedirectLoading(false);
                          window.location.href = "/";
                        }, 3000);

                  }
                })
                .catch((err) => {
                  console.log("err", err);
                  setLoading(false);
                  setErrorMessage("Incorrect username or password!");
                  setError(true);
                  props.setSignIn(false);
                });
            }
          })
          .catch((err) => {
            console.log("err", err);
            setLoading(false);
            setErrorMessage("Error!");
            setError(true);
            props.setSignIn(false);
          });
      }
    }
  }

  const [token, setToken] = useState(localStorage.getItem("imgToken"));
  const [pdfFileModal, setPdfFileModal] = useState(false);
  const [pdfFile, setPdfFile] = useState({
    url: "",
    fileame: "",
  });
  const [pdfZoom, setPdfZoom] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const prevHandle = () => {
    if (pageNumber <= 1) {
      setPageNumber(1);
    } else {
      setPageNumber((prev) => prev - 1);
    }
  };

  const nextHandle = () => {
    if (pageNumber >= numPages) {
      setPageNumber(numPages);
    } else {
      setPageNumber((prev) => prev + 1);
    }
  };

  const downloadFile = (url, filename) => {
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: "application/pdf",
      });

      const isIE = false || !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || window.webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", filename);
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  };

  const tncClicked = (e) => {
    e.preventDefault();
    setPdfFileModal(true);
  }

  // const [token, setToken] = useState(localStorage.getItem("imgToken"));
  // const [termsPdf, setTermsPdf] = useState();

  useEffect(() => {
    console.log("RESET PASSWORD useParams >>> ", useParams);
    console.log("reset_code: ", searchParams.get('reset_code'))
    console.log("AuthDetail.reset_code:  ", props.AuthDetail.reset_code);

    api.get(GET_TOKEN)
      .then((res) => {
        setToken(res["results"]["token"]);
        localStorage.setItem("imgToken", res["results"]["token"]);
      })
      .catch((err) => {
        console.log("Image token err", err);
      });

    var resetCode = searchParams.get('reset_code') || props.AuthDetail.reset_code;

    api
      .get(GET_RESET_USER + "?reset_code=" + resetCode)
      .then((res) => {
        if (res["status"] != "Error") {
          setResetUser(res["results"]["username"]);
        }
        else {
          alert("Invalid reset code. Will redirect back to the main page.");
          window.location.href = "/";
        }
      })
      .catch((err) => console.log("err", err));

  }, [])

  useEffect(() => {
    if(token) {
      if (config.url == "https://api.kaerconnect.com/kaer") {
        setPdfFile({
          url: "https://files.kaerconnect.com/terms_of_service/Kaer_Connect_Terms_of_Service.pdf" + token,
          fileame: "Kaer_Connect_Terms_of_Service.pdf"
        });
      }
      else {
        setPdfFile({
          url: "https://kaerdev-cdn.int.weeswares.com/terms_of_service/Kaer_Connect_Terms_of_Service.pdf" + token,
          fileame: "Kaer_Connect_Terms_of_Service.pdf"
        });
      }
    }
  }, [token])

  // <Modal show={showTNC}
  //   className={`${isMobile && "modal-sm"} ${!isMobile && "modal-md"}`}
  //   onHide={function () {
  //     setShowTNC(false);
  //   }}
  // >
  //   <Modal.Header
  //     closeButton
  //   >
  //     <Modal.Title>
  //       <h3>Terms of Service (ToS)</h3>
  //     </Modal.Title>
  //   </Modal.Header>
  //   <Modal.Body>
  //     <div style={{ height: "500px" }}>
  //       <iframe
  //           sandbox="allow-scripts allow-same-origin"
  //           src={"https://drive.google.com/viewerng/viewer?embedded=true&url=" + encodeURIComponent(termsPdf)}
  //           frameBorder="0"
  //           scrolling="auto"
  //           height="100%"
  //           width="100%"
  //       ></iframe>
  //     </div>
  //   </Modal.Body>
  // </Modal>

  return (
    <div className={`force-reset-blk${theme === "light" ? "" : "-dark"}`}>
      <div style={{ maxWidth: "400px" }} className="force-reset-sub-blk">
        <Form>
          { loading && (
            <Modal show={loading} data-backdrop="static" data-keyboard="false" className="loader">
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            </Modal>
          )}

          { pdfFileModal == true && (
            <div className="pdf-container-view"
            >
              <div
                className="modal_view_overlay"
              >
                <div
                  className="modal_view_overlay"
                  style={{ zIndex: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setPdfFileModal(false);
                  }}
                ></div>
                <div className="modal_header">
                  <div className="move_fun">
                    <div onClick={prevHandle} className="pdf-button">
                      {"<"}
                    </div>

                    <div className="pdf-currentPageNumber">
                      {pageNumber} / {numPages}
                    </div>

                    <div onClick={nextHandle} className="pdf-button">
                      {" "}
                      {">"}
                    </div>
                  </div>
                  <div className="right-side-pdf-header">
                    <div
                      onClick={() => {
                        setPdfZoom((prev) => prev + 1);
                      }}
                      style={{
                        marginRight: 25,
                        fontSize: 30,
                      }}
                    >
                      +
                    </div>
                    <div
                      onClick={() => {
                        setPdfZoom((prev) => (prev === 1 ? 1 : prev - 1));
                      }}
                      style={{
                        fontSize: 30,
                      }}
                    >
                      -
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <div
                        onClick={() => downloadFile(pdfFile.url, "Kaer Connect Terms of Service")}
                        style={{
                          marginRight: 25,
                          marginLeft: 25,
                          fontWeight: 500,
                          fontSize: 16,
                        }}
                      >
                        Download
                        {/* <DownloadIcon
                          style={{
                            width: 30,
                            height: 21,
                            marginTop: -5,
                          }}
                        /> */}
                      </div>
                      <div onClick={() => setPdfFileModal(false)}>X</div>
                    </div>
                  </div>
                </div>

                <PdfViewer
                  setPdfFileModal={setPdfFileModal}
                  pdfFile={pdfFile}
                  numPages={numPages}
                  setNumPages={setNumPages}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  pdfZoom={pdfZoom}
                />
              </div>
            </div>
          )}

          { redirectLoading && (
            <Modal show={redirectLoading} data-backdrop="static" data-keyboard="false" className="loader">
              <div className="text-center">
                <h4 style={{ color: "#FFFFFF" }}>Password updated successfully.</h4>
                <h4 style={{ color: "#FFFFFF" }}>You will be redirected to Kaer Connect.</h4>
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            </Modal>
          )}
          <h3 className="resetTitle">Please set your password</h3>
          <p className="resetHeader">Please note that your password must contain at least 8 characters,
          one number, one capital letter & one special character</p>
          <p><input type="checkbox" id="txtTNC" defaultChecked={tncChecked}
            onClick={(e) => {
              if (tncChecked) {
                setTncChecked(false);
              }
              else {
                setTncChecked(true);
              }
            }}
           /> I agree to the Kaer Connect Terms of Service (ToS)
          (click <a href="" target="_blank" onClick={tncClicked}>here</a> to read the ToS)</p>
          { error && (
            <div className="alert alert-danger login-alert" role="alert" style={{padding: "0.5em"}}>
              <i className="bi bi-exclamation-circle-fill"></i> &nbsp;
              {errorMessage}
            </div>
          )}
          <Form.Group className="mb-3" controlId="formBasicPassword">
              <InputGroup>
                  <FloatingLabel label="Password">
                    <Form.Control type={!showPassword ? "password" : "text"} placeholder="Password" className="password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                     />
                  </FloatingLabel>
                  <InputGroup.Text>
                    <i className={`bi ${!showPassword ? "bi-eye" : "bi-eye-slash"}`}
                      onClick={(e) => {
                        if (showPassword) {
                          setShowPassword(false);
                        }
                        else {
                          setShowPassword(true);
                        }
                      }}
                    ></i>
                  </InputGroup.Text>
              </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <InputGroup>
                <FloatingLabel label="Confirm Password">
                  <Form.Control type={!showConfirmPassword ? "password" : "text"} placeholder="Confirm Password" className="confirm_password"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </FloatingLabel>
                <InputGroup.Text>
                  <i className={`bi ${!showConfirmPassword ? "bi-eye" : "bi-eye-slash see-password"}`}
                    onClick={(e) => {
                      if (showConfirmPassword) {
                        setShowConfirmPassword(false);
                      }
                      else {
                        setShowConfirmPassword(true);
                      }
                    }}
                  ></i>
                </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Button className="btn btn-primary"
          type="submit" className="reset-button-color"
          onClick={resetFunc}>
            Set Password
          </Button>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  AuthDetail: state.loginUserDetail.userDetail,
  AuthLoggedIn: state.loginUserDetail.loggedIn,
  CSRFToken: state.loginUserDetail.csrf,
});

const mapDispatchToProps = (dispatch) => ({
  getUserLoginDetail: (username) => dispatch(getUserLoginDetail(username)),
  storeCSRF: (token) => dispatch(storeCSRF(token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForceResetPassword);
