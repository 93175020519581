import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getUserLoginDetail, storeCSRF } from "../../store/actions/loginUserAction";

// UI
import { Button, Form, FloatingLabel, Modal, InputGroup } from 'react-bootstrap';

// API
import config from "../../config/config";
import api from "../../config/api";

function Login(props) {

  const { LOGIN_USER, GET_CSRF_TOKEN, GET_SITE } = config.api_endpoint;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const loginFunc = (e) => {
    e.preventDefault();

    setLoading(true);
    api
      .post(
        LOGIN_USER,
        JSON.stringify({
          username: username,
          password: password
        })
      )
      .then((result) => {
        console.log("result: ", result);
        setLoading(false);
        if (result["status"] === "Error") {
          setErrorMessage(result["details"]);
          setError(true);
          props.setSignIn(false);
        }
        else {
          if(result["results"]["company"].length > 0) {
            document.getElementById("kaer_current_now").value = result["results"]["company"][0]["name"];
          }

          api
            .get(GET_CSRF_TOKEN)
            .then((res) => {
              props.storeCSRF(res["csrf"]);

              props.getUserLoginDetail(username);
              props.setSignIn(true);
              setErrorMessage("");
              setError(false);

            })
            .catch((err) => console.log("err", err));

            api
              .get(GET_SITE)
              .then((res) => {
                var re = [];
                res["results"].forEach((item, i) => {
                  re.push(item["name"]);
                });
                localStorage.setItem("all_sites", re);

              })
              .catch((err) => console.log("err", err));

        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setErrorMessage("Incorrect username or password!");
        setError(true);
        props.setSignIn(false);
      });
  }

  return (
    <>
    <Form style={{ paddingBottom: "1em" }} className="login-sub-blk">
      <br></br>
      <hr></hr>
      <br></br>
      { error && (
        <div className="alert alert-danger login-alert" role="alert" style={{padding: "0.5em"}}>
          <i className="bi bi-exclamation-circle-fill"></i> &nbsp;
          {errorMessage}
        </div>
      )}
      { loading && (
        <Modal show={loading} data-backdrop="static" data-keyboard="false" className="loader">
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </Modal>
      )}
      <Form.Group className="mb-3" controlId="formBasicEmail" autoComplete="off">
        <FloatingLabel label="Name">
          <Form.Control type="text" placeholder="Name" className="username"
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </FloatingLabel>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <InputGroup>
          <FloatingLabel label="Password" className="floating-password">
            <Form.Control type={!showPassword ? "password" : "text"} placeholder="Password" className="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
             />
          </FloatingLabel>
          <InputGroup.Text>
            <i className={`bi ${!showPassword ? "bi-eye" : "bi-eye-slash see-password"}`}
              onClick={(e) => {
                if (showPassword) {
                  setShowPassword(false);
                }
                else {
                  setShowPassword(true);
                }
              }}
            ></i>
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Remember me" defaultChecked className="rememberMe" />
      </Form.Group>
      <Button className="btn btn-primary"
      type="submit" className="login-button-color"
      onClick={loginFunc} >
        Login
      </Button>
    </Form>
    </>
  );
}

const mapStateToProps = (state) => ({
  AuthLoading: state.loginUserDetail.loading
});

const mapDispatchToProps = (dispatch) => ({
  getUserLoginDetail: (username) => dispatch(getUserLoginDetail(username)),
  storeCSRF: (token) => dispatch(storeCSRF(token)),
});
;
export default connect(mapStateToProps, mapDispatchToProps)(Login);
