import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Modal, Dropdown } from "react-bootstrap";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

import "./data.css";
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as LessSignIcon } from "../../assets/images/Data/lessSign.svg";
import { ReactComponent as GreaterSignIcon } from "../../assets/images/Data/greaterSign.svg";
import PeakIcon from "../../assets/images/Data/peak.svg";
import DeliveredIcon from "../../assets/images/Data/delivered.svg";
import ElectricityIcon from "../../assets/images/Data/electricity.svg";
import WaterIcon from "../../assets/images/Data/water.svg";
import EffciencyIcon from "../../assets/images/Data/effciency.svg";
import CarbonIcon from "../../assets/images/Data/carbon.svg";
import CalendarIcon from "../../assets/images/Data/calendar.svg";
import DownloadIcon from "../../assets/images/billing/download.svg";

import ChartOne from "./Chart/ChartOne";
import SpaceTempatureChart from "./Chart/SpaceTempatureChart";
import ChartTwo from "./Chart/ChartTwo";
import PreChartIndex from "./PreMonthChart/";

import config from "../../config/config";

import { fetchDataCount } from "../../store/actions/dataAction";
import { connect } from "react-redux";
import { isSameISOWeek, isSameWeek } from "date-fns";

function Index(props) {

  const getCurrentSite = config.getCurrentSite;

  let selectedDateMethod = localStorage.getItem("selectedDateMethod");
  const [previousDate, setPreviousDate] = useState(
    selectedDateMethod ? selectedDateMethod : "Day"
  );
  const [previosCalendar, setPreviousCalendar] = useState({
    from_date: moment(new Date()).format("YYYY-MM-DD"),
    to_date: moment(new Date()).format("YYYY-MM-DD"),
  });

  let selectedDateLocal = localStorage.getItem("selectedDate");

  const [selectdDatePicker, setSelectedDatePicker] = useState(
    selectedDateLocal ? new Date(selectedDateLocal) : new Date()
  );

  const isMobile = useMediaQuery({ maxWidth: 800 });
  const [headerData, setHeaderData] = useState([
    {
      title: "Peak cooling",
      value: "-",
      image: PeakIcon,
      sign: "RT",
      obj_title: "peak_cooling",
    },
    {
      title: "Cooling delivered",
      value: "-",
      image: DeliveredIcon,
      sign: "RTH",
      obj_title: "cooling_delivered",
    },
    {
      title: "Electricity used",
      value: "-",
      image: ElectricityIcon,
      sign: "kWh",
      obj_title: "electricity_used",
    },
    {
      title: "Water used",
      value: "-",
      image: WaterIcon,
      sign: "m",
      obj_title: "water_used",
    },
    {
      title: "Efficiency",
      value: "-",
      image: EffciencyIcon,
      sign: "kW/RT",
      obj_title: "efficiency",
    },
    {
      title: "Carbon emissions",
      value: "-",
      image: CarbonIcon,
      sign: "MT",
      obj_title: "carbon_emission",
    },
  ]);

  let theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  const onDateRangeChosen = (date) => {
    let from_ts = moment(date).format("YYYY-MM-DD");
    let to_date = moment(date).format("YYYY-MM-DD");

    if (previousDate == "Day") {
      from_ts = moment(date).format("YYYY-MM-DD");
    } else if (previousDate == "Week") {
      from_ts = moment(date).startOf("week").format("YYYY-MM-DD");
      to_date = moment(date).endOf("week").format("YYYY-MM-DD");
    } else if (previousDate == "Month") {
      from_ts = moment(date).startOf("month").format("YYYY-MM-DD");
      to_date = moment(date).endOf("month").format("YYYY-MM-DD");
    } else if (previousDate == "Year") {
      from_ts = moment(date).startOf("year").format("YYYY-MM-DD");
      to_date = moment(date).endOf("year").format("YYYY-MM-DD");
    }
    localStorage.setItem("selectedDate", date);
    setSelectedDatePicker(date);
    props.fetchDataCount(from_ts, to_date);
    // setPreviousCalendar(date);
    // setFromTs(date); setToTs(date);
    // var tmpDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    // loadDailyData(tmpDate, tmpDate);
  };
  useEffect(() => {
    let from_date = moment(selectdDatePicker).format("YYYY-MM-DD");
    let to_date = moment(selectdDatePicker).format("YYYY-MM-DD");

    if (previousDate == "Day") {
      from_date = moment(selectdDatePicker).format("YYYY-MM-DD");
    } else if (previousDate == "Week") {
      from_date = moment(selectdDatePicker)
        .startOf("week")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker).endOf("week").format("YYYY-MM-DD");
    } else if (previousDate == "Month") {
      from_date = moment(selectdDatePicker)
        .startOf("month")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker).endOf("month").format("YYYY-MM-DD");
    } else if (previousDate == "Year") {
      from_date = moment(selectdDatePicker)
        .startOf("year")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker).endOf("year").format("YYYY-MM-DD");
    }

    setPreviousCalendar({
      from_date,
      to_date,
    });
    props.fetchDataCount(from_date, to_date);
  }, [previousDate]);

  const CustomCalendar = React.forwardRef((props, ref) => {
    return (
      <div ref={ref} className={`calendar ${theme}`}>
        <div onClick={subtractDate} className="sign">
          <LessSignIcon />
          {/* <img src={LessSignIcon} /> */}
        </div>
        <div style={{ width: "90%" }}>
          <div
            onClick={props.onClick}
            style={{
              fontSize: 14,
              color: "#373737",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={CalendarIcon} />
            <span style={{ marginLeft: 10 }}>
              {previousDate === "Day"
                ? moment(props.value).format("DD MMMM YYYY")
                : previousDate === "Week"
                ? moment(props.value).startOf("week").format("DD MMMM YYYY") +
                  " - " +
                  moment(props.value).endOf("week").format("DD MMMM YYYY")
                : previousDate === "Month"
                ? moment(props.value).startOf("month").format("DD MMMM YYYY") +
                  " - " +
                  moment(props.value).endOf("month").format("DD MMMM YYYY")
                : previousDate === "Year"
                ? moment(props.value).startOf("year").format("DD MMMM YYYY") +
                  " - " +
                  moment(props.value).endOf("year").format("DD MMMM YYYY")
                : null}
            </span>
          </div>
        </div>
        <div>
          <div onClick={nextDate} className="sign">
            <GreaterSignIcon />
          </div>
        </div>
      </div>
    );
  });

  const showNumberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const Card = (data) => {
    const { title, value, image, sign, obj_title } = data;
    return (
      <div className="card">
        <div className="title">{title}</div>
        <div>
          <img src={image} />
          {}
        </div>
        <div>
          {/* <span className="count">{showNumberWithCommas(value)}</span> */}

          <span className="count">
            {!props.loading
              ? props.data_count[obj_title] == ""
                ? "-"
                : props.data_count[obj_title] == "-"
                ? "-"
                : obj_title === "electricity_used"
                ? Number(parseInt(props.data_count[obj_title])).toLocaleString()
                : Number(props.data_count[obj_title]).toLocaleString()
              : "-"}
          </span>

          <span className="sign">
            {sign} {sign === "m" && <sup>3</sup>}
          </span>
        </div>
      </div>
    );
  };

  const HeaderList = () => {
    return (
      <div
        style={{
          background: theme == "light" ? "white" : "#000",
          border: "1px solid #dddddd",
          marginTop: 15,
          width: isMobile ? "90%" : "100%",
        }}
      >
        <div className={`header_list ${theme}`}>
          {headerData.map((_) => Card(_))}
        </div>
        {new Date(selectdDatePicker).setHours(0, 0, 0, 0) ===
          new Date().setHours(0, 0, 0, 0) &&
          previousDate == "Day" && (
            <div
              style={{
                textAlign: "center",
                marginBottom: 8,
                color: theme == "light" ? "#000" : "white",
              }}
            >
              These figures are updated at the end of each day so will not be
              displayed when viewing <b>today</b>.
            </div>
          )}
      </div>
    );
  };

  let titles = [
    "Cooling profile (RT)",
    "Chilled water supply temperature",
    "Space temperature & RH",
  ];

  // useEffect(() => {
  //   console.log("previousDate is changed: ", previousDate);
  //   var dateRange = getDateRangeForData(previousDate);
  //   setFromTs(dateRange["from"]);
  //   setToTs(dateRange["to"]);
  //   loadDailyData(dateRange["from"], dateRange["to"]);
  // }, [previousDate]);

  const subtractDate = () => {
    let from_date = moment(selectdDatePicker).format("YYYY-MM-DD");
    let to_date = moment(selectdDatePicker).format("YYYY-MM-DD");
    if (previousDate == "Day") {
      from_date = moment(selectdDatePicker)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    } else if (previousDate == "Week") {
      from_date = moment(selectdDatePicker)
        .subtract(1, "weeks")
        .startOf("week")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker)
        .subtract(1, "weeks")
        .endOf("week")
        .format("YYYY-MM-DD");
    } else if (previousDate == "Month") {
      from_date = moment(selectdDatePicker)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker)
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
    } else if (previousDate == "Year") {
      from_date = moment(selectdDatePicker)
        .subtract(1, "years")
        .startOf("year")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker)
        .subtract(1, "years")
        .endOf("year")
        .format("YYYY-MM-DD");
    }
    localStorage.setItem("selectedDate", new Date(from_date));
    setSelectedDatePicker(new Date(from_date));

    props.fetchDataCount(from_date, to_date);
  };

  const nextDate = () => {
    let from_date = moment(selectdDatePicker).format("YYYY-MM-DD");
    let to_date = moment(selectdDatePicker).format("YYYY-MM-DD");
    if (previousDate == "Day") {
      from_date = moment(selectdDatePicker).add(1, "days").format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker).add(1, "days").format("YYYY-MM-DD");
    } else if (previousDate == "Week") {
      from_date = moment(selectdDatePicker)
        .add(1, "weeks")
        .startOf("week")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker)
        .add(1, "weeks")
        .endOf("week")
        .format("YYYY-MM-DD");
    } else if (previousDate == "Month") {
      from_date = moment(selectdDatePicker)
        .add(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker)
        .add(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
    } else if (previousDate == "Year") {
      from_date = moment(selectdDatePicker)
        .add(1, "years")
        .startOf("year")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker)
        .add(1, "years")
        .endOf("year")
        .format("YYYY-MM-DD");
    }
    setPreviousCalendar({
      from_date,
      to_date,
    });
    localStorage.setItem("selectedDate", new Date(from_date));
    setSelectedDatePicker(new Date(from_date));
    props.fetchDataCount(from_date, to_date);
  };

  let shouldDisplaySpaceChart =
    props.AuthDetail &&
    props.AuthDetail.sites &&
    props.AuthDetail.sites.find((user) => {
      if (user.name === "7000amk") {
        return false;
      }
      if (user.name === "ecs") {
        return false;
      }
      return true;
    });
  return (
    <div className="data_container">
      {props.loading && (
        <Modal
          show={props.loading}
          data-backdrop="static"
          data-keyboard="false"
          className="loader"
        >
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </Modal>
      )}
      {/* <div> */}
      <div className={`previous_date_container ${theme}`}>
        {isMobile ? (
          <div className="prev_dropdown_date">
            <Dropdown
              onSelect={(e) => {
                setPreviousCalendar({
                  from_date: moment(new Date()).format("YYYY-MM-DD"),
                  to_date: moment(new Date()).format("YYYY-MM-DD"),
                });
                localStorage.setItem("selectedDateMethod", e);
                setPreviousDate(e);
              }}
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {previousDate == "Day"
                  ? "Day  "
                  : previousDate == "Week"
                  ? "Week"
                  : previousDate == "Month"
                  ? "Month"
                  : previousDate == "Year"
                  ? "Year"
                  : null}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="Day"> Day</Dropdown.Item>
                <Dropdown.Item eventKey="Week">Week</Dropdown.Item>
                <Dropdown.Item eventKey="Month">Month</Dropdown.Item>
                <Dropdown.Item eventKey="Year"> Year</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : (
          <div className="previous_date_selector">
            <div
              onClick={() => {
                setPreviousCalendar({
                  from_date: moment(new Date()).format("YYYY-MM-DD"),
                  to_date: moment(new Date()).format("YYYY-MM-DD"),
                });
                localStorage.setItem("selectedDateMethod", "Day");
                setPreviousDate("Day");
              }}
              className={` ${previousDate == "Day" && "active"}`}
            >
              Day
            </div>
            <div
              onClick={() => {
                setPreviousCalendar({
                  from_date: moment(new Date()).format("YYYY-MM-DD"),
                  to_date: new Date(),
                });
                localStorage.setItem("selectedDateMethod", "Week");
                setPreviousDate("Week");
              }}
              className={` ${previousDate == "Week" && "active"}`}
            >
              Week
            </div>
            <div
              onClick={() => {
                setPreviousCalendar({
                  from_date: moment(new Date()).format("YYYY-MM-DD"),
                  to_date: new Date(),
                });
                localStorage.setItem("selectedDateMethod", "Month");
                setPreviousDate("Month");
              }}
              className={` ${previousDate == "Month" && "active"}`}
            >
              Month
            </div>
            <div
              onClick={() => {
                setPreviousCalendar({
                  from_date: moment(new Date()).format("YYYY-MM-DD"),
                  to_date: new Date(),
                });
                localStorage.setItem("selectedDateMethod", "Year");
                setPreviousDate("Year");
              }}
              className={` ${previousDate == "Year" && "active"}`}
            >
              Year
            </div>
          </div>
        )}
        <div
          // style={{ background: "red", width: 500 }}
          className={`calendar_selector ${
            previousDate == "Week" && "select-week"
          }`}
        >
          {previousDate === "Week" ? (
            <DatePicker
              onChange={onDateRangeChosen}
              customInput={<CustomCalendar />}
              selected={selectdDatePicker}
              dayClassName={(date) =>
                isSameWeek(new Date(date), new Date(selectdDatePicker))
                  ? "react-datepicker__day--selected"
                  : ""
              }
              // disabled={previousDate !== "previous_day" && true}
            />
          ) : (
            <DatePicker
              onChange={onDateRangeChosen}
              customInput={<CustomCalendar />}
              selected={selectdDatePicker}
              showMonthYearPicker={previousDate == "Month" && true}
              showYearPicker={previousDate == "Year" && true}

              // disabled={previousDate !== "previous_day" && true}
            />
          )}
        </div>
      </div>

      <div className={`${isMobile && "headerlist_container"} `}>
        {HeaderList()}
      </div>
      {previousDate !== "Day" ? (
        <PreChartIndex />
      ) : (
        titles.map((title) =>
          title === "Space temperature & RH" ? (
            (getCurrentSite() === "insead" || (props.data_count?.graph?.space_list?.length > 0 && props.data_count?.show_space_temp)) && (
              <SpaceTempatureChart
                title={title}
                shouldDisplaySpaceChart={shouldDisplaySpaceChart}
              />
            )
          ) : title === "Chilled water supply temperature" ? (
            <ChartTwo title={title} />
          ) : (
            <ChartOne title={title} />
          )
        )
      )}

      {/* </div> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  data_count: state.data.data_count,
  loading: state.data.loading,
  AuthDetail: state.loginUserDetail.userDetail,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDataCount: (from_date, to_date) =>
    dispatch(fetchDataCount(from_date, to_date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
