import api from "../../config/api";
import config from "../../config/config";
import dataTypes from "./types/dataTypes";

const { GET_DAILY_DATA, GET_DAILY_MIN_DATA } = config.api_endpoint;

const { GET_DATA_COUNT, GET_DATA_COUNT_SUCCESS, GET_DATA_COUNT_FAIL } =
  dataTypes;

const fetchingDataCount = () => {
  return {
    type: GET_DATA_COUNT,
  };
};

const fetchingDataCountSuccess = (data) => {
  return {
    type: GET_DATA_COUNT_SUCCESS,
    payload: data,
  };
};

const fetchingDataCountFail = () => {
  return {
    type: GET_DATA_COUNT_FAIL,
  };
};

const getCurrentSite = config.getCurrentSite;

export const fetchDataCount = (from_ts, to_ts) => {
  // console.log("from_ts, to_ts)", from_ts, to_ts);
  return (dispatch) => {
    dispatch(fetchingDataCount());
    api
      .get(GET_DAILY_MIN_DATA, {
        from_ts,
        to_ts,
        site_id: getCurrentSite(),
      })
      .then((res) => {
        if (res.status == "Success") {
          return dispatch(fetchingDataCountSuccess(res));
        }

        // if (res.status == "Success") {
        //   return dispatch(fetchingDataCountSuccess(res));
        // }

        return dispatch(fetchingDataCountFail());
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};
