import api from "../../config/api";
import config from "../../config/config";
import billingActionType from "./types/billingActionType";
import moment from "moment";

const { GET_BILLING_INVOICE, GET_TOKEN } = config.api_endpoint;

const { GET_INVOCIES, GET_INVOCIES_SUCCESS, GET_INVOCIES_FAIL } =
  billingActionType;

const getbillingInvoice = () => {
  return {
    type: GET_INVOCIES,
  };
};

const getBillingInvoiceFail = () => {
  return {
    type: GET_INVOCIES_FAIL,
  };
};

const getBillingInvoiceSuccess = (data) => {
  return {
    type: GET_INVOCIES_SUCCESS,
    payload: data,
  };
};

const getCurrentSite = config.getCurrentSite;

export const getBillingInvoice = (isMobile, from_date, to_date) => {
  // let from_ts = moment(new Date()).subtract(1, "years").format("YYYY-MM-DD");
  // let to_ts = moment(new Date()).subtract(12, "months").format("YYYY-MM-DD");
  // let to_ts = moment().subtract(1, "months").endOf("year").format("YYYY-MM-DD");
  let from_ts;
  let to_ts;
  if (isMobile) {
    from_ts = from_date
      ? from_date
      : moment().subtract(5, "months").startOf("month").format("YYYY-MM-DD");
    to_ts = to_date
      ? to_date
      : moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");
  } else {
    from_ts = moment()
      .subtract(12, "months")
      // .startOf("year")
      .startOf("month")
      .format("YYYY-MM-DD");
    to_ts = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");
  }

  let site_id = getCurrentSite();
  return (dispatch) => {
    dispatch(getbillingInvoice());
    return api
      .get(`/api/invoice/?from_ts=${from_ts}&to_ts=${to_ts}&site_id=${site_id}`)
      .then((res) => {
        console.log("res", res);
        if (res.status == "Success") {
          return dispatch(getBillingInvoiceSuccess(res.results));
        }
      })
      .catch((err) => {
        console.log("err", err);
        return dispatch(getBillingInvoiceFail());
      });
  };
};

export const downloadPdfInvoice = (url) => {
  console.log("url", url);
  return (dispatch) => {
    api.get(GET_TOKEN).then((res) => {
      let combineLink = url + res.results.token;
      console.log("combineLink", combineLink);
      console.log("url", url);
      console.log("res.results.token", res.results.token);
      window.open(combineLink);
      // const link = document.createElement("a");
      // link.href = combineLink;
      // link.setAttribute("download", "");
      // document.body.appendChild(link);
      // link.click();
      // link.parentNode.removeChild(link);
    });
  };
};
