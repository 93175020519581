import React from "react";
import { Container, Row, Col, Image, Nav, Modal } from "react-bootstrap";

// image & css
import "./comingSoon.css";
import Clock from "../assets/images/comingSoon/clock.svg";
import ClockDark from "../assets/images/comingSoon/clock-dark.svg";

function ComingSoon(props) {
  const theme = localStorage.getItem("theme");

  return (
    <div className={`coming-soon-blk${theme === "light" ? "" : "-dark"}`}>
      <div>
      {theme === "dark" ? (
        <Image className="img-fluid" src={ClockDark} />
      ):(
        <Image className="img-fluid" src={Clock} />
      )}
      <h1 className={`coming-message${theme === "light" ? "" : "-dark"}`}>Coming soon</h1>
      </div>
    </div>
  )
}

export default ComingSoon;
