import React from "react";
import { Row, Col, Image } from "react-bootstrap";

// image & css
import "./blankBlk.css";
import AddTile from "../assets/images/live/add-tile.png";
import AddTileDark from "../assets/images/live/add-tile-dark.png";

function blankBlk(props) {
  const theme = localStorage.getItem("theme");

  return (
    <Row className="blank-blk">
      <Image
        className="add-tile"
        src={theme === "light" ? AddTile : AddTileDark}
        onClick={props.handleShow}
      />
    </Row>
  );
}

export default blankBlk;
