import React from "react";
import { Table } from "react-bootstrap";

import DownloadIcon from "../../../assets/images/billing/download.svg";
import SearchIcon from "../../../assets/images/billing/Search.svg";
import StarActiveIcon from "../../../assets/images/resources/staractive.svg";
import StarUnactiveIcon from "../../../assets/images/resources/starunactive.svg";
import { ReactComponent as ArrowDownIcon } from "../../../assets/images/billing/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../../assets/images/billing/arrow_up.svg";

import { connect } from "react-redux";
import { downloadPdfInvoice } from "../../../store/actions/billingAction";
import { ReactComponent as EditIcon } from "../../../assets/images/billing/edit.svg";
import api from "../../../config/api";
import config from "../../../config/config";
import moment from "moment";

const data = [
  "Signed contracts",
  "Addendums / notices",
  "Annual Statement of Account",
  "Commencement forms",
];

const DesktopTable = (props) => {
  const { selected, tabs, sorting, setSorting, setCurrentPage } = props;
  const { GET_TOKEN } = config.api_endpoint;
  let theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  const downloadPdf = (url, name) => {
    // api.get(GET_TOKEN).then((res) => {
    let imgToken = localStorage.getItem("imgToken");
    let combineLink = url + imgToken;

    props.setPdfFile({
      url: combineLink,
      filename: name,
    });
    // });
  };

  const shouldEditResource =
    props.AuthDetail &&
    props.AuthDetail.permissions.length > 0 &&
    props.AuthDetail.permissions.find((p) => p === "change_resource");

  return (
    <div className={`download_table ${theme}`}>
      <div
        style={{
          cursor: "pointer",
          fontWeight: "bold",
          fontSize: 18,
          marginTop: 10,
        }}
      >
        {" "}
        {/* {selected == "contracts"
          ? "Contracts & formal notices"
          : "Technical documents"} */}
        {tabs.find((tab) => tab.value == selected).title}
      </div>
      <Table hover borderless={true} bordered={false} responsive="xl">
        <thead>
          <tr>
            <th colSpan={0.5}>
              Name
              <span
                style={{ marginLeft: 15, cursor: "pointer" }}
                onClick={() => {
                  setCurrentPage(1);
                  setSorting((prev) => !prev);
                }}
              >
                {sorting ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </span>
            </th>

            <th>Favourite</th>
            <th></th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {props.resources_list.map((_) => {
            return (
              <tr key={_.id}>
                <td className="align-middle">{_.title}</td>

                <td className="align-middle">
                  {_.is_favourite ? (
                    <img
                      src={StarActiveIcon}
                      onClick={() =>
                        props.favouriteResource(_.id, _.is_favourite)
                      }
                    />
                  ) : (
                    <img
                      src={StarUnactiveIcon}
                      onClick={() =>
                        props.favouriteResource(_.id, _.is_favourite)
                      }
                    />
                  )}
                </td>

                <td
                  colSpan={2}
                  className="d-flex align-items-center justify-content-evenly"
                >
                  <div>
                    {shouldEditResource && (
                      <EditIcon
                        fill={theme === "light" ? "#000" : "#fff"}
                        width={18}
                        onClick={() => {
                          props.getDetailValue(_);
                          props.handleShowUpdate();
                        }}
                      />
                    )}
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (_.resource_file == null) {
                        return alert("PDF file doesn't exist");
                      }
                      downloadPdf(_.resource_file, _.timestamp_fmt);
                      props.setPdfFileModal(true);
                      // props.setIsEditable(false);
                      // props.getDetailValue(_);
                      // props.handleShowUpdate();
                    }}
                  >
                    View
                  </div>
                  <button
                    className="btn btn-dark d-flex justify-content-around align-items-center"
                    onClick={() => {
                      if (_.resource_file == null) {
                        return alert("PDF file doesn't exist");
                      }
                      props.downloadPdfInvoice(_.resource_file);
                      // downloadPdf(_.resource_file, _.timestamp_fmt);
                      // props.setPdfFileModal(true);
                    }}
                  >
                    <div style={{ marginRight: 5 }}>
                      <img src={DownloadIcon} />
                    </div>
                    <div>Download</div>
                  </button>
                  {/* <div onClick={props.handleShowDelete}>Delete</div> */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
const mapStateToProps = (state) => ({
  resource: state.resource,
  resources_list: state.resource.resources_list,
  AuthDetail: state.loginUserDetail.userDetail,
});

const mapDispatchToProps = (dispatch) => ({
  downloadPdfInvoice: (url) => dispatch(downloadPdfInvoice(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DesktopTable);
