import React from "react";
import { Row, Col, Button } from 'react-bootstrap';

// image & css
import "./notificationBlk.css";

function notificationBlk(props) {

  const theme = localStorage.getItem("theme");
  const chart_title = props.chart_title;
  const heading = props.heading;
  const data = props.data;
  const readNotification = props.readNotification;
  const removeWidgetClicked = props.removeWidgetClicked;
  const id = props.id;

  var allNotiIDs = [];
  data.forEach((item, i) => {
    allNotiIDs.push(item.id);
  });
  allNotiIDs = allNotiIDs.toString();
  // console.log("allNotiIDs: ", allNotiIDs);

  return (
    <Row className={`rounded notis-blk${theme === "light" ? "" : "-dark"}`}>
      <Row className="ms-0 noti-main-blk">
        <Col lg="10" md="10" sm="10" className="col-10 noti-sub-blk">
          <div className="noti-blk">
            <div className="noti-cell-blk noti-padding-blk padding-blk"></div>
            <h3 className={`noti-cell-blk noti-blk-header${theme === "light" ? "" : " dark"}`}>{heading}</h3>
          </div>
        </Col>
        <Col lg="2" md="2" sm="2" className="col-2 noti-sub-blk">
          <i className="bi bi-gear-fill settings-icon" role="button" id={id} data-bs-toggle="dropdown" aria-expanded="false"></i>
          <ul className="dropdown-menu" aria-labelledby={id}>
            <li><a className="dropdown-item" href="#" data-choice="day" data-id={id} onClick={removeWidgetClicked}>Remove</a></li>
          </ul>
        </Col>
      </Row>
      { data.length > 0 && (
        <Row className="ms-0">
          <p className="noti-blk-title" data-id={allNotiIDs}
          onClick={readNotification}>
            <u data-id={allNotiIDs} onClick={readNotification}>{chart_title}</u>
          </p>
        </Row>
      )}
      <Row className="ms-0 noti-noti" style={{ overflowY: "auto" }}>
        { data.length > 0 ? (
          data.map((_, index) => {
            return (
              <Row key={index} className="noti-info">
                <Col md="1" sm="1" className="col-1">
                  <i className="bi bi-x-lg read-noti" data-id={_.id}
                  onClick={readNotification}></i>
                </Col>
                <Col md="11" sm="11" className="col-11">
                  <p>{_.created_datetime_fmt}: {_.msg}</p>
                </Col>
              </Row>
            )
          })
        ): (
          <div className="noti-info"><center>No notifications.</center></div>
        )}
      </Row>
    </Row>
  )

}

export default notificationBlk;
