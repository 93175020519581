import React, { useEffect, useState } from "react";
import { Row, Col, Image } from 'react-bootstrap';

import CheckIcon from "../../assets/images/notification/check.svg";
import CheckDarkIcon from "../../assets/images/notification/check-dark.svg";
import CheckAltIcon from "../../assets/images/notification/check-alt.svg";

function NotiList (props) {

  const notiData = props.notiData;
  const checkClicked = props.checkClicked;
  const theme = props.theme;

  return (
    <>
    { notiData.length > 0 ? (
      notiData.map((_, index) => {
        return (
          <Row className="ms-0 noti-row" key={_.id}>
            <Col md="10" sm="10" className="col-10">
              <p className="noti-msg">{_.msg}</p>
              <p className="noti-msg-date">{_.created_datetime_fmt}</p>
            </Col>
            <Col md="2" sm="2" className="col-2 pe-0">
              {_.is_new === true && (
                <Image data-ind={index} className="img-fluid read-noti" src={
                  theme === "light" && _.selected === false ? CheckIcon :
                  theme === "dark" && _.selected === false ? CheckDarkIcon :
                  CheckAltIcon
                } onClick={checkClicked} />
              )}
            </Col>
            <hr></hr>
          </Row>

        )
      })
    ): (
      <h6 className="text-center">No notifications.</h6>
    )}
    </>
  )
}

export default NotiList;
