import React from "react";
import { Row, Col } from "react-bootstrap";
import { CollectionView } from "wijmo/wijmo";
import * as wjCore from "wijmo/wijmo";
import {
  FlexChart,
  FlexChartSeries,
  FlexChartAxis,
  FlexChartLegend,
} from "wijmo/wijmo.react.chart";
import { FlexChartAnimation } from "wijmo/wijmo.react.chart.animation";

// apply Wijmo license key
import { setLicenseKey } from "wijmo/wijmo";

// image & css
import "./chartBlk.css";

function chartBlk(props) {
  /*
  const getData = () => {
    var countries = 'US,Germany,UK,Japan,Italy,Greece'.split(','),
        data = [];
    for (var i = 0; i < countries.length; i++) {
      data.push({
        country: countries[i],
        sales: Math.random() * 10000,
        expenses: Math.random() * 5000,
        downloads: Math.round(Math.random() * 20000),
      });
    }
    return new CollectionView(data);
  }

  const getChartData = () => {
    return [
        { month: 'Jan', mean: -5.2, high: -0.8, low: -9.7 },
        { month: 'Feb', mean: -3.4, high: 1.4, low: -8.2 },
        { month: 'Mar', mean: 1.7, high: 6.9, low: -3.5 },
        { month: 'Apr', mean: 8.8, high: 14.6, low: 2.9 },
        { month: 'May', mean: 14.6, high: 20.8, low: 8.4 },
        { month: 'Jun', mean: 19.6, high: 25.5, low: 13.6 },
        { month: 'Jul', mean: 22.1, high: 27.9, low: 16.3 },
        { month: 'Aug', mean: 21.2, high: 26.9, low: 15.5 },
        { month: 'Sep', mean: 16.6, high: 22.3, low: 10.9 },
        { month: 'Oct', mean: 9.8, high: 15.4, low: 4.2 },
        { month: 'Nov', mean: 4.3, high: 8.8, low: -0.3 },
        { month: 'Dec', mean: -1.9, high: 2.1, low: -6 }
    ];
  }

  const getAreaChartData = () => {
    var tmp = `Date,MaxTemp,MeanTemp,MinTemp
    2022-1-1,8,7,6
    2022-1-2,8,5,4
    2022-1-3,8,3,4
    2022-1-4,8,2,4
    2022-1-5,8,1,4
    2022-1-6,8,5,4
    2022-1-7,8,6,4
    2022-1-8,8,2,4
    2022-1-9,8,1,4
    2022-1-10,8,4,4
    2022-1-11,8,5,4
    2022-1-12,8,5,4
    2022-1-13,8,6,4
    2022-1-14,8,6,4
    2022-1-15,8,5,4
    2022-1-16,8,7,4
    2022-1-17,8,7,4
    2022-1-18,8,7,4
    2022-1-19,8,2,4
    2022-1-20,8,3,4
    2022-1-21,8,7,4
    2022-1-22,8,4,4
    2022-1-23,8,2,4
    2022-1-24,8,5,4
    2022-1-25,8,7,4
    2022-1-26,8,7,4
    2022-1-27,8,5,4
    2022-1-28,8,4,4
    2022-1-29,8,5,4
    2022-1-30,8,7,4
    2022-1-31,8,7,4`

    let strings = tmp.split(/\r\n|\n|\r/);
    let len = strings.length;
    //
    let items = [];
    let names = [];
    for (let i = 0; i < len; i++) {
        let s = strings[i];
        if (s && s.length) {
            let sdata = s.split(',');
            let slen = sdata.length;
            if (i == 0) {
                for (let j = 0; j < slen; j++) {
                    names.push(sdata[j]);
                }
            }
            else {
                let item = {};
                for (let j = 0; j < slen; j++) {
                    if (j == 0) {
                        item[names[j]] = wjCore.Globalize.parseDate(sdata[j], 'yyyy-MM-dd');
                    }
                    else {
                        let num = parseFloat(sdata[j]);
                        item[names[j]] = isNaN(num) ? sdata[j] : num;
                    }
                }
                items.push(item);
            }
        }
    }
    //
    return items;
  }

  const data = getData();
  const chart_data = getChartData();
  const area_chart_data = getAreaChartData();
  const palette = ['rgba(42,159,214,1)', 'rgba(119,179,0,1)', 'rgba(153,51,204,1)', 'rgba(255,136,0,1)', 'rgba(204,0,0,1)', 'rgba(0,204,163,1)', 'rgba(61,109,204,1)', 'rgba(82,82,82,1)', 'rgba(0,0,0,1)'];
  const tooltip = `<b>Date: </b>{Date:dd MMMM, yyyy} </br>temp: {MeanTemp}`;
  */
  /*<Row>
      <Row>
        <FlexChart itemsSource={data} bindingX="country">
          <FlexChartSeries name="Sales" binding="sales"/>
          <FlexChartSeries name="Expenses" binding="expenses"/>
          <FlexChartSeries name="Downloads" binding="downloads"/>
        </FlexChart>
      </Row>
      <hr></hr>
      <Row>
        <Col md="6">
          <FlexChart itemsSource={chart_data} bindingX="month" chartType="Line" header="Temperature of New York" palette={palette}>
                <FlexChartLegend position="Bottom"></FlexChartLegend>
                <FlexChartAxis wjProperty="axisY" title="Temperature(°C)"></FlexChartAxis>
                <FlexChartSeries binding="low" name="Average Low"></FlexChartSeries>
                <FlexChartSeries binding="high" name="Average High"></FlexChartSeries>
                <FlexChartSeries binding="mean" name="Daily Mean"></FlexChartSeries>

                <FlexChartAnimation animationMode="Point"></FlexChartAnimation>
          </FlexChart>
        </Col>
        <Col  md="6">
          <FlexChart itemsSource={area_chart_data} bindingX="Date" chartType="Area" palette={palette}>
            <FlexChartLegend position="None"></FlexChartLegend>
            <FlexChartAxis wjProperty="axisX" labelAngle={-45}></FlexChartAxis>
            <FlexChartAxis wjProperty="axisY" title="Temperature(°C)"></FlexChartAxis>
            <FlexChartSeries binding="MeanTemp" name="Mean Temperature" tooltipContent={tooltip}></FlexChartSeries>

            <FlexChartAnimation animationMode="Point"></FlexChartAnimation>
        </FlexChart>
        </Col>
      </Row>
  </Row>
  md-12
  md-10
  md-4
  md-6
  md-2
  md-2
  md-2
  */

  setLicenseKey(
    "GrapeCity,427351286422477#B0JoIIklkIs4nIzYXMyAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQvAVW7ZWbqNjeWZzRh9Ucl5UTuZVaCR7ZpB5UH9EVC3kaqJWZ0pnasJ7Q9I4bB3GR0F6aIt6NZ96MFN4aotEZrUXe4kHUlllerlGb9dDSPhEcFFmclJXd8syNEtENzMTOBNVYpFje6lDUlBlbkdmcNNGOrAHR9pXNSl6NpVkbCNWUxlkZ7o7QT3icHNGavFXdapWQDZ5KsN5N7dDcyRUW85ESFBlb4JUZq3GWlBldXBzU0hjW9wkb8cncopnSOBjNkJFdyNmSqB7YVlnNzpnb9BDSMllTSFDaNFjca54dtBXatlEdS5mVNV6dxIEN7RUNxYGSvU7KIdndPpENvlXW6hzbCh7RsZ7YLJzboNnU7ZERTRkVBNlN7RWSGhDVrdmZqZ4cq94aFpkbWZDMGBHdVZ5YwUTTIdlN0RnVvMDdFJzbQt6bolDM5NkdrsUO536LrNWSotmI0IyUiwiIDRTR4MjM9QjI0ICSiwSMyIjM9UTMwEjM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiETMwAzNwACOwcDMwIDMyIiOiQncDJCLi46bj9idlRWe4l6YlBXYydmLqwibj9SbvNmL9RXajVGchJ7ZuoCLt36YukHdpNWZwFmcn9iKsI7au26YukHdpNWZwFmcn9iKsAnau26YukHdpNWZwFmcn9iKiojIz5GRiwiI9RXaDVGchJ7RiojIh94QiwiI7cDNyIDN6gjMxUzMdI6N"
  );

  const theme = localStorage.getItem("theme");
  const chart_type = props.chart_type;
  const chart_title = props.chart_title;
  const heading = props.heading;
  const data = props.data;

  const palette = [
    "rgba(42,159,214,1)",
    "rgba(119,179,0,1)",
    "rgba(153,51,204,1)",
    "rgba(255,136,0,1)",
    "rgba(204,0,0,1)",
    "rgba(0,204,163,1)",
    "rgba(61,109,204,1)",
    "rgba(82,82,82,1)",
    "rgba(0,0,0,1)",
  ];
  const tooltip = `<b>Date: </b>{date:dd MMMM, yyyy} </br>temp: {mean}`;

  if (theme == "dark") {
    var styles = `
      .wj-tooltip {
        color: #FFFFFF !important;
        font-family: 'Graphik' !important;
      }
      .custom-chart text {
        fill: #FFFFFF !important;
        font-family: 'Graphik' !important;
      }
    `;
  } else {
    var styles = `
      .wj-tooltip {
        font-family: 'Graphik' !important;
      }
      .custom-chart text {
        font-family: 'Graphik' !important;
      }
    `;
  }
  var styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);

  return (
    <Row className={`rounded charts-blk${theme === "light" ? "" : "-dark"}`}>
      <Row className="ms-0 chart-main-blk">
      <Col md="8" sm="8" className="col-8 chart-sub-blk">
        <div className="chart-blk">
          <div className="chart-cell-blk chart-padding-blk padding-blk"></div>
          <h3 className={`chart-cell-blk chart-blk-header${theme === "light" ? "" : " dark"}`}>{heading}</h3>
        </div>
      </Col>
      <Col md="4" sm="4" className="col-4 chart-sub-blk">
        <i className="bi bi-gear-fill chart-settings-icon"></i>
      </Col>
      </Row>
      <Row className="ms-0 chart-chart">
        <div>
          <p className="chart-blk-title">{chart_title}</p>
          <a
            className={`chartDrop dropdown-toggle ${
              theme === "light" ? "" : "dark"
            }`}
            href="#"
            role="button"
            id="liveStatsDrop123"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          ></a>
          <ul className="dropdown-menu" aria-labelledby="liveStatsDrop123">
            <li>
              <a className="dropdown-item" href="#">
                Action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Another action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Something else here
              </a>
            </li>
          </ul>
        </div>
        {chart_type === "area_chart" ? (
          <FlexChart
            className="custom-chart"
            itemsSource={data}
            bindingX="date"
            chartType="Area"
            palette={palette}
          >
            <FlexChartLegend position="None"></FlexChartLegend>
            <FlexChartAxis
              wjProperty="axisX" labelAngle={-45}
            ></FlexChartAxis>
            <FlexChartAxis
              wjProperty="axisY"
              title="Temperature(°C)"
              StrokeColor="#FFFFFF"
            ></FlexChartAxis>
            <FlexChartSeries
              binding="mean"
              name="Mean Temperature"
              tooltipContent={tooltip}
            ></FlexChartSeries>

            <FlexChartAnimation animationMode="Point"></FlexChartAnimation>
          </FlexChart>
        ) : (
          <div></div>
        )}
      </Row>
    </Row>
  );
}

export default chartBlk;
