import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import * as wjCore from "wijmo/wijmo";

// views
import BlankBlk from "./blankBlk";

// image & css
import "./spaceBlk.css";
import Temperature from "../assets/images/live/temperature.png";
import TemperatureDark from "../assets/images/live/temperature-dark.png";
import Water from "../assets/images/live/water.png";
import WaterDark from "../assets/images/live/water-dark.png";
import SpaceIcon from "../assets/images/live/space.svg";
import SpaceDarkIcon from "../assets/images/live/space-dark.svg";

function spaceBlkAlt(props) {
  const theme = localStorage.getItem("theme");
  const heading = props.heading;
  const data = props.data;
  const removeWidgetClicked = props.removeWidgetClicked;

  return (
    <Row className={`rounded space-blk${theme === "light" ? "" : "-dark"}`}>
      <Row className="ms-0 ps-0 main-blk">
        <Col md="6" sm="6" className="col-6">
          <div className="table-blk">
            <div className="table-cell-blk padding-blk"></div>
            <h3
              className={`table-cell-blk blk-header${
                theme === "light" ? "" : " dark"
              }`}
            >
              {heading}
            </h3>
          </div>
        </Col>
        <Col md="6" sm="6" className="col-6"></Col>
      </Row>
      <Row className="ms-0 pt-4" style={{ marginLeft: "0px !important" }}>
        {data.length > 0 &&
          data.map((_, index) => {
            return (
              <Col
                lg="3"
                md="6"
                sm="12"
                key={index}
                data-id={_.id}
                className="space-stats-wrapper"
                draggable="true"
                onDragStart={props.dashboardDragStart}
                onDragOver={props.dashboardDragOver}
                onDrop={props.dashboardDrop}
                onDragEnd={props.dashboardDragEnd}
              >
                <div className="space-stats mb-2">
                  <div className="space-stats-top">
                    <span className="space-title">
                      <Image
                        className="img-fluid"
                        src={theme === "light" ? SpaceIcon : SpaceDarkIcon}
                      />
                      &nbsp;&nbsp;<h6>{_.name}</h6>
                    </span>
                    <i
                      className="bi bi-gear-fill settings-icon"
                      role="button"
                      id={_.id}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    ></i>
                    <ul className="dropdown-menu" aria-labelledby={_.id}>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          data-choice="day"
                          data-id={_.id}
                          onClick={removeWidgetClicked}
                        >
                          Remove
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="space-stats-bottom">
                    <div className="space-stats-child">
                      <Image
                        className="img-fluid"
                        src={theme === "light" ? Temperature : TemperatureDark}
                      />
                      <h5
                        className={`cdn-blk-text${
                          theme === "light" ? "" : " dark"
                        }`}
                        style={{ marginTop: "0.3em" }}
                      >
                        {_.temperature}
                        <span className="text-be-float">&#8451;</span>
                      </h5>
                    </div>
                    <div className="space-stats-child">
                      <Image
                        className="img-fluid"
                        src={theme === "light" ? Water : WaterDark}
                      />
                      <h5
                        className={`cdn-blk-text${
                          theme === "light" ? "" : " dark"
                        }`}
                        style={{ marginTop: "0.3em" }}
                      >
                        {_.humidity}
                        <span className="text-be-float">% RH</span>
                      </h5>
                    </div>
                  </div>
                </div>
                {_.flag && (
                  <div className="rounded space-bottom mb-4">
                    <p>
                      {" "}
                      <i className="bi bi-exclamation-circle"></i>&nbsp;
                      Temperature is out of range
                    </p>
                  </div>
                )}
              </Col>
            );
          })}
        <Col
          lg="3"
          md="6"
          sm="12"
          style={{
            paddingLeft: "1.5em",
            paddingRight: "1.5em",
            paddingBottom: "3em",
          }}
        >
          <BlankBlk handleShow={props.handleShow} />
        </Col>
      </Row>
    </Row>
  );
}

export default spaceBlkAlt;
