import dataTypes from "../actions/types/dataTypes";

const { GET_DATA_COUNT, GET_DATA_COUNT_SUCCESS, GET_DATA_COUNT_FAIL } =
  dataTypes;

const intialState = {
  data_count: [],
  loading: false,
  graph: {},
  fetchCount: 0,
};

const dataReducer = (state = intialState, action) => {
  switch (action.type) {
    case GET_DATA_COUNT:
      return {
        ...state,
        data_count: [],
        loading: true,
      };
    case GET_DATA_COUNT_SUCCESS:
      return {
        ...state,
        data_count: action.payload.results,
        // graph: action.payload.results.graph,
        loading: false,
        fetchCount: state.fetchCount + 1,
      };
    case GET_DATA_COUNT_FAIL:
      return {
        ...state,
        data_count: [],
        loading: false,
      };
    default:
      return state;
  }
};

export default dataReducer;
