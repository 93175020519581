import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Pagination,
  Spinner,
  Modal,
  Form,
  Button,
} from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';

import SearchIcon from "../../../assets/images/billing/Search.svg";
import CrossIcon from "../../../assets/images/resources/cross.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/images/billing/download.svg";

import DesktopTable from "./DesktopTable";
import { useMediaQuery } from "react-responsive";
import MobileTable from "./MobileTable";
import { connect } from "react-redux";
import {
  fetchResource,
  updateFavourite,
} from "../../../store/actions/resourceAction";
import { downloadPdfInvoice } from "../../../store/actions/billingAction";
import api from "../../../config/api";
import config from "../../../config/config";
import { useFormik, userFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import PdfViewer from "../../../components/PdfViewer";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "../download.css";

const Download = React.memo((props) => {

  const tabs = [
    {
      title: "Equipment & operations",
      value: "equipment_and_operations",
    },
    {
      title: "As-built drawings",
      value: "as-built_drawings",
    },
    {
      title: "Maintenance & Water reports",
      value: "maintenance_and_water_reports",
    },
    {
      title: "Cooling tower information",
      value: "cooling_tower_information",
    },
    {
      title: "IAQ reports",
      value: "iaq_reports",
    },
  ];

  const checkHasDefaultType = () => {
    var parts = window.location.pathname.split("/");
    for (var x in parts) {
        var arr = tabs.filter(function(el) { return el.value == parts[x] });
        if (arr.length > 0) {
          return arr[0]["value"];
        }
    }
    return "equipment_and_operations";
  }

  const [selected, setSelected] = useState(checkHasDefaultType());
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 800 });
  const isTablet = useMediaQuery({ maxWidth: 930 });
  const [showForm, setShowForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfFileModal, setPdfFileModal] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [selectId, setSelectId] = useState(null);
  const [showAlertBox, setShowAlertBox] = useState(false);
  const [isNewForm, setIsNewForm] = useState(true);
  const [pdfZoom, setPdfZoom] = useState(1);
  let theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";
  const [pdfFile, setPdfFile] = useState({
    url: "",
    fileame: "",
  });
  const { FAVOURITE_RESOURCE, RESOURCE, CHECK_RESOURCE } = config.api_endpoint;
  const [resoureDate, setResourceDate] = useState(new Date());
  const [pageSize, setPageSize] = useState(10);
  // default is true (desc),
  // false is (asc)
  const [sorting, setSorting] = useState(true);

  const getCurrentSite = config.getCurrentSite;

  const [showProgressBar, setShowProgressBar] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [createFileNames, setCreateFileNames] = useState("");
  const [createDuplicateFiles, setCreateDuplicateFiles] = useState("");
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [refreshAfter, setRefreshAfter] = useState(false);

  let items = [];
  for (let number = 1; number <= props.total_pages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => setCurrentPage(number)}
        className="resource_pagination_item"
      >
        {number}
      </Pagination.Item>
    );
  }

  let pageSizeCount = [];

  for (let i = 1; i <= 5; i++) {
    pageSizeCount.push(i * 10);
  }

  const favouriteResource = (resource_id, is_favourite) => {
    var fd = new FormData();

    api
      .post(
        FAVOURITE_RESOURCE,
        JSON.stringify({
          resource_id,
          site_id: getCurrentSite(),
        }),
        props.CSRFToken
      )
      .then((res) => {
        console.log("res  ", res);
        props.updateFavourite(resource_id, is_favourite);
      })
      .catch((err) => {
        console.log("res ", err);
      });
  };

  useEffect(() => {
    props.fetchResource(selected, search, currentPage, sorting, pageSize);
  }, [selected, currentPage, sorting, pageSize]);

  useEffect(() => {
    if (refreshAfter == true) {
      console.log("refreshAfter useEffect...");
      setResourceDate(new Date());
      setShowForm(false);
      handleCloseAletBox();
      props.fetchResource(selected, search, currentPage, sorting, pageSize);

      createFormformik.resetForm();
      setShowProgressModal(false);
      setShowProgressBar(false);
      setPercentage(0);
      setCreateFileNames("");
      setCreateDuplicateFiles("");

      handleCloseUpdate();
      updateFormformik.resetForm();

      setRefreshAfter(false);

      console.log("refreshAfter useEffect done...");
    }
  }, [refreshAfter])

  const searchHandle = (e) => {
    e.preventDefault();
    props.fetchResource(selected, search, currentPage, sorting, pageSize);
  };

  const handleClose = () => {
    setShowForm(false);
    setResourceDate(new Date());
  };

  const handleShow = () => setShowForm(true);

  const formValidationSchema = Yup.object({
    // title: Yup.string().required("Required Field."),
    resource_file: Yup.mixed()
      .required("Required Field.")
      .test("fileFormat", "Allow only PDF file.", (value) => {
        console.log("formValidationSchema: ", value);
        for (var i = 0; i < value.length; i++) {
          if (value[i].type != "application/pdf") {
            return false;
          }
        }
        return true;
      }),
  });

  const createFormformik = useFormik({
    initialValues: {
      title: "",
      resource_file: "",
      resource_type: selected,
    },
    onSubmit: (values) => {
      // console.log("createFormformik: ", values);
      // console.log("createFormformik: ", createFormformik, form);
      // console.log("onSubmit validate: ", form);

      // var ret = form.validateForm(values);
      // console.log("return values: ", ret);

      var duplicateFiles = [];
      var all_resources = values.resource_file;

      let promises = [];
      for (let i = 0; i < all_resources.length; i++) {

        let formData = new FormData();
        formData.append("filename", all_resources[i].name);
        formData.append("site_id", getCurrentSite());

        promises.push(api.post(CHECK_RESOURCE, formData, props.CSRFToken));
      }
      Promise.all(promises)
        .then(function handleData(data) {
          console.log("Data: ", data);

          for (let x = 0; x < data.length; x++) {
            if (data[x] && data[x].exists) {
                duplicateFiles.push(all_resources[x].name);
            }
          }

          setIsNewForm(true);

          if (duplicateFiles.length > 0) {
            console.log("=== Duplicate Files: ", duplicateFiles.join(", "));
            setCreateDuplicateFiles(duplicateFiles.join(", "));
            console.log("=== createDuplicateFiles: ", createDuplicateFiles);
            handleClose();
            handleShowAletBox();
            console.log("=== Duplicate Files ===");
          }
          else {
            console.log("=== Start Create New Resource ===");
            handleClose();
            createNewResource();
            console.log("=== End Create New Resource ===");
          }

        })
        .catch(function handleError(error) {
          console.log("Error" + error);
        });
    },
    validationSchema: formValidationSchema,
    validateOnChange: false,
    validateOnBlur: false
  });

  const modalForm = () => {
    return (
      <Modal
        show={showForm}
        onHide={() => {
          createFormformik.resetForm();
          handleClose();
        }}
        className={`modalForm ${theme}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create an resource</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Resource Type *</Form.Label>
            <Form.Select
              onChange={(e) => {
                createFormformik.setFieldValue("resource_type", e.target.value);
              }}
              defaultValue={selected}
            >
              {tabs.map((tab) => {
                return (
                  <option
                    value={tab.value}
                    selected={
                      createFormformik.values.resource_type === tab.value &&
                      "selected"
                    }
                  >
                    {tab.title}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Attachment upload (PDF files only) *</Form.Label>
            <div>
              {createFileNames && (
                <div className="modal_attach_input_file_name">
                  {createFileNames}
                </div>
              )}

              <div className="modal_attach_input_file">
                Upload PDF file(s)
                <input
                  type="file"
                  name="resource_file"
                  accept=".pdf"
                  multiple
                  onChange={(e) => {
                    console.log("Change resource file: ", e.target.files);
                    if (e.target.files[0]) {
                      createFormformik.setFieldValue(
                        "resource_file",
                        e.target.files
                      );
                      createFormformik.setFieldValue("has_file", true);
                      var fileNames = "";
                      for (var i = 0; i < e.target.files.length; i++) {
                        if (i == 0){
                          fileNames = e.target.files[i].name;
                        }
                        else {
                          fileNames += ", "  + e.target.files[i].name;
                        }
                      }
                      if (fileNames) {
                        setCreateFileNames(fileNames);
                      }

                    } else {
                      createFormformik.setFieldValue("has_file", false);
                    }
                  }}
                  onBlur={createFormformik.handleBlur}
                />
              </div>
            </div>
            {createFormformik.touched.resource_file &&
              createFormformik.errors.resource_file && (
                <Form.Text style={{ color: "red" }}>
                  {createFormformik.errors.resource_file}
                </Form.Text>
              )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              createFormformik.handleSubmit();
            }}
          >
            Save
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              createFormformik.resetForm();
              handleClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const handleCloseUpdate = () => setShowUpdateForm(false);
  const handleShowUpdate = () => setShowUpdateForm(true);

  const updateFormValidationSchema = Yup.object({
    // title: Yup.string().required("Required Field."),
    resource_file: Yup.mixed().when("has_file", {
      is: true,
      then: Yup.mixed().test("fileFormat", "Allow only PDF file.", (value) => {
        return value && value.type === "application/pdf";
      }),
    }),
  });

  const updateFormformik = useFormik({
    initialValues: {
      // title: "",
      resource_file: "",
      has_file: false,
      is_url_file: false,
      site_id: "",
      resource_type: selected,
    },
    onSubmit: (values) => {
      let formData = new FormData();
      formData.append("filename", values.resource_file.name);
      formData.append("site_id", getCurrentSite());
      api.post(CHECK_RESOURCE, formData, props.CSRFToken).then((res) => {
        handleCloseUpdate();
        if (res.exists) handleShowAletBox();
        else updateResource();
      });
    },
    validationSchema: updateFormValidationSchema,
    validateOnChange: false,
    validateOnBlur: false
  });

  const getDetailValue = (value) => {
    if (value) {
      console.log("value", value);
      setIsNewForm(false);
      // updateFormformik.setFieldValue("title", value.title);
      updateFormformik.setFieldValue("resource_type", value.resource_type);
      updateFormformik.setFieldValue("resource_file", value.resource_file);
      updateFormformik.setFieldValue("timestamp", value.timestamp);
      setResourceDate(new Date(value.timestamp));
      setSelectId(value.id);
      updateFormformik.setFieldValue(
        "is_url_file",
        value.resource_file && true
      );
    }
  };

  const modalUpdateForm = () => {
    return (
      <Modal
        show={showUpdateForm}
        onHide={() => {
          updateFormformik.resetForm();
          handleCloseUpdate();
          setIsNewForm(true);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update a resource</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Form.Group
            className="mb-3"
            controlId="formBasicEmail"
            onSubmit={updateFormformik.handleSubmit}
          >
            <Form.Label>Title*</Form.Label>
            <Form.Control
              type="text"
              name="title"
              onChange={updateFormformik.handleChange}
              value={updateFormformik.values.title}
              onBlur={updateFormformik.handleBlur}
              disabled
            />
            {updateFormformik.touched.title &&
              updateFormformik.errors.title && (
                <Form.Text style={{ color: "red" }}>
                  {updateFormformik.errors.title}
                </Form.Text>
              )}
          </Form.Group> */}
          {/* <Form.Group>
            <Form.Label>Date *</Form.Label>
            <DatePicker
              dateFormat="dd MMMM  yyyy"
              // showMonthYearPicker
              name="invoice_month"
              showFullMonthYearPicker
              className="create_modal_date_picker"
              selected={resoureDate}
              onChange={(date) => {
                setResourceDate(date);
              }}
              onChangeRaw={(e) => e.preventDefault()}
              autoComplete="off"
            />
          </Form.Group> */}
          <Form.Group className="mb-3">
            <Form.Label>Resource Type *</Form.Label>
            <Form.Select
              onChange={(e) => {
                updateFormformik.setFieldValue("resource_type", e.target.value);
              }}
              defaultValue={updateFormformik.values.resource_type}
            >
              {tabs.map((tab) => {
                return (
                  <option
                    value={tab.value}
                    selected={
                      updateFormformik.values.resource_type === tab.value &&
                      "selected"
                    }
                  >
                    {tab.title}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Attachment upload (PDF file) *</Form.Label>
            <div>
              {/* {updateFormformik.values.resource_file && (
                <div className="modal_attach_input_file_name">
                  {updateFormformik.values.resource_file.name}
                </div>
              )} */}

              {updateFormformik.values.has_file ? (
                updateFormformik.values.resource_file &&
                updateFormformik.values.resource_file.name && (
                  <div className="modal_attach_input_file_name">
                    {updateFormformik.values.resource_file.name}
                  </div>
                )
              ) : updateFormformik.values.is_url_file ? (
                <div className="modal_attach_input_file_name">
                  {updateFormformik.values.resource_file}
                </div>
              ) : null}

              <div className="modal_attach_input_file">
                Upload PDF file
                <input
                  type="file"
                  name="resource_file"
                  accept=".pdf"
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      updateFormformik.setFieldValue(
                        "resource_file",
                        e.target.files[0]
                      );
                      updateFormformik.setFieldValue("has_file", true);
                    } else {
                      updateFormformik.setFieldValue("has_file", false);
                    }
                  }}
                  onBlur={updateFormformik.handleBlur}
                />
              </div>
            </div>
            {/* <Form.Control
              type="file"
              accpet=".pdf"
              name="resource_file"
              onChange={(e) => {
                if (e.target.files[0]) {
                  updateFormformik.setFieldValue(
                    "resource_file",
                    e.target.files[0]
                  );
                  updateFormformik.setFieldValue("has_file", true);
                } else {
                  updateFormformik.setFieldValue("has_file", false);
                }
              }}

              value={updateFormformik.values.resource_file}
              onBlur={updateFormformik.handleBlur}
            /> */}
            {updateFormformik.touched.resource_file &&
              updateFormformik.errors.resource_file && (
                <Form.Text style={{ color: "red" }}>
                  {updateFormformik.errors.resource_file}
                </Form.Text>
              )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {<Button variant="primary" onClick={updateFormformik.handleSubmit}>
            Update
          </Button>}
          <Button variant="danger" onClick={handleDeleteFunction}>
            Delete
          </Button>

          <Button
            variant="secondary"
            onClick={() => {
              updateFormformik.resetForm();
              handleCloseUpdate();
              setIsNewForm(true);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const handleDeleteFunction = () => {
    let formData = new FormData();
    formData.append("id", selectId);
    api
      .remove(RESOURCE, formData, props.CSRFToken)
      .then((res) => {
        console.log("res", res);
        setShowUpdateForm(false);
        props.fetchResource(selected, search, currentPage, sorting, pageSize);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const createNewResource = () => {
    console.log("called");
    handleCloseAletBox();
    let promises = [];

    for (let i = 0; i < createFormformik.values.resource_file.length; i++) {
      let formData = new FormData();
      formData.append("resource_type", selected);
      formData.append("resource_file", createFormformik.values.resource_file[i]);
      formData.append("site_id",getCurrentSite());

      promises.push(api.post(RESOURCE, formData, props.CSRFToken));
    }

    const all_promises = Promise.all(promises);
    let progress = 0;
    promises.forEach(p => p.then(() => {
      progress++;
      var percentage = progress / promises.length * 100;
      console.log(percentage + "%");

      setShowProgressModal(true);
      setShowProgressBar(true);

      setTimeout(function() {
        console.log("SET: ", percentage);
        setPercentage(percentage);

        if (percentage == 100) {
          setTimeout(function(){
            console.log("SET TIMEOUT: ", refreshAfter);
            setRefreshAfter(true);
          }, (progress * 1000) + 1000);
        }

      }, progress * 1000);

    }));
  };

  const updateResource = () => {

    handleCloseAletBox();

    let formData = new FormData();
    formData.append("resource_type", updateFormformik.values.resource_type);
    formData.append("resource_file", updateFormformik.values.resource_file);
    formData.append("site_id",getCurrentSite());

    let promises = [];
    promises.push(api.post(RESOURCE, formData, props.CSRFToken));

    const all_promises = Promise.all(promises);
    let progress = 0;

    promises.forEach(p => p.then(() => {
      progress++;
      var percentage = progress / promises.length * 100;
      console.log(percentage + "%");

      setShowProgressModal(true);
      setShowProgressBar(true);

      setTimeout(function() {
        console.log("SET: ", percentage);
        setPercentage(percentage);

        if (percentage == 100) {
          setTimeout(function(){
            console.log("SET TIMEOUT: ", refreshAfter);
            setRefreshAfter(true);
          }, (progress * 1000) + 2000);
        }

      }, progress * 1000);

    }));
  };


  const progressBarModal = () => {
    return (
      <Modal
        show={showProgressModal}
        onHide={() => {
          setShowProgressModal(false);
        }}
        backdrop="static"
        keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>File Upload Progress</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showProgressBar && (
              <ProgressBar animated now={percentage} label={`${parseInt(percentage)}%`} style={{ width: "100%" }} />
            )}
            <br></br>
          </Modal.Body>
      </Modal>
    )
  }


  const handleCloseAletBox = () => setShowAlertBox(false);
  const handleShowAletBox = () => setShowAlertBox(true);

  const alertBox = () => {
    return (
      <Modal
        show={showAlertBox}
        onHide={() => {
          if (isNewForm) handleShow();
          else handleShowUpdate();

          handleCloseAletBox();
        }}
        className={`modalForm ${theme}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isNewForm ? (
            createDuplicateFiles.indexOf(",") > 0 ? (
                "The files " + createDuplicateFiles + " already exist. Do you want to replace the existing files?"
            ) : (
              "The file " + createDuplicateFiles + " already exists. Do you want to replace the existing file?"
            )
          ): (
            "The file " + updateFormformik.values.resource_file.name + " already exists. Do you want to replace the existing file?"
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            type="button"
            onClick={isNewForm ? createNewResource : updateResource}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              if (isNewForm) handleShow();
              else handleShowUpdate();

              handleCloseAletBox();
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const prevHandle = () => {
    if (pageNumber <= 1) {
      setPageNumber(1);
    } else {
      setPageNumber((prev) => prev - 1);
    }
  };

  const nextHandle = () => {
    if (pageNumber >= numPages) {
      setPageNumber(numPages);
    } else {
      setPageNumber((prev) => prev + 1);
    }
  };

  const downloadFile = (url, filename) => {
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: "application/pdf",
      });

      const isIE = false || !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || window.webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", filename);
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  };

  const modalView = () => {
    return (
      <div className="pdf-container-view">
        <div
          className="modal_view_overlay"
          // onClick={(e) => {
          //   e.stopPropagation();
          //   setPdfFileModal(false);
          // }}
        >
          <div
            className="modal_view_overlay"
            style={{ zIndex: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              setPdfFileModal(false);
            }}
          ></div>
          <div className="modal_header">
            <div className="move_fun">
              <div onClick={prevHandle} className="pdf-button">
                {"<"}
              </div>

              <div className="pdf-currentPageNumber">
                {pageNumber} / {numPages}
              </div>

              <div onClick={nextHandle} className="pdf-button">
                {" "}
                {">"}
              </div>
            </div>
            <div className="right-side-pdf-header">
              <div
                onClick={() => {
                  setPdfZoom((prev) => prev + 1);
                }}
                style={{
                  marginRight: 25,
                  fontSize: 30,
                }}
              >
                +
              </div>
              <div
                onClick={() => {
                  setPdfZoom((prev) => (prev === 1 ? 1 : prev - 1));
                }}
                style={{
                  fontSize: 30,
                }}
              >
                -
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => downloadFile(pdfFile.url, pdfFile.filename)}
                  style={{
                    marginRight: 25,
                    marginLeft: 25,
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                >
                  Download
                  {/* <DownloadIcon
                    style={{
                      width: 30,
                      height: 21,
                      marginTop: -5,
                    }}
                  /> */}
                </div>
                <div onClick={() => setPdfFileModal(false)}>X</div>
              </div>
            </div>
          </div>

          <PdfViewer
            setPdfFileModal={setPdfFileModal}
            pdfFile={pdfFile}
            numPages={numPages}
            setNumPages={setNumPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pdfZoom={pdfZoom}
          />
        </div>
      </div>
    );
  };

  const handleCloseDelete = () => setShowDeleteForm(false);
  const handleShowDelete = () => setShowDeleteForm(true);

  const modalDeleteForm = () => {
    return (
      <Modal
        show={showDeleteForm}
        onHide={() => {
          handleCloseDelete();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete a document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Close
          </Button>
          <Button variant="danger">Delete</Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const shouldCreateResource =
    props.AuthDetail &&
    props.AuthDetail.permissions.length > 0 &&
    props.AuthDetail.permissions.find((p) => p === "add_resource");

  const clearSearchClicked = () => {
    setSearch("");
    props.fetchResource(selected, "", 1, sorting, pageSize);
    setShowClearSearchIcon(false);
  };

  const [showClearSearchIcon, setShowClearSearchIcon] = useState(false);

  return (
    <div className="mobile_down">
      {modalForm()}
      {modalUpdateForm()}
      {modalDeleteForm()}
      {progressBarModal()}
      {showAlertBox && alertBox()}
      <div className={`downloads  ${theme}`}>
        <div className="resource_table_container_header">
          <div>
            <div className="main_title">Downloads</div>
          </div>
          <div className="create_resource_button">
            {shouldCreateResource && (
              <button
                className="btn btn-dark d-flex justify-content-around align-items-center"
                onClick={handleShow}
              >
                <div>Upload documents</div>
              </button>
            )}
          </div>
        </div>

        {isMobile ? (
          <>
            <div className="download_dropdwon">
              <Dropdown
                onSelect={(e) => {
                  setSearch("");
                  setSelected(e);
                }}
              >
                <Dropdown.Toggle variant="success">
                  {tabs.map((tab) => tab.value == selected && tab.title)}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {tabs.map((tab) => {
                    return (
                      <Dropdown.Item eventKey={tab.value}>
                        {tab.title}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        ) : (
          <div className="d-flex header">
            {tabs.map((tab) => {
              return (
                <div
                  className={`border border-1 p-1 rounded-1  p-2   ${
                    selected == tab.value && "active_title"
                  }`}
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    fontSize: 13,
                  }}
                  onClick={() => {
                    setSearch("");
                    setSelected(tab.value);
                  }}
                >
                  {" "}
                  {tab.title}
                </div>
              );
            })}
            {/* <div
              className={`border border-1 p-1 rounded-1  p-2   ${
                selected == "contract" && "active_title"
              }`}
              style={{
                cursor: "pointer",
                fontWeight: "bold",
                fontSize: 13,
              }}
              onClick={() => setSelected("contract")}
            >
              {" "}
              Contracts & formal notices
            </div>
            <div
              className={`border border-1 p-1 rounded-1  p-2   ${
                selected == "technical" && "active_title"
              }`}
              style={{ cursor: "pointer", fontWeight: "bold", fontSize: 13 }}
              onClick={() => setSelected("technical")}
            >
              {" "}
              Technical documents
            </div> */}

            {/* Search */}
            <div
              className="w-50 border border-1 rounded-1 search_"
              style={{ marginLeft: 20 }}
            >
              <form
                className="d-flex  justify-content-between align-items-center h-100"
                onSubmit={searchHandle}
              >
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control border-0 shadow-none search_input"
                  value={search}
                  onChange={(e) => {
                    if (e.target.value == "") {
                      props.fetchResource(selected, "", 1, sorting, pageSize);
                      setShowClearSearchIcon(false);
                    }
                    else {
                      setShowClearSearchIcon(true);
                    }
                    setSearch(e.target.value);
                  }}
                />
                {showClearSearchIcon && (
                  <button
                    type="button"
                    style={{
                      background: "none",
                      outline: "none",
                      border: "none",
                    }}
                    onClick={clearSearchClicked}
                  >
                    <img src={CrossIcon} style={{ width: "20px" }}/>
                  </button>
                )}
                <button
                  type="submit"
                  style={{
                    background: "none",
                    outline: "none",
                    border: "none",
                  }}
                >
                  <img src={SearchIcon} />
                </button>
              </form>
            </div>
          </div>
        )}
        {isMobile && (
          <div
            className="w-50 border border-1 rounded-1 search_"
            style={{ marginLeft: 20 }}
          >
            <form
              className="d-flex  justify-content-between align-items-center h-100"
              onSubmit={searchHandle}
            >
              <input
                type="text"
                placeholder="Search"
                className="form-control border-0 shadow-none search_input"
                onChange={(e) => {
                  if (e.target.value == "") {
                    props.fetchResource(selected, "", 1, sorting, pageSize);
                  }
                  setSearch(e.target.value);
                }}
              />
              <button
                style={{
                  background: "none",
                  outline: "none",
                  border: "none",
                }}
              >
                <img src={SearchIcon} />
              </button>
            </form>
          </div>
        )}
        {pdfFileModal && modalView()}
        {/* table */}
        {props.loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 0px",
            }}
            className="resource_table_spinnser_container"
          >
            <Spinner animation="border" size="md" />
          </div>
        ) : isTablet ? (
          <MobileTable
            favouriteResource={favouriteResource}
            handleShowUpdate={handleShowUpdate}
            getDetailValue={getDetailValue}
            setPdfFileModal={setPdfFileModal}
            setPdfFile={setPdfFile}
            handleShowDelete={handleShowDelete}
            sorting={sorting}
            setSorting={setSorting}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          <DesktopTable
            selected={selected}
            tabs={tabs}
            favouriteResource={favouriteResource}
            handleShowUpdate={handleShowUpdate}
            getDetailValue={getDetailValue}
            setPdfFileModal={setPdfFileModal}
            setPdfFile={setPdfFile}
            handleShowDelete={handleShowDelete}
            sorting={sorting}
            setSorting={setSorting}
            setCurrentPage={setCurrentPage}
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: isMobile && 30,
          }}
        >
          <Pagination>{items}</Pagination>
        </div>
        <div className="d-flex justify-content-end">
          <Dropdown
            onSelect={(e) => {
              setCurrentPage(1);
              setPageSize(e);
            }}
          >
            <Dropdown.Toggle variant="secondary">
              {pageSizeCount.map((num) => num == pageSize && pageSize)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {pageSizeCount.map((num) => {
                return <Dropdown.Item eventKey={num}>{num}</Dropdown.Item>;
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
});

const mapStateToProps = (state) => ({
  resource: state.resource,
  total_pages: state.resource.total_pages,
  loading: state.resource.loading,
  CSRFToken: state.loginUserDetail.csrf,
  AuthDetail: state.loginUserDetail.userDetail,
});

const mapDispatchToProps = (dispatch) => ({
  fetchResource: (resource_type, search, current_page, sorting, pageSize) =>
    dispatch(
      fetchResource(resource_type, search, current_page, sorting, pageSize)
    ),
  updateFavourite: (resource_id, is_favourite) =>
    dispatch(updateFavourite(resource_id, is_favourite)),
  downloadPdfInvoice: (url) => dispatch(downloadPdfInvoice(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Download);
