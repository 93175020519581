// Dashboard
export const DASHBOARD_ROUTE = "/dashboard";
// Live
export const LIVE_ROUTE = "/live";
// Notification
export const NOTIFICATION_ROUTE = "/notifications";
// Login
export const LOGIN_ROUTE = "/";
export const DATA_ROUTE = "/data";
// Billing
export const BILLING_ROUTE = "/billing";
export const BILLING_PDF_ROUTE = "/billing_pdf";
// Resources
export const RESOURCES_ROUTE = "/resources";
// Contact
export const CONTACT_ROUTE = "/contact";
// Profile
export const PROFILE_ROUTE = "/profile";
export const USER_MANAGEMENT_ROUTE = "/user_management";
// Forgot Password
export const FORGOT_PASSWORD_ROUTE = "/set_password";
