import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import loginUserDetail from "./loginUserReducer";
import billingReducer from "./billingReducer";
import dataReducer from "./dataReducer";
import dashboardReducer from "./dashboardReducer";
import resourceReducer from "./resourceReducer";
import userManagementReducer from "./userManagementReducer";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    loginUserDetail: loginUserDetail,
    billing: billingReducer,
    data: dataReducer,
    dashboard: dashboardReducer,
    resource: resourceReducer,
    usersList: userManagementReducer,
  });

export default createRootReducer;
