import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js";
import { Row, Col } from "react-bootstrap";

import { connect } from "react-redux";
import faker from "@faker-js/faker";

import "./billingBlk.css";
import moment from "moment";

function billingBlk(props) {
  const theme = localStorage.getItem("theme");
  const chart_title = props.chart_title;
  const heading = props.heading;
  const main_data = props.data;
  const removeWidgetClicked = props.removeWidgetClicked;
  const id = props.id;

  var chart_data = [];
  var labels = [];
  main_data.forEach(function (item, index) {
    chart_data.push(item.amount);
    labels.push(
      item.timestamp_fmt.substring(0, 3) +
        " " +
        item.timestamp_fmt.substring(
          item.timestamp_fmt.length - 2,
          item.timestamp_fmt.length
        )
    );
  });

  const showNumberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const data = (data) => {
    return {
      labels,
      labelBackgrounds: [
        "rgba(120,220,220,0.2)",
        "rgba(220,120,220,0.2)",
        "rgba(220,220,120,0.2)",
        "rgba(120,120,220,0.2)",
        "rgba(120,220,120,0.2)",
        "rgba(220,120,120,0.2)",
        "rgba(120,120,120,0.2)",
      ],
      datasets: [
        {
          backgroundColor: "#11C126",
          data: chart_data,
          borderWidth: 0,
          borderRadius: 5,
          borderSkipped: false,
          barPercentage: 0.2,
        },
      ],
    };
  };

  const options = {
    elements: {
      point: {
        radius: 0,
      },
    },
    hover: {
      intersect: false,
    },
    tooltips: {
      enabled: false,
    },
    onClick: function (e) {
      console.log("e", e);
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      legend: {
        display: false,
      },
      annotation: {
        annotations: [
          {
            type: "box",
            yScaleID: "y-axis-0",
            xMin: -0.1,
            xMax: 0.1,
            borderWidth: 0,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: 50,
          },
          {
            type: "box",
            yScaleID: "y-axis-0",
            xMin: 0.9,
            xMax: 1.1,
            borderWidth: 0,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: 50,
          },
          {
            type: "box",
            yScaleID: "y-axis-0",
            xMin: 0.9,
            xMax: 1.1,
            borderWidth: 0,
            backgroundColor: "rgba(0, 0, 0, 0.01)",
            borderRadius: 50,
          },
          {
            type: "box",
            yScaleID: "y-axis-0",
            xMin: 1.9,
            xMax: 2.1,
            borderWidth: 0,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: 50,
          },
          {
            type: "box",
            yScaleID: "y-axis-0",
            xMin: 2.9,
            xMax: 3.1,
            borderWidth: 0,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: 50,
          },
          {
            type: "box",
            yScaleID: "y-axis-0",
            xMin: 3.9,
            xMax: 4.1,
            borderWidth: 0,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: 50,
          },
          {
            type: "box",
            yScaleID: "y-axis-0",
            xMin: 4.9,
            xMax: 5.1,
            borderWidth: 0,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: 50,
          },
          {
            type: "box",
            yScaleID: "y-axis-0",
            xMin: 5.9,
            xMax: 6.1,
            borderWidth: 0,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: 50,
          },
          {
            type: "box",
            yScaleID: "y-axis-0",
            xMin: 6.9,
            xMax: 7.1,
            borderWidth: 0,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: 50,
          },
          {
            type: "box",
            yScaleID: "y-axis-0",
            xMin: 7.9,
            xMax: 8.1,
            borderWidth: 0,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: 50,
          },
          {
            type: "box",
            yScaleID: "y-axis-0",
            xMin: 8.9,
            xMax: 9.1,
            borderWidth: 0,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: 50,
          },
          {
            type: "box",
            yScaleID: "y-axis-0",
            xMin: 9.9,
            xMax: 10.1,
            borderWidth: 0,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: 50,
          },
          {
            type: "box",
            yScaleID: "y-axis-0",
            xMin: 10.9,
            xMax: 11.1,
            borderWidth: 0,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: 50,
          },
        ],
      },
    },

    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grace: "50",
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: 10
          },
          callback: function (value) {
            return showNumberWithCommas(value);
          },
        },
      },
    },
  };

  /*
  <div className="header_chart_container">
    <h1
      className="title"
      style={{
        width: "200",
      }}
    >
      Invoicing history
    </h1>
    <br />
    <div style={{ height: 180 }}>
      <Bar
        options={options}
        data={data(props.billing.invoices)}
        id="bill_chart"
      />

      <div className="custom_label">
        {label.map((_, index) => {
          return <div key={index}>{_}</div>;
        })}
      </div>
    </div>
  </div>
  */

  return (
    <Row className={`rounded dbillings-blk${theme === "light" ? "" : "-dark"}`}>
      <Row className="ms-0 dbilling-main-blk">
        <Col lg="10" md="10" sm="10" className="col-10 dbilling-sub-blk">
          <div className="dbilling-blk">
            <div className="dbilling-cell-blk dbilling-padding-blk padding-blk"></div>
            <h3
              className={`dbilling-cell-blk dbilling-blk-header${
                theme === "light" ? "" : " dark"
              }`}
            >
              {heading}
            </h3>
          </div>
        </Col>
        <Col lg="2" md="2" sm="2" className="col-2 dbilling-sub-blk">
          <i
            className="bi bi-gear-fill settings-icon"
            role="button"
            id={id}
            data-bs-toggle="dropdown"
            aria-expanded="false"
          ></i>
          <ul className="dropdown-menu" aria-labelledby={id}>
            <li>
              <a
                className="dropdown-item"
                href="#"
                data-choice="day"
                data-id={id}
                onClick={removeWidgetClicked}
              >
                Remove
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="ms-0" className="d-none">
        <p className="dbilling-blk-title">{chart_title}</p>
      </Row>
      <Row className="ms-0 dbilling-dbilling">
        <Bar options={options} data={data(props.data)} id="dbillChart" />
        <div className="custom_label">
          {labels.map((_, index) => {
            return <div key={index}>{_}</div>;
          })}
        </div>
      </Row>
    </Row>
  );
}

export default billingBlk;
