import React from "react";
import { Row, Col, Button, Image } from 'react-bootstrap';

import config from "../config/config";

// image & css
import "./resourceBlk.css";
import DownloadLogo from "../assets/images/resources/download.svg";

function resourceBlk(props) {

  const theme = localStorage.getItem("theme");
  const chart_title = props.chart_title;
  const heading = props.heading;
  const data = props.data;
  const removeWidgetClicked = props.removeWidgetClicked;
  const id = props.id;
  const chosen_text = props.chosen_text;
  const chosen_type = props.chosen_type;
  const resoChoiceClicked = props.resoChoiceClicked;
  const resource_types = props.resource_types;

  const getCurrentSite = config.getCurrentSite;

  return (
    <Row className={`rounded resos-blk${theme === "light" ? "" : "-dark"}`}>
      <Row className="ms-0 reso-main-blk">
        <Col lg="10" md="10" sm="10" className="col-10 reso-sub-blk">
          <div className="reso-blk">
            <div className="reso-cell-blk reso-padding-blk padding-blk"></div>
            <h3 className={`reso-cell-blk reso-blk-header${theme === "light" ? "" : " dark"}`}>{heading}</h3>
          </div>
        </Col>
        <Col lg="2" md="2" sm="2" className="col-2 reso-sub-blk">
          <i className="bi bi-gear-fill settings-icon" role="button" id={id} data-bs-toggle="dropdown" aria-expanded="false"></i>
          <ul className="dropdown-menu" aria-labelledby={id}>
            <li><a className="dropdown-item" href="#" data-choice="day" data-id={id} onClick={removeWidgetClicked}>Remove</a></li>
          </ul>
        </Col>
      </Row>
      <Row>
        <div style={{ paddingTop: "1em" }}>
          <p className="reso-cdn-blk-title-right" data-chosen-type={chosen_type} id={`reso_${id}`}>{chosen_text}</p>
          <a className={`dropdown-toggle ${theme === "light" ? "" : "dark"}`} href="#" role="button" id="resoDrop" data-bs-toggle="dropdown" aria-expanded="false"></a>
          <ul className="dropdown-menu" aria-labelledby="dataDrop">
            {
              resource_types && (
                Object.keys(resource_types).map((key, index) => {
                  return (
                    <li key={index}>
                      <a className="dropdown-item" href="#" data-choice={key} onClick={resoChoiceClicked}>{resource_types[key]}</a>
                    </li>
                  )
                })
              )
            }
          </ul>
        </div>
      </Row>
      <Row className="ms-0 reso-noti" style={{ overflowY: "auto" }}>
        { data.length > 0 ? (
          data.map((_, index) => {
            return (
              <Row key={index} className="reso-info">
                <Col md="10" sm="10" className="col-10">
                  <p>{_.title}</p>
                </Col>
                <Col md="2" sm="2" className="col-2">
                  <a href={_.resource_file + localStorage.getItem("imgToken")} target="_blank">
                    <Image
                      src={DownloadLogo}
                    />
                  </a>
                </Col>
              </Row>
            )
          })
        ): (
          <div className="reso-info"><center>No resources.</center></div>
        )}
      </Row>
      <Row className="ms-0">
        <a href={"/resources/" + chosen_type  + "/"+ getCurrentSite()}
          className="reso-blk-bottom">View All</a>
      </Row>
    </Row>
  )

}

export default resourceBlk;
