import { useState, useRef } from "react";
// bootstrap
import { Row, Col, Image, Button } from "react-bootstrap";
// libs
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
// icons
import CountryLogo from "../../assets/images/profile/country.svg";
import CountryDarkLogo from "../../assets/images/profile/country-alt.svg";
import CrateLogo from "../../assets/images/profile/crate.svg";
import CrateDarkLogo from "../../assets/images/profile/crate-alt.svg";
import CapacityLogo from "../../assets/images/profile/capacity.svg";
import CapacityDarkLogo from "../../assets/images/profile/capacity-alt.svg";
import DateLogo from "../../assets/images/profile/date.svg";
import DateDarkLogo from "../../assets/images/profile/date-alt.svg";
import AddressLogo from "../../assets/images/profile/address.svg";
import AddressDarkLogo from "../../assets/images/profile/address-alt.svg";
import AreaLogo from "../../assets/images/profile/area.svg";
import AreaDarkLogo from "../../assets/images/profile/area-alt.svg";
import GreenLogo from "../../assets/images/profile/green.svg";
import GreenDarkLogo from "../../assets/images/profile/green-alt.svg";
import EditLogo from "../../assets/images/profile/edit-profile-img.svg";
import ProfileHolder from "../../assets/images/profile/profile-holder.svg";
// css
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

function CompanyDetails({ company, theme, token, hideEdit, saveCompany }) {
  const companyImgRef = useRef(null);
  const [editCompany, setEditCompany] = useState(company);

  const [title, setTitle] = useState(company.title);
  const [address, setAddress] = useState(company.address);
  const [country, setCountry] = useState(company.country);
  const [area, setArea] = useState(company.area);
  const [capacity, setCapacity] = useState(company.capacity);
  const [rating, setRating] = useState(company.rating);
  const [greenMark, setGreenMark] = useState(company.green_mark_expiry);
  const [greenMarkFmt, setGreenMarkFmt] = useState(
    company.green_mark_expiry_fmt
  );
  const [nextPrice, setNextPrice] = useState(company.next_price_adjustment);
  const [buildingType, setBuildingType] = useState(company.building_type);
  const [wdOpHrStart, setWdOpHrStart] = useState(company.wd_op_hrs_start);
  const [wdOpHrEnd, setWdOpHrEnd] = useState(company.wd_op_hrs_end);
  const [satOpHrStart, setSatOpHrStart] = useState(company.sat_op_hrs_start);
  const [satOpHrEnd, setSatOpHrEnd] = useState(company.sat_op_hrs_end);
  const [sunOpHrStart, setSunOpHrStart] = useState(company.sun_op_hrs_start);
  const [sunOpHrEnd, setSunOpHrEnd] = useState(company.sun_op_hrs_end);
  const [phOpHrStart, setPhOpHrStart] = useState(company.ph_op_hrs_start);
  const [phOpHrEnd, setPhOpHrEnd] = useState(company.ph_op_hrs_end);
  const [image, setImage] = useState(company.img);

  const [disableEdit, setDisableEdit] = useState(true);
  const [titleError, setTitleError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [areaError, setAreaError] = useState(false);
  const [capacityError, setCapacityError] = useState(false);
  const [ratingError, setRatingError] = useState(false);
  const [greenMarkError, setGreenMarkError] = useState(false);
  const [nextPriceError, setNextPriceError] = useState(false);
  const [buildingTypeError, setBuildingTypeError] = useState(false);
  const [companyError, setCompanyError] = useState("");
  const [wdOpHrStartError, setWdOpHrStartError] = useState(false);
  const [wdOpHrEndError, setWdOpHrEndError] = useState(false);
  const [satOpHrStartError, setSatOpHrStartError] = useState(false);
  const [satOpHrEndError, setSatOpHrEndError] = useState(false);
  const [sunOpHrStartError, setSunOpHrStartError] = useState(false);
  const [sunOpHrEndError, setSunOpHrEndError] = useState(false);
  const [phOpHrStartError, setPhOpHrStartError] = useState(false);
  const [phOpHrEndError, setPhOpHrEndError] = useState(false);

  // method
  const resetValues = () => {
    setTitle(company.title);
    setAddress(company.address);
    setCountry(company.country);
    setArea(company.area);
    setCapacity(company.capacity);
    setRating(company.rating);
    setGreenMark(company.green_mark_expiry);
    setGreenMarkFmt(company.green_mark_expiry_fmt);
    setNextPrice(company.next_price_adjustment);
    setBuildingType(company.building_type);
    setWdOpHrStart(company.wd_op_hrs_start);
    setWdOpHrEnd(company.wd_op_hrs_end);
    setSatOpHrStart(company.sat_op_hrs_start);
    setSatOpHrEnd(company.sat_op_hrs_end);
    setSunOpHrStart(company.sun_op_hrs_start);
    setSunOpHrEnd(company.sun_op_hrs_end);
    setPhOpHrStart(company.ph_op_hrs_start);
    setPhOpHrEnd(company.ph_op_hrs_end);
    setImage(company.img);
  };

  const resetErrors = () => {
    setTitleError(false);
    setAddressError(false);
    setCountryError(false);
    setAreaError(false);
    setCapacityError(false);
    setRatingError(false);
    setGreenMarkError(false);
    setNextPriceError(false);
    setBuildingTypeError(false);
    setCompanyError("");
    setWdOpHrStartError(false);
    setWdOpHrEndError(false);
    setSatOpHrStartError(false);
    setSatOpHrEndError(false);
    setSunOpHrStartError(false);
    setSunOpHrEndError(false);
    setPhOpHrStartError(false);
    setPhOpHrEndError(false);
  };

  const checkValue = (val, errList, fieldName) => {
    const copiedErrorList = [...errList];
    if (val === "" || val === null) {
      copiedErrorList.push(fieldName);
    }
    return copiedErrorList;
  };

  const validateCompany = () => {
    let errors = [];
    // title
    errors = checkValue(title, errors, "Title");
    if (errors.includes("Title")) {
      setTitleError(true);
    }
    // address
    errors = checkValue(address, errors, "Address");
    if (errors.includes("Address")) {
      setAddressError(true);
    }
    // country
    errors = checkValue(country, errors, "Country");
    if (errors.includes("Address")) {
      setCountryError(true);
    }
    // area
    errors = checkValue(area, errors, "Area");
    if (errors.includes("Area")) {
      setAreaError(true);
    }
    // capicity
    errors = checkValue(capacity, errors, "Capacity");
    if (errors.includes("Capacity")) {
      setCapacityError(true);
    }
    // building_type
    errors = checkValue(buildingType, errors, "Building Type");
    if (errors.includes("Building Type")) {
      setBuildingTypeError(true);
    }
    // wd_op_hrs_start
    errors = checkValue(wdOpHrStart, errors, "Start of Weekday Operating Hour");
    if (errors.includes("Start of Weekday Operating Hour")) {
      setWdOpHrStartError(true);
    }
    // wd_op_hrs_end
    errors = checkValue(wdOpHrEnd, errors, "End of Weekday Operating Hour");
    if (errors.includes("End of Weekday Operating Hour")) {
      setWdOpHrEndError(true);
    }
    // sat_op_hrs_start
    errors = checkValue(
      satOpHrStart,
      errors,
      "Start of Saturday Operating Hour"
    );
    if (errors.includes("Start of Saturday Operating Hour")) {
      setSatOpHrStartError(true);
    }
    // sat_op_hrs_end
    errors = checkValue(satOpHrEnd, errors, "End of Saturday Operating Hour");
    if (errors.includes("End of Saturday Operating Hour")) {
      setSatOpHrEndError(true);
    }
    // sun_op_hrs_start
    errors = checkValue(sunOpHrStart, errors, "Start of Sunday Operating Hour");
    if (errors.includes("Start of Sunday Operating Hour")) {
      setSunOpHrStartError(true);
    }
    // sun_op_hrs_end
    errors = checkValue(sunOpHrEnd, errors, "End of Sunday Operating Hour");
    if (errors.includes("End of Sunday Operating Hour")) {
      setSunOpHrEndError(true);
    }
    // ph_op_hrs_start
    errors = checkValue(
      phOpHrStart,
      errors,
      "Start of Public Holiday Operating Hour"
    );
    if (errors.includes("Start of Public Holiday Operating Hour")) {
      setPhOpHrStartError(true);
    }
    // ph_op_hrs_end
    errors = checkValue(
      phOpHrEnd,
      errors,
      "End of Public Holiday Operating Hour"
    );
    if (errors.includes("End of Public Holiday Operating Hour")) {
      setPhOpHrEndError(true);
    }
    return errors;
  };

  const onChangeUserCompany = (key, value) => {
    setEditCompany({
      ...editCompany,
      [key]: value,
    });
  };

  const saveCompanyClicked = () => {
    let errors = validateCompany();
    console.log("errors:", errors);
    if (errors.length > 0) {
      setCompanyError("Invalid value on " + errors.toString() + ".");
    } else {
      resetErrors();
      saveCompany(editCompany);
    }
  };

  const cancelCompanyClicked = () => {
    setDisableEdit(true);
    setEditCompany(company);
    resetValues();
    resetErrors();
  };

  const editCompanyClicked = () => {
    setDisableEdit(false);
  };

  const companyImageClicked = (event) => {
    event.preventDefault();
    companyImgRef.current.click();
  };

  const companyImageChanged = (event) => {
    onChangeUserCompany("img", event.target.files[0]);
    setImage(event.target.files[0]);
  };

  const showNumberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="profile-details">
      <br></br>
      <b>
        <label className="profile-label-head">My building info</label>
      </b>

      {disableEdit & !hideEdit ? (
        <Button
          className="btn edit-company-btn"
          type="button"
          onClick={editCompanyClicked}
        >
          <i className="bi bi-pencil-square"></i> Edit
        </Button>
      ) : (
        <>
          <Button
            className="btn cancel-company-btn"
            type="button"
            onClick={cancelCompanyClicked}
          >
            <i className="bi bi-x-circle"></i> Cancel
          </Button>
          <Button
            className="btn save-company-btn"
            type="button"
            onClick={saveCompanyClicked}
          >
            <i className="bi bi-save"></i> Save
          </Button>
        </>
      )}
      <br></br>
      <br></br>
      {companyError && (
        <div
          className="alert alert-danger d-flex align-items-center"
          role="alert"
        >
          <i className="bi bi-exclamation-triangle-fill"></i> &nbsp;&nbsp;
          <div>{companyError}</div>
        </div>
      )}
      {disableEdit ? (
        <p style={{ fontSize: "15px" }}>
          {title}{" "}
          <Image
            src={EditLogo}
            className={`edit-company-img ${disableEdit && "hidden"}`}
          />
        </p>
      ) : (
        <p>
          <input
            id="companyImg"
            type="file"
            name="companyImg"
            placeholder="Photo"
            required=""
            capture
            accept="image/*"
            style={{ display: "none" }}
            disabled={disableEdit ? "disabled" : ""}
            onChange={companyImageChanged}
            ref={companyImgRef}
          />
          <input
            id="companyTitle"
            type="text"
            defaultValue={title}
            disabled={disableEdit ? "disabled" : ""}
            className={`${titleError && "input_error_cls"}`}
            onChange={(e) => {
              console.log("title ==> ", e.target.value);
              onChangeUserCompany("title", e.target.value);
              setTitle(e.target.value);
            }}
          />
          &nbsp;&nbsp;
          <Image
            src={EditLogo}
            className={`edit-company-img ${disableEdit && "hidden"}`}
            onClick={companyImageClicked}
          />
        </p>
      )}
      <table>
        <tbody>
          <tr>
            <td className="company-op-hrs-label">Monday - Friday:</td>
            <td className="company-op-hrs-value">
              {disableEdit ? (
                <p>{wdOpHrStart + " - " + wdOpHrEnd}</p>
              ) : (
                <>
                  <TimePicker
                    disableClock={true}
                    format={"HH:mm"}
                    id="companyWdOpStart"
                    onChange={(e) => {
                      console.log("Wd Op Start Hr ==> ", e);
                      onChangeUserCompany("wd_op_hrs_start", e);
                      setWdOpHrStart(e);
                    }}
                    value={wdOpHrStart === "NA" ? "" : wdOpHrStart}
                    className={`${wdOpHrStartError && "input_error_cls"}`}
                  />
                  &nbsp;-&nbsp;
                  <TimePicker
                    disableClock={true}
                    format={"HH:mm"}
                    id="companyWdOpEnd"
                    onChange={(e) => {
                      console.log("Wd Op End Hr ==> ", e);
                      onChangeUserCompany("wd_op_hrs_end", e);
                      setWdOpHrEnd(e);
                    }}
                    value={wdOpHrEnd === "NA" ? "" : wdOpHrEnd}
                    className={`${wdOpHrEndError && "input_error_cls"}`}
                  />
                </>
              )}
            </td>
          </tr>
          <tr>
            <td className="company-op-hrs-label">Saturday:</td>
            <td className="company-op-hrs-value">
              {disableEdit ? (
                <p>{satOpHrStart + " - " + satOpHrEnd}</p>
              ) : (
                <>
                  <TimePicker
                    disableClock={true}
                    format={"HH:mm"}
                    id="companySatOpStart"
                    onChange={(e) => {
                      console.log("Sat Op Start Hr ==> ", e);
                      onChangeUserCompany("sat_op_hrs_start", e);
                      setSatOpHrStart(e);
                    }}
                    value={satOpHrStart === "NA" ? "" : satOpHrStart}
                    className={`${satOpHrStartError && "input_error_cls"}`}
                  />
                  &nbsp;-&nbsp;
                  <TimePicker
                    disableClock={true}
                    format={"HH:mm"}
                    id="companySatOpEnd"
                    onChange={(e) => {
                      console.log("Sat Op End Hr ==> ", e);
                      onChangeUserCompany("sat_op_hrs_end", e);
                      setSatOpHrEnd(e);
                    }}
                    value={satOpHrEnd === "NA" ? "" : satOpHrEnd}
                    className={`${satOpHrEndError && "input_error_cls"}`}
                  />
                </>
              )}
            </td>
          </tr>
          <tr>
            <td className="company-op-hrs-label">Sunday:</td>
            <td className="company-op-hrs-value">
              {disableEdit ? (
                <p>{sunOpHrStart + " - " + sunOpHrEnd}</p>
              ) : (
                <>
                  <TimePicker
                    disableClock={true}
                    format={"HH:mm"}
                    id="companySunOpStart"
                    onChange={(e) => {
                      console.log("Sun Op Start Hr ==> ", e);
                      onChangeUserCompany("sun_op_hrs_start", e);
                      setSunOpHrStart(e);
                    }}
                    value={sunOpHrStart === "NA" ? "" : sunOpHrStart}
                    className={`${sunOpHrStartError && "input_error_cls"}`}
                  />
                  &nbsp;-&nbsp;
                  <TimePicker
                    disableClock={true}
                    format={"HH:mm"}
                    id="companySunOpEnd"
                    onChange={(e) => {
                      console.log("Sun Op End Hr ==> ", e);
                      onChangeUserCompany("sun_op_hrs_end", e);
                      setSunOpHrEnd(e);
                    }}
                    value={sunOpHrEnd === "NA" ? "" : sunOpHrEnd}
                    className={`${sunOpHrEndError && "input_error_cls"}`}
                  />
                </>
              )}
            </td>
          </tr>
          <tr>
            <td className="company-op-hrs-label">Public Holiday:</td>
            <td className="company-op-hrs-value">
              {disableEdit ? (
                <p>{phOpHrStart + " - " + phOpHrEnd}</p>
              ) : (
                <>
                  <TimePicker
                    disableClock={true}
                    format={"HH:mm"}
                    id="companyPhOpStart"
                    onChange={(e) => {
                      console.log("Ph Op Start Hr ==> ", e);
                      onChangeUserCompany("ph_op_hrs_start", e);
                      setPhOpHrStart(e);
                    }}
                    value={phOpHrStart === "NA" ? "" : phOpHrStart}
                    className={`${phOpHrStartError && "input_error_cls"}`}
                  />
                  &nbsp;-&nbsp;
                  <TimePicker
                    disableClock={true}
                    format={"HH:mm"}
                    id="companyPhOpEnd"
                    onChange={(e) => {
                      console.log("Ph Op End Hr ==> ", e);
                      onChangeUserCompany("ph_op_hrs_end", e);
                      setPhOpHrEnd(e);
                    }}
                    value={phOpHrEnd === "NA" ? "" : phOpHrEnd}
                    className={`${phOpHrEndError && "input_error_cls"}`}
                  />
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <Image
        id="company-profile-img"
        className="img-fluid company-img"
        src={
          typeof image === "object"
            ? URL.createObjectURL(image)
            : typeof image === "string"
            ? image + token
            : ProfileHolder
        }
        disabled={disableEdit ? "disabled" : ""}
      />
      <br></br>
      <br></br>
      <Row>
        <Col lg="6" md="12" sm="12" className="col-12">
          <div>
            <div className="company-info-blk">
              <Image
                src={theme === "light" ? CountryLogo : CountryDarkLogo}
                className="company-info-logo"
              />
            </div>
            <div className="company-info-blk company-info-blk-value">
              <b>
                <p style={{ lineHeight: "1em", marginBottom: "0.5em" }}>
                  Country
                </p>
              </b>
              {disableEdit ? (
                <p>{country}</p>
              ) : (
                <p>
                  <input
                    id="companyCountry"
                    type="text"
                    defaultValue={country}
                    disabled={disableEdit ? "disabled" : ""}
                    className={`company-input ${
                      countryError && "input_error_cls"
                    }`}
                    onChange={(e) => {
                      console.log("country ==> ", e.target.value);
                      onChangeUserCompany("country", e.target.value);
                      setCountry(e.target.value);
                    }}
                  />
                </p>
              )}
            </div>
          </div>
          <div>
            <div className="company-info-blk">
              <Image
                src={theme === "light" ? CrateLogo : CrateDarkLogo}
                className="company-info-logo"
              />
            </div>
            <div className="company-info-blk company-info-blk-value">
              <b>
                <p style={{ lineHeight: "1em", marginBottom: "0.5em" }}>
                  Asset class
                </p>
              </b>
              {disableEdit ? (
                <p>{buildingType}</p>
              ) : (
                <p>
                  <input
                    id="companyBuilldingType"
                    type="text"
                    defaultValue={buildingType}
                    disabled={disableEdit ? "disabled" : ""}
                    className={`company-input ${
                      buildingTypeError && "input_error_cls"
                    }`}
                    onChange={(e) => {
                      console.log("building type ==> ", e.target.value);
                      onChangeUserCompany("building_type", e.target.value);
                      setBuildingType(e.target.value);
                    }}
                  />
                </p>
              )}
            </div>
          </div>
          <div>
            <div className="company-info-blk">
              <Image
                src={theme === "light" ? CapacityLogo : CapacityDarkLogo}
                className="company-info-logo"
              />
            </div>
            <div className="company-info-blk company-info-blk-value">
              <b>
                <p style={{ lineHeight: "1em", marginBottom: "0.5em" }}>
                  Installed capacity
                </p>
              </b>
              {disableEdit ? (
                <p>{showNumberWithCommas(capacity)} RT</p>
              ) : (
                <p>
                  <input
                    id="companyCapacity"
                    type="number"
                    defaultValue={capacity}
                    disabled={disableEdit ? "disabled" : ""}
                    className={`company-input ${
                      capacityError && "input_error_cls"
                    }`}
                    onChange={(e) => {
                      console.log("capacity ==> ", e.target.value);
                      onChangeUserCompany("capacity", e.target.value);
                      setCapacity(e.target.value);
                    }}
                  />
                </p>
              )}
            </div>
          </div>
          <div>
            <div className="company-info-blk">
              <Image
                src={theme === "light" ? DateLogo : DateDarkLogo}
                className="company-info-logo-address"
              />
            </div>
            <div className="company-info-blk company-info-blk-value">
              <b>
                <p style={{ lineHeight: "1em", marginBottom: "0.5em" }}>
                  Green building rating expiry date
                </p>
              </b>
              {disableEdit ? (
                <p>{greenMarkFmt}</p>
              ) : (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={
                    greenMark && greenMark !== "-" ? new Date(greenMark) : null
                  }
                  disabled={disableEdit ? "disabled" : ""}
                  className={`company-input ${
                    greenMarkError && "input_error_cls"
                  }`}
                  onChange={(date) => {
                    console.log("green_mark_expiry_fmt changed ==> ", date);
                    onChangeUserCompany("green_mark_expiry", date);
                    setGreenMark(date);
                  }}
                />
              )}
            </div>
          </div>
        </Col>
        <Col lg="6" md="12" sm="12" className="col-12">
          <div>
            <div className="company-info-blk">
              <Image
                src={theme === "light" ? AddressLogo : AddressDarkLogo}
                className="company-info-logo-address"
              />
            </div>
            <div
              className="company-info-blk company-info-blk-value"
              style={{ width: "calc(100%-40px) !important" }}
            >
              <b>
                <p style={{ lineHeight: "1em", marginBottom: "0.5em" }}>
                  Address
                </p>
              </b>
              {disableEdit ? (
                <p style={{ marginBottom: "0px", lineHeight: "1.2em" }}>
                  {address}
                </p>
              ) : (
                <p>
                  <input
                    id="companyAddress"
                    type="text"
                    defaultValue={address}
                    disabled={disableEdit ? "disabled" : ""}
                    className={`company-input ${
                      addressError && "input_error_cls"
                    }`}
                    onChange={(e) => {
                      console.log("address ==> ", e.target.value);
                      onChangeUserCompany("address", e.target.value);
                      setAddress(e.target.value);
                    }}
                  />
                </p>
              )}
            </div>
          </div>
          <div>
            <div className="company-info-blk">
              <Image
                src={theme === "light" ? AreaLogo : AreaDarkLogo}
                className="company-info-logo"
              />
            </div>
            <div className="company-info-blk company-info-blk-value">
              <b>
                <p style={{ lineHeight: "1em", marginBottom: "0.5em" }}>
                  GFA (sqft)
                </p>
              </b>
              {disableEdit ? (
                <p>{showNumberWithCommas(area)}</p>
              ) : (
                <p>
                  <input
                    id="companyArea"
                    type="number"
                    defaultValue={area}
                    disabled={disableEdit ? "disabled" : ""}
                    className={`company-input ${
                      areaError && "input_error_cls"
                    }`}
                    onChange={(e) => {
                      console.log("area ==> ", e.target.value);
                      onChangeUserCompany("area", e.target.value);
                      setArea(e.target.value);
                    }}
                  />
                </p>
              )}
            </div>
          </div>
          <div>
            <div className="company-info-blk">
              <Image
                src={theme === "light" ? GreenLogo : GreenDarkLogo}
                className="company-info-logo"
              />
            </div>
            <div className="company-info-blk company-info-blk-value">
              <b>
                <p style={{ lineHeight: "1em", marginBottom: "0.5em" }}>
                  Green building rating
                </p>
              </b>
              {disableEdit ? (
                <p>{rating}</p>
              ) : (
                <p>
                  <input
                    id="companyRating"
                    type="text"
                    defaultValue={rating}
                    disabled={disableEdit ? "disabled" : ""}
                    className={`company-input ${
                      ratingError && "input_error_cls"
                    }`}
                    onChange={(e) => {
                      console.log("rating ==> ", e.target.value);
                      onChangeUserCompany("rating", e.target.value);
                      setRating(e.target.value);
                    }}
                  />
                </p>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <br></br>
      <br></br>
    </div>
  );
}

export default CompanyDetails;
