import React, { useEffect, useState } from "react";
import { Row, Col, Image, Modal } from "react-bootstrap";
import api from "../../config/api";
import config from "../../config/config";
import { connect } from "react-redux";

import {
  userLogout,
  getUserLoginDetail,
  storeCSRF,
} from "../../store/actions/loginUserAction";

import ProfileDetails from "./profileDetails.js";
import CompanySelector from "./companySelector";

import "./profile.css";

function Profile(props) {
  const theme = localStorage.getItem("theme");
  const { AuthDetail, AuthLoggedIn, CSRFToken } = props;
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("imgToken");
  const { GET_CSRF_TOKEN } = config.api_endpoint;

  useEffect(() => {
    setLoading(true);
    api
      .get(GET_CSRF_TOKEN)
      .then((res) => {
        props.storeCSRF(res["csrf"]);
        props.getUserLoginDetail(AuthDetail.username);
        setLoading(false);
      })
      .catch((err) => console.log("err", err));
  }, []);

  return (
    <>
      {loading ? (
        <Modal
          show={loading}
          data-backdrop="static"
          data-keyboard="false"
          className="loader"
        >
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </Modal>
      ) : (
        <div>
          <Row
            className={`rounded profile-blk${
              theme === "light" ? "" : "-dark"
            } mx-2 ps-2`}
          >
            <Row className="ms-0 ps-0 profile-main-blk">
              <Col md="12" sm="12" className="col-12">
                <div className="table-blk">
                  <div className="table-cell-blk profile-padding-blk padding-blk"></div>
                  <h3
                    className={`table-cell-blk profile-blk-header${
                      theme === "light" ? "" : " dark"
                    }`}
                  >
                    My profile
                  </h3>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6" className="col-12" style={{ paddingRight: "0px" }}>
                <ProfileDetails
                  AuthDetail={AuthDetail}
                  theme={theme}
                  token={token}
                  csrf_token={CSRFToken}
                  storeCSRF={props.storeCSRF}
                  getUserLoginDetail={props.getUserLoginDetail}
                />
              </Col>
              <Col md="6" className="col-12" style={{ paddingRight: "0px" }}>
                <CompanySelector
                  AuthDetail={AuthDetail}
                  theme={theme}
                  token={token}
                  csrf_token={CSRFToken}
                />
              </Col>
            </Row>
          </Row>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  AuthDetail: state.loginUserDetail.userDetail,
  AuthLoggedIn: state.loginUserDetail.loggedIn,
  CSRFToken: state.loginUserDetail.csrf,
});

const mapDispatchToProps = (dispatch) => ({
  getUserLoginDetail: (username) => dispatch(getUserLoginDetail(username)),
  storeCSRF: (token) => dispatch(storeCSRF(token)),
  userLogout: () => dispatch(userLogout()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
