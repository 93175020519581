import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Modal, Image } from "react-bootstrap";
import * as wjCore from "wijmo/wijmo";
import api from "../../config/api";
import config from "../../config/config";
import { connect } from "react-redux";
// views
import StatsBlk from "../../components/liveStatsBlk";
import SpaceBlkAlt from "../../components/spaceBlkAlt";

// css & image
import "./live.css";
import SpaceIcon from "../../assets/images/live/space.svg";
import SpaceDarkIcon from "../../assets/images/live/space-dark.svg";
import LayersIcon from "../../assets/images/live/layers.svg";
import LayersDarkIcon from "../../assets/images/live/layers-dark.svg";
import CubeIcon from "../../assets/images/live/cube.svg";
import CubeDarkIcon from "../../assets/images/live/cube-dark.svg";

function Live(props) {
  const theme = localStorage.getItem("theme");
  const [spaceData, setSpaceData] = useState([]);
  const [selected_tile, setSelectedTile] = useState("");
  const [showTile, setShowTile] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedSpaceFloor, setSelectedSpaceFloor] = useState("All");
  const [selectedSpaceType, setSelectedSpaceType] = useState("All");
  const [selectedSpaceCat, setSelectedSpaceCat] = useState("Main");
  const [loading, setLoading] = useState(false);
  const { GET_LIVE_DATA, GET_ADD_LIVE_DATA, SET_LIVE_SETTING } =
    config.api_endpoint;
  const { AuthDetail, AuthLoggedIn, CSRFToken } = props;
  const [liveData, setLiveData] = useState({ rooms: [] });
  const [spaceBlocks, setSpaceBlocks] = useState([]);
  const [mainData, setMainData] = useState({});
  const [liveSetting, setLiveSetting] = useState([]);
  var liveInterval = null;

  const isInitialRender = useRef(true);
  const shouldUpdateSettings = useRef(true);
  const pauseSetInternval = useRef(false);

  // test data
  const data = {
    power: "ON",
    temp: 8,
    cooling: 657,
    min_cooling: 150,
    max_cooling: 2200,
    confer_temp: null,
    water: null,
  };

  const [spaceCats, setSpaceCats] = useState(["Main"]);
  const [spaceFloors, setSpaceFloors] = useState(["All"]);
  const [spaceTypes, setSpaceTypes] = useState(["All"]);

  const getCurrentSite = config.getCurrentSite;

  // events
  const calculateIndicatorDeg = (tmp, max) => {
    var per = (180 / max) * tmp;
    return parseInt(per);
  };

  const handleClose = () => {
    setSelectedTile("");
    setSelectedBlock("");
    document.getElementById("btnMain0").click();

    setShowTile(false);
    pauseSetInternval.current = false;
    console.log(
      "handleCloseSpaceDialog pauseSetInternval ==> ",
      pauseSetInternval.current
    );
  };

  const retrieveSpaceBlkData = () => {
    setLoading(true);
    api
      .get(
        GET_ADD_LIVE_DATA +
          "?building=" +
          selectedSpaceCat +
          "&type=" +
          selectedSpaceType +
          "&floor=" +
          selectedSpaceFloor
      )
      .then((res) => {
        if (res["status"] === "Success") {
          console.log("retrieveSpaceBlkData rs: ", res["results"]);
          setSpaceBlocks(res["results"]);
          setLoading(false);
        } else {
          setLoading(false);
          alert("Error retrieving add live data.");
        }
      })
      .catch((err) => {
        console.log("add live err", err);
        setLoading(false);
      });
  };

  const handleShow = () => {
    var tmpArr = [];

    loadDialogBlocks("All", "All", "Main", mainData);

    if (mainData["live_settings"]) {
      mainData["live_settings"]["setting"].forEach((item, i) => {
        tmpArr.push(item["name"]);
      });
    }

    console.log("=== handleShow ===", mainData["live_settings"], tmpArr);

    setAllChosenSpaces(tmpArr);

    setSelectedTile("");
    setSelectedBlock("");

    setSelectedSpaceFloor("All");
    setSelectedSpaceType("All");
    setShowTile(true);
    setSelectedSpaceCat("Main");

    pauseSetInternval.current = true;
    console.log("handleShow pauseSetInternval ==> ", pauseSetInternval.current);
    // retrieveSpaceBlkData();
  };

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const spaceCatClicked = (event) => {
    console.log("=== spaceCatClicked === ", event.target.nextSibling.innerHTML);

    var floors = ["All"];
    var types = ["All"];

    Object.keys(mainData["live_ka"]["room_map"]).forEach(function (key) {
      if (key == event.target.nextSibling.innerHTML) {
        Object.keys(mainData["live_ka"]["room_map"][key]).forEach(function (
          key1
        ) {
          types.push(key1);
          Object.keys(mainData["live_ka"]["room_map"][key][key1]).forEach(
            function (key2) {
              floors.push(key2);
            }
          );
        });
      }
    });

    floors.sort(
      (a, b) =>
        mainData["live_ka"]["floor_list"].indexOf(a) -
        mainData["live_ka"]["floor_list"].indexOf(b)
    );
    floors = floors.filter(onlyUnique);
    setSpaceTypes(types);
    setSpaceFloors(floors);
    setSelectedSpaceType("All");
    setSelectedSpaceFloor("All");

    setSelectedSpaceCat(event.target.nextSibling.innerHTML);
    // loadLiveData();
  };

  const spaceFloorClicked = (event) => {
    console.log("=== spaceFloorClicked === ", event.target.innerHTML);
    setSelectedSpaceFloor(event.target.innerHTML);
  };

  const spaceFloorPagingClicked = () => {
    var ind = spaceFloors.indexOf(selectedSpaceFloor);
    if (ind === spaceFloors.length - 1) {
      setSelectedSpaceFloor(spaceFloors[0]);
      console.log("=== spaceFloorPagingClicked === ", spaceFloors[0]);
    } else {
      setSelectedSpaceFloor(spaceFloors[ind + 1]);
      console.log("=== spaceFloorPagingClicked === ", spaceFloors[ind + 1]);
    }
  };

  const addSpaceBlkMouseHover = (event) => {
    var clicked = event.target.getAttribute("data-id");

    if (clicked) {
      setSelectedBlock(clicked);
    }
  };

  const addSpaceBlkMouseLeave = (event) => {
    setSelectedBlock("");
  };

  const removeWidgetClicked = (event) => {
    event.preventDefault();
    var widgetID = parseInt(event.target.getAttribute("data-id"));
    var copySpaceData = [...spaceData];

    spaceData.forEach(function (item, index) {
      if (item["id"] === widgetID) {
        copySpaceData.splice(index, 1);

        isInitialRender.current = false;
        shouldUpdateSettings.current = true;
        setSpaceData(copySpaceData);

        // var elements = document.getElementsByClassName("dropdown-item");
        //
        // for (var i = 0; i < elements.length; i++) {
        //     elements[i].addEventListener('click', removeWidgetClicked, false);
        // };
      }
    });
  };

  const spaceBlkClicked = (event) => {
    console.log("=== spaceBlkClicked ===");

    if (event.target.classList.contains("space-floor-blk")) {
      if (event.target.classList.contains("disabled")) {
        return;
      }
    } else {
      var tmp = event.target.closest(".space-floor-blk");
      if (tmp.classList.contains("disabled")) {
        return;
      }
    }

    var copyLiveData = { ...liveData };
    if (airDialogClickedFrom == "live-main") {
      copyLiveData["rooms"].forEach(function (item, index) {
        if (item["name"] == selectedBlock) {
          copyLiveData["chosen_room"] = selectedBlock;
          copyLiveData["confer_temp"] = item["temperature"];
          copyLiveData["water"] = item["humidity"];
        }
      });

      setLiveData(copyLiveData);
    } else {
      copyLiveData["rooms"].forEach(function (item, index) {
        if (item["name"] == selectedBlock) {
          isInitialRender.current = false;
          shouldUpdateSettings.current = true;

          setSpaceData([
            ...spaceData,
            {
              id: Math.floor(Math.random() * 1000 + 1),
              name: item["name"],
              temperature: item["temperature"],
              humidity: item["humidity"],
              flag: item["flag"],
            },
          ]);
        }
      });
    }
    document.getElementById("btnMain0").click();
    setShowTile(false);
    pauseSetInternval.current = false;
    console.log(
      "spaceBlkClicked pauseSetInternval ==> ",
      pauseSetInternval.current
    );
  };

  const spaceTypeClicked = (event) => {
    console.log("=== spaceTypeClicked === ", event.target.innerHTML);
    setSelectedSpaceType(event.target.innerHTML);

    var floors = ["All"];

    Object.keys(mainData["live_ka"]["room_map"]).forEach(function (key) {
      if (key == selectedSpaceCat) {
        Object.keys(mainData["live_ka"]["room_map"][key]).forEach(function (
          key1
        ) {
          if (
            key1 == event.target.innerHTML ||
            event.target.innerHTML == "All"
          ) {
            Object.keys(mainData["live_ka"]["room_map"][key][key1]).forEach(
              function (key2) {
                console.log("=== key1: ", key1, key2);
                floors.push(key2);
              }
            );
          }
        });
      }
    });

    floors = [...new Set(floors)];
    floors.sort(
      (a, b) =>
        mainData["live_ka"]["floor_list"].indexOf(a) -
        mainData["live_ka"]["floor_list"].indexOf(b)
    );
    console.log("Live floors: ", floors);
    setSpaceFloors(floors);

    setSelectedSpaceFloor("All");
  };

  const sortRooms = (a, b) => {
    var val_a = a["level_raw"];
    var val_b = b["level_raw"];

    if (isNaN(parseInt(val_a))) {
      val_a = 0;
    }

    if (isNaN(parseInt(val_b))) {
      val_b = 0;
    }

    if (val_a < val_b) {
      return -1;
    }
    if (val_a > val_b) {
      return 1;
    }
    return 0;
  };

  const [allChosenSpaces, setAllChosenSpaces] = useState([]);

  const loadDialogBlocks = (_type, _floor, _cat, _data) => {
    var selectedData = _data["live_ka"]["room_map"][_cat];
    var chosenData = [];

    if (!selectedData) return;

    if (_type === "All") {
      if (_floor === "All") {
        Object.keys(selectedData).forEach(function (key) {
          // console.log(key + " " + selectedData[key]);

          Object.keys(selectedData[key]).forEach(function (key2) {
            // console.log(key2 + " " + selectedData[key][key2]);

            chosenData.push(...selectedData[key][key2]);
          });
        });
      } else {
        Object.keys(selectedData).forEach(function (key) {
          if (_floor in selectedData[key]) {
            chosenData.push(...selectedData[key][_floor]);
          }
        });
      }
    } else {
      if (_floor === "All") {
        if (selectedData[_type]) {
          Object.keys(selectedData[_type]).forEach(function (key2) {
            chosenData.push(...selectedData[_type][key2]);
          });
        }
      } else {
        if (selectedData[_type]) {
          if (_floor in selectedData[_type]) {
            chosenData.push(...selectedData[_type][_floor]);
          }
        }
      }
    }

    chosenData = chosenData.sort(sortRooms);

    setSpaceBlocks(chosenData);
  };

  useEffect(() => {
    console.log("useEffect selectedSpaceType ==> ", selectedSpaceType);
    console.log("useEffect selectedSpaceFloor ==> ", selectedSpaceFloor);
    console.log("useEffect selectedSpaceCat ==> ", selectedSpaceCat);

    console.log("mainData ==> ", mainData);
    console.log("liveData ==> ", liveData);

    if (!isInitialRender.current) {
      loadDialogBlocks(
        selectedSpaceType,
        selectedSpaceFloor,
        selectedSpaceCat,
        mainData
      );
    }
  }, [selectedSpaceType, selectedSpaceFloor, selectedSpaceCat]);

  useEffect(() => {
    if (!isInitialRender.current && shouldUpdateSettings.current) {
      var liveConfig = [];
      spaceData.forEach((item, i) => {
        liveConfig.push({
          name: item["name"],
        });
      });

      api
        .post(
          SET_LIVE_SETTING,
          JSON.stringify({ settings: liveConfig, site_id: getCurrentSite() }),
          CSRFToken
        )
        .then((res) => {
          console.log("live settings update: ", res);
          var tmp = mainData;
          tmp["live_settings"] = res["results"];
          setMainData(tmp);
          shouldUpdateSettings.current = false;
        })
        .catch((err) => {
          console.log("Live settings update err", err);
          shouldUpdateSettings.current = false;
        });
    }
  }, [spaceData]);

  const SpaceDialogContent = () => {
    return (
      <>
        {spaceCats.map((value, index) => {
          return (
            <div
              key={index}
              className="btn-group"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input
                type="radio"
                className="btn-check"
                name={`btnMain${index}`}
                id={`btnMain${index}`}
                autoComplete="off"
                onClick={spaceCatClicked}
              />
              <label
                className={`btn add-space-type-live ${
                  selectedSpaceCat === value && "active"
                } ${theme === "light" ? "" : "dark"}`}
                htmlFor={`btnMain${index}`}
              >
                {value}
              </label>
            </div>
          );
        })}
        <hr className="d-none d-lg-block"></hr>
        <br className="d-block d-lg-none"></br>
        <br className="d-block d-lg-none"></br>
        <Row>
          <Col lg="2" md="2">
            <div
              className={`d-none d-lg-block space-type-chooser ${
                theme === "light" ? "" : "dark-blk"
              }`}
            >
              <Image
                className="img-fluid"
                src={theme === "light" ? SpaceIcon : SpaceDarkIcon}
                style={{ paddingBottom: "3px" }}
              />
              <p
                style={{
                  display: "inline-block",
                  fontSize: "15px",
                  paddingLeft: "10px",
                }}
              >
                <b>Type</b>
              </p>
              {spaceTypes.map((value, index) => {
                return (
                  <div key={index}>
                    {index !== 0 && <hr></hr>}
                    <p
                      className={`space-type-info ${
                        selectedSpaceType === value && "active"
                      }`}
                      onClick={spaceTypeClicked}
                    >
                      {value}
                    </p>
                  </div>
                );
              })}
            </div>
            <div
              className="d-block d-lg-none accordion accordion-flush space-type-chooser-accordion"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <Image
                      className="img-fluid"
                      src={theme === "light" ? SpaceIcon : SpaceDarkIcon}
                    />
                    <p
                      style={{
                        display: "inline-block",
                        fontSize: "15px",
                        paddingLeft: "10px",
                        marginBottom: "0px",
                      }}
                    >
                      <b>Type</b>
                    </p>
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    {spaceTypes.map((value, index) => {
                      return (
                        <div key={index}>
                          {index !== 0 && <hr></hr>}
                          <p
                            className={`space-type-info ${
                              selectedSpaceType === value && "active"
                            }`}
                            onClick={spaceTypeClicked}
                          >
                            {value}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="10" md="10">
            <div style={{ paddingTop: "10px" }}>
              <Image
                className="img-fluid"
                style={{ verticalAlign: "text-bottom" }}
                src={theme === "light" ? LayersIcon : LayersDarkIcon}
              />
              <p
                style={{
                  display: "inline-block",
                  fontSize: "15px",
                  fontWeight: "400",
                  paddingLeft: "10px",
                  verticalAlign: "-webkit-baseline-middle",
                }}
              >
                <b>Floor</b>
              </p>
              <div className="middle-padding-space-blk d-none d-lg-inline-flex">
                <hr></hr>
              </div>
              <br className="d-block d-lg-none"></br>
              <p className="paging-space-blk d-none d-lg-inline-block">
                {spaceFloors.map((value, index) => {
                  return (
                    <span key={index}>
                      <span
                        className={`paging-space-page ${
                          value === selectedSpaceFloor && "active"
                        }`}
                        onClick={spaceFloorClicked}
                      >
                        {value}
                      </span>
                    </span>
                  );
                })}
                <span
                  className="paging-space-page"
                  onClick={spaceFloorPagingClicked}
                >
                  >
                </span>
              </p>
              <div className="paging-space-blk-mobile d-block d-lg-none">
                {spaceFloors.map((value, index) => {
                  return (
                    <span
                      key={index}
                      className="paging-space-page-mobile-parent"
                    >
                      <span
                        className={`paging-space-page-mobile ${
                          value === selectedSpaceFloor && "active"
                        }`}
                        onClick={spaceFloorClicked}
                      >
                        {value}
                      </span>
                    </span>
                  );
                })}
                <span
                  className="paging-space-page"
                  onClick={spaceFloorPagingClicked}
                >
                  >
                </span>
              </div>
            </div>
            <div>
              <Row>
                {spaceBlocks !== undefined ? (
                  spaceBlocks.map((_, index) => {
                    return (
                      <Col md="3" sm="6" className="spacer col-6" key={index}>
                        <div
                          className={`rounded space-floor-blk ${
                            selectedBlock === _.name &&
                            allChosenSpaces.indexOf(selectedBlock) < 0 &&
                            "active"
                          } ${
                            allChosenSpaces.indexOf(_.name) > -1 && "disabled"
                          }`}
                          disabled={
                            allChosenSpaces.indexOf(_.name) > -1
                              ? "disabled"
                              : ""
                          }
                          onClick={spaceBlkClicked}
                          onMouseOver={addSpaceBlkMouseHover}
                          onMouseLeave={addSpaceBlkMouseLeave}
                          data-id={_.name}
                        >
                          <Image
                            className="img-fluid"
                            onMouseOver={addSpaceBlkMouseHover}
                            data-id={_.name}
                            src={
                              theme === "light" &&
                              selectedBlock === _.name &&
                              allChosenSpaces.indexOf(selectedBlock) < 0
                                ? CubeDarkIcon
                                : theme === "light" && selectedBlock !== _.name
                                ? CubeIcon
                                : CubeIcon
                            }
                          />
                          <p
                            className="space-blk-level"
                            style={{
                              paddingLeft: "10px",
                              display: "inline-block",
                              lineHeight: "0.5em",
                            }}
                          >
                            <b>{_.level}</b>
                          </p>
                          <p
                            className="space-blk-name"
                            data-id={_.name}
                            style={{ lineHeight: "1em", textAlign: "left" }}
                          >
                            {_.name}
                          </p>
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <div></div>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const loadLiveData = () => {
    console.log("loadLiveData...");
    setLoading(true);
    api
      .get(GET_LIVE_DATA + "?site_id=" + getCurrentSite())
      .then((res) => {
        if (res["detail"] == "Authentication credentials were not provided.") {
          window.location.href = "/";
        }

        if (res["status"] === "Success") {
          setMainData(res["results"]);

          var live_data = {
            power: "-",
            temp: 0,
            temp_limit: 0,
            cooling: 0,
            min_cooling: 150,
            max_cooling: 2200,
            chosen_room: "-",
            rooms: [],
            confer_temp: 0,
            water: 0,
            show_space_temp: res["results"]["show_space_temp"],
            deg: 0,
          };

          if (res["results"]["limit"]) {
            live_data["temp_limit"] =
              res["results"]["limit"]["chilled_water_limit"];
            live_data["min_cooling"] =
              res["results"]["limit"]["cooling_delivered_min"];
            live_data["max_cooling"] =
              res["results"]["limit"]["cooling_delivered_max"];
          }

          var dashSet = res["results"]["live_settings"]["setting"];
          var arr = [];
          var floors = ["All"];
          var ftypes = ["All"];
          var cats = [];

          dashSet.forEach((item, i) => {
            var temperature = 0;
            var humidity = 0;
            var flag = false;
            var name = "";

            res["results"]["live_ka"]["rooms"].forEach((it, ind) => {
              if (it["name"].toUpperCase() == item["name"].toUpperCase()) {
                name = it["name"];
                temperature = it["temperature"];
                humidity = it["humidity"];
                flag = it["flag"];
              }
            });

            arr.push({
              id: Math.floor(Math.random() * 1000 + 1),
              name: name,
              temperature: temperature,
              humidity: humidity,
              flag: flag,
            });
          });

          Object.keys(res["results"]["live_ka"]["room_map"]).forEach(function (
            key
          ) {
            if (key == selectedSpaceCat) {
              Object.keys(res["results"]["live_ka"]["room_map"][key]).forEach(
                function (key1) {
                  ftypes.push(key1);

                  Object.keys(
                    res["results"]["live_ka"]["room_map"][key][key1]
                  ).forEach(function (key2) {
                    floors.push(key2);
                  });
                }
              );
            }
          });

          ftypes = ftypes.sort();
          floors = [...new Set(floors)];
          floors.sort(
            (a, b) =>
              res["results"]["live_ka"]["floor_list"].indexOf(a) -
              res["results"]["live_ka"]["floor_list"].indexOf(b)
          );

          res["results"]["live_ka"]["building_list"].forEach((it, ind) => {
            cats.push(it);
          });

          isInitialRender.current = false;
          shouldUpdateSettings.current = false;

          setSpaceData(arr);
          setSpaceFloors(floors);
          setSpaceTypes(ftypes);
          setSpaceCats(cats);

          if (res["results"]["live_kw"] && res["results"]["live_ka"]) {
            live_data["power"] = res["results"]["live_kw"]["system_status"];
            live_data["temp"] = res["results"]["live_kw"]["water_temp"];
            live_data["cooling"] = res["results"]["live_kw"]["cooling_amount"];
            live_data["deg"] = calculateIndicatorDeg(
              live_data["cooling"],
              live_data["max_cooling"]
            );
            live_data["rooms"] = res["results"]["live_ka"]["rooms"];
            if (res["results"]["live_ka"]["rooms"].length > 0) {
              live_data["chosen_room"] =
                res["results"]["live_ka"]["rooms"][0]["name"];
              live_data["confer_temp"] =
                res["results"]["live_ka"]["rooms"][0]["temperature"];
              live_data["water"] =
                res["results"]["live_ka"]["rooms"][0]["humidity"];
            } else {
              live_data["chosen_room"] = "";
              live_data["confer_temp"] = "";
              live_data["water"] = "";
            }

            if (selectedBlock) {
              live_data["rooms"].forEach((item, i) => {
                if (selectedBlock == item["name"]) {
                  live_data["chosen_room"] = item["name"];
                  live_data["confer_temp"] = item["temperature"];
                  live_data["water"] = item["humidity"];
                }
              });
            }
          }

          setLiveData(live_data);
          loadDialogBlocks(
            selectedSpaceType,
            selectedSpaceFloor,
            selectedSpaceCat,
            res["results"]
          );
          setLoading(false);
        } else {
          setLoading(false);
          alert("Error retrieving live data.");
        }
      })
      .catch((err) => {
        console.log("live err", err);
        setLoading(false);
      });
  };

  const intervalTimer = () => {
    console.log("live setInterval running...");
    console.log("Pause Set Interval: ", pauseSetInternval.current);
    if (pauseSetInternval.current == false) {
      loadLiveData();
      console.log("Ran set interval...");
    } else {
      console.log("Paused set interval...");
    }
  };

  useEffect(() => {
    if (props.AuthLoggedIn === false) {
      return;
    }

    console.log("refresh_interval: ", AuthDetail.refresh_interval * 60 * 1000);

    loadLiveData();
    liveInterval = setInterval(
      intervalTimer,
      AuthDetail.refresh_interval * 60 * 1000
    );

    return () => {
      setLoading(false);
      clearInterval(liveInterval);
    };
  }, []);

  const [showLiveValueModal, setShowLiveValueModal] = useState(false);
  const [showLiveType, setShowLiveType] = useState("");

  const showMoreIconClicked = (event) => {
    event.preventDefault();
    console.log("showMoreIconClicked: ", event.target);

    var choice = event.target.getAttribute("data-type");

    if (choice === "water") {
      setShowLiveType("water");
    } else {
      setShowLiveType("cooling");
    }
    setShowLiveValueModal(true);
  };

  const [airDialogClickedFrom, setAirDialogClickedFrom] = useState("");
  const [airDialogWidget, setAirDialogWidget] = useState("");

  const showSpaceDialogClicked = (event) => {
    event.preventDefault();
    var widget = event.target.getAttribute("data-widget");
    var clickedId = event.target.getAttribute("data-id");

    setAirDialogClickedFrom(widget);
    setShowTile(true);
    pauseSetInternval.current = true;
    console.log(
      "showSpaceDialogClicked pauseSetInternval ==> ",
      pauseSetInternval.current
    );

    if (clickedId) {
      setAirDialogWidget(clickedId);
    }
  };

  let dragSource = null;
  let dropTarget = null;

  const dashboardDragStart = (e) => {
    const target = e.target;
    console.log("target:", target);
    if (target) {
      dragSource = target;
      wjCore.addClass(dragSource, "drag-source");
      const dt = e.dataTransfer;
      dt.effectAllowed = "move";
      dt.setData("text", dragSource.innerHTML);
    }
  };

  const dashboardDragOver = (e) => {
    if (dragSource) {
      let tile = wjCore.closest(e.target, ".space-stats-wrapper");
      if (tile === dragSource) {
        tile = null;
      }
      if (dragSource && tile && tile !== dragSource) {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
      }
      if (dropTarget !== tile) {
        wjCore.removeClass(dropTarget, "drag-over");
        dropTarget = tile;
        wjCore.addClass(dropTarget, "drag-over");
      }
    }
  };

  const getIndex = (e) => {
    const p = e.parentElement;
    for (let i = 0; i < p.children.length; i++) {
      if (p.children[i] === e) return i;
    }
    return -1;
  };

  const dashboardDrop = (e) => {
    if (dragSource && dropTarget) {
      // finish drag/drop
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      e.preventDefault();

      // re-order HTML elements (optional here, we're updating the state later)
      const srcIndex = getIndex(dragSource);
      const dstIndex = getIndex(dropTarget);

      var dragSourceInd = 0;
      var dropTargetInd = 0;

      let copiedSpaceData = [...spaceData];
      copiedSpaceData.forEach((item, i) => {
        if (item["id"] == dragSource.getAttribute("data-id")) {
          dragSourceInd = i;
        }
        if (item["id"] == dropTarget.getAttribute("data-id")) {
          dropTargetInd = i;
        }
      });

      var cl = copiedSpaceData[dragSourceInd];
      copiedSpaceData[dragSourceInd] = copiedSpaceData[dropTargetInd];
      copiedSpaceData[dropTargetInd] = cl;
      shouldUpdateSettings.current = true;
      setSpaceData(copiedSpaceData);
      console.log("At the end copiedSpaceData:", copiedSpaceData);

      // var tmpDashConfig = dashConfig.slice();
      // var swapper = tmpDashConfig[dragSourceInd + 1];
      // tmpDashConfig[dragSourceInd + 1] = tmpDashConfig[dropTargetInd + 1];
      // tmpDashConfig[dropTargetInd + 1] = swapper;
      // setDashConfig(tmpDashConfig);

      var clone = dropTarget.cloneNode(true);
      clone.classList.remove("drag-over");

      wjCore.removeClass(dragSource, "drag-source");
      wjCore.removeClass(dropTarget, "drag-over");

      // dragSource.parentElement.insertBefore(clone, dragSource);
      // dropTarget.parentElement.insertBefore(dragSource, dropTarget);
      // dropTarget.remove();
      // dragSource.focus();
    }
  };

  const dashboardDragEnd = () => {
    wjCore.removeClass(dragSource, "drag-source");
    wjCore.removeClass(dropTarget, "drag-over");

    dragSource = dropTarget = null;

    // var dragSourceIndex = tiles
    //   .map(function (e) {
    //     return e.id;
    //   })
    //   .indexOf(parseInt(dragSource.getAttribute("data-id")));
    // var dropTargetIndex = tiles
    //   .map(function (e) {
    //     return e.id;
    //   })
    //   .indexOf(parseInt(dropTarget.getAttribute("data-id")));
    //
    // var tmpDashConfig = dashConfig.slice();
    // var swapper = tmpDashConfig[dragSourceIndex + 1];
    // tmpDashConfig[dragSourceIndex + 1] = tmpDashConfig[dropTargetIndex + 1];
    // tmpDashConfig[dropTargetIndex + 1] = swapper;
    //
    // shouldUpdateSettings.current = true;
    // setDashConfig(tmpDashConfig);
    //
    // dragSource = dropTarget = null;

    // var elements = document.getElementsByClassName("liveStatsDrop");

    // for (var i = 0; i < elements.length; i++) {
    //   elements[i].addEventListener("click", showSpaceDialogClicked, false);
    // }

    // var elements = document.getElementsByClassName("dropdown-item");

    // for (var i = 0; i < elements.length; i++) {
    //   elements[i].addEventListener("click", removeWidgetClicked, false);
    // }
  };

  return (
    <>
      {loading && (
        <Modal
          show={loading}
          data-backdrop="static"
          data-keyboard="false"
          className="loader"
        >
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </Modal>
      )}
      {!loading && (
        <Row style={{ marginLeft: "1em", marginRight: "1em" }} className="pb-2">
          <StatsBlk
            heading="Kaer Water"
            data={liveData}
            showLiveValueModal={showLiveValueModal}
            setShowLiveValueModal={setShowLiveValueModal}
            showLiveType={showLiveType}
            setShowLiveType={setShowLiveType}
            showMoreIconClicked={showMoreIconClicked}
            showSpaceDialogClicked={showSpaceDialogClicked}
            hideKaerAir={true}
          />
        </Row>
      )}

      {(getCurrentSite() === "insead" ||
        (liveData["rooms"].length > 0 && liveData["show_space_temp"])) && (
        <Row className="pt-4" style={{ marginLeft: "1em", marginRight: "1em" }}>
          <SpaceBlkAlt
            heading="Kaer Air"
            data={spaceData}
            handleClose={handleClose}
            handleShow={handleShow}
            removeWidgetClicked={removeWidgetClicked}
            dashboardDragStart={dashboardDragStart}
            dashboardDragOver={dashboardDragOver}
            dashboardDrop={dashboardDrop}
            dashboardDragEnd={dashboardDragEnd}
          />

          <Modal show={showTile} onHide={handleClose}>
            <Modal.Header
              closeButton
              className={`${theme === "light" ? "" : "dark dark-blk"}`}
            >
              <Modal.Title>
                <div className="modal-padding-blk"></div>{" "}
                <div className="modal-title-blk">
                  <span className={`${theme === "light" ? "" : "dark"}`}>
                    Add a space
                  </span>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              className={`${theme === "light" ? "" : "dark dark-blk"}`}
            >
              <SpaceDialogContent />
            </Modal.Body>
          </Modal>
        </Row>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  AuthDetail: state.loginUserDetail.userDetail,
  AuthLoggedIn: state.loginUserDetail.loggedIn,
  CSRFToken: state.loginUserDetail.csrf,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Live);
