import React, { useEffect, useState } from "react";
import { Row, Col, Image } from 'react-bootstrap';

// import { Checkbox } from 'antd';

function NotificationSettings (props) {

  const header = props.header;
  const notification_settings = props.notification_settings;
  const notiSettingsChanged = props.notiSettingsChanged;

  console.log('separate notification_settings ==> ', notification_settings);
  /*
  <p className="settings-text">
    <input className="form-check-input" type="checkbox" value="" id="notiDaily" />
    <label className="form-check-label" htmlFor="notiDaily">
      Daily
    </label>
  </p>
  <p className="settings-text">
    <input className="form-check-input" type="checkbox" value="" id="notiWeekly" />
    <label className="form-check-label" htmlFor="notiWeekly">
      Weekly
    </label>
  </p>
  <p className="settings-text">
    <input className="form-check-input" type="checkbox" value="" id="notiMonthly" />
    <label className="form-check-label" htmlFor="notiMonthly">
      Monthly
    </label>
  </p>
  */

  return (
    <div>
      <h5 className="noti-settings-head"><b>{header}</b></h5>
      <p className="settings-text">
        <input className="form-check-input" type="checkbox" id="monthlyConsumption"
        defaultChecked={notification_settings.monthly_consumption && notification_settings.monthly_consumption.enabled}
        onChange={notiSettingsChanged} />
        <label className="form-check-label" htmlFor="monthlyConsumption">
          If monthly consumption is <select className="form-select cons-level" aria-label="Default select example" id="monthlyConsumptionThreshold"
            defaultValue={notification_settings.monthly_consumption && notification_settings.monthly_consumption.threshold}
            onChange={notiSettingsChanged}
          >
            <option value="above">above</option>
            <option value="below">below</option>
          </select>
          <input style={{ width: "100px" }} type="number" className="cons-focus cons-input"
            id="monthlyConsumptionValue"
            defaultValue={notification_settings.monthly_consumption && notification_settings.monthly_consumption.value}
            onChange={notiSettingsChanged}
          /> RTH
        </label>

      </p>
      <p className="settings-text">
        <input className="form-check-input" type="checkbox" id="monthlyConsumptionAvg"
        defaultChecked={notification_settings.monthly_consumption_avg && notification_settings.monthly_consumption_avg.enabled}
        onChange={notiSettingsChanged} />
        <label className="form-check-label" htmlFor="monthlyConsumptionAvg">
          If monthly consumption is <select className="form-select cons-level" aria-label="Default select example" id="monthlyConsumptionAvgThreshold"
            defaultValue={notification_settings.monthly_consumption_avg && notification_settings.monthly_consumption_avg.threshold}
            onChange={notiSettingsChanged}
          >
            <option value="above">above</option>
            <option value="below">below</option>
          </select>
          <input style={{ width: "60px" }} type="number" className="cons-focus cons-input"
            id="monthlyConsumptionAvgValue"
            defaultValue={notification_settings.monthly_consumption_avg && notification_settings.monthly_consumption_avg.value} min="1" max="100"
            onChange={notiSettingsChanged}
          /> % of monthly average
        </label>
      </p>
      <p className="settings-text">
        <input className="form-check-input" type="checkbox" id="greenMarkExpiry"
        defaultChecked={notification_settings.green_mark_expiry && notification_settings.green_mark_expiry.enabled}
        onChange={notiSettingsChanged} />
        <label className="form-check-label" htmlFor="greenMarkExpiry">
          <input style={{ width: "50px" }} type="number" className="cons-focus cons-input"
            id="greenMarkExpiryValue"
            defaultValue={notification_settings.green_mark_expiry && notification_settings.green_mark_expiry.value}
            onChange={notiSettingsChanged}
          /> months(s) to green building rating expiry date
        </label>
      </p>
    </div>
  )
}

export default NotificationSettings;
