import api from "../../config/api";
import config from "../../config/config";

import loginUserActionTypes from "./types/loginUserActionTypes";

const { USER_DETAILS } = config.api_endpoint;

const {
  LOGIN_USER_DETAIL,
  LOGIN_USER_DETAIL_SUCCESS,
  LOGIN_USER_DETAIL_FAIL,
  USER_LOGOUT,
  STORE_TOKEN_USER,
  STORE_CSRF_TOKEN,
  STORE_LOGGED_IN_FLAG,
} = loginUserActionTypes;

// User Details
export const getuserloginDetail = () => {
  return {
    type: LOGIN_USER_DETAIL,
  };
};

export const getuserloginDetailSuccess = (data) => {
  return {
    type: LOGIN_USER_DETAIL_SUCCESS,
    payload: data,
  };
};

export const getuserloginDetailFail = () => {
  return {
    type: LOGIN_USER_DETAIL_FAIL,
  };
};

export const getUserLoginDetail = (username) => {
  return (dispatch) => {
    return api
      .get(USER_DETAILS, {
        username: username,
      })
      .then((res) => {
        return dispatch(getuserloginDetailSuccess(res["results"]));
      })
      .catch((err) => {
        console.log("err", err);
        return dispatch(getuserloginDetailFail());
      });
  };
};

// Logout
export const userLogoutT = () => {
  return {
    type: USER_LOGOUT,
  };
};

export const userLogout = () => {
  return (dispatch) => {
    localStorage.removeItem("checkTimeoutValue");
    return dispatch(userLogoutT());
  };
};

// Token
const storeTokeSuccess = (token) => {
  return {
    type: STORE_TOKEN_USER,
    payload: token,
  };
};

export const storeToken = (token) => {
  return (dispatch) => {
    return dispatch(storeTokeSuccess(token));
  };
};

// CSRF Token
const storeCSRFTokenSuccess = (token) => {
  return {
    type: STORE_CSRF_TOKEN,
    payload: token,
  };
};

export const storeCSRF = (token) => {
  return (dispatch) => {
    console.log("storeCSRF: ", token);
    return dispatch(storeCSRFTokenSuccess(token));
  };
};

// Login flag
export const storeLoggedInFlagSuccess = (flag) => {
  return {
    type: STORE_LOGGED_IN_FLAG,
    payload: flag,
  };
};

export const storeLoggedInFlag = (flag) => {
  return (dispatch) => {
    console.log("storeLoggedInFlag: ", flag);
    return dispatch(storeLoggedInFlagSuccess(flag));
  };
};
