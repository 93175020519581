import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import throttle from "lodash.throttle";
import { Document, Page, pdfjs } from "react-pdf";
import { Spinner } from "react-bootstrap";

function PdfViewer(props) {
  const [initialWidth, setInitialWidth] = useState(null);
  const { numPages, setNumPages, pageNumber, setPageNumber } = props;
  const isMobile = useMediaQuery({ maxWidth: 800 });
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const pdfWrapper = useRef(null);

  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
      setInitialWidth(pdfWrapper.current.getBoundingClientRect().width);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", throttle(setPdfSize, 3000));
    setPdfSize();
    return () => {
      window.removeEventListener("resize", throttle(setPdfSize, 3000));
    };
  }, []);

  return (
    <div
      style={{ marginTop: 50, marginLeft: 50, marginRight: 50 }}
      // className="pv"
    >
      <div
        // className="pdf-docs"
        ref={pdfWrapper}
        // style={{
        //   // position: "fixed",
        //   zIndex: 9999,
        //   overflow: "scroll",
        // }}
      >
        <Document
          file={{
            url: props.pdfFile.url,
          }}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
          loading={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <Spinner animation="grow" variant="info" />
            </div>
          }
        >
          <Page
            pageNumber={pageNumber}
            scale={props.pdfZoom || 1}
            width={initialWidth}
          />
        </Document>
      </div>
    </div>
  );
}

export default PdfViewer;
// style={{
//   position: "fixed",
//   top: "70%",
//   left: "50%",
//   transform: "translate(-50%,-50%)",
//   zIndex: 9999,
//   height: "100%",
//   // maxWidth: "50%",
//   overflow: !isMobile && "scroll",
// }}
// className="pdf-docs"
