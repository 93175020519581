import config from "./config";

const get = (path, params) => {
  return doRequest(path, params, "GET");
};

const post = (path, params, csrf_token) => {
  return doRequest(path, params, "POST", csrf_token);
};

const remove = (path, params, csrf_token) => {
  return doRequest(path, params, "DELETE", csrf_token);
};

const objectToQueryString = (obj) => {
  return Object.keys(obj)
    .map((key) => key + "=" + obj[key])
    .join("&");
};

const doRequest = (path, params, method, csrf_token) => {
  const options = { method, headers: {} };

  // convert the object to params
  params
    ? method === "GET"
      ? (path += `?${objectToQueryString(params)}`)
      : (options.body = params)
    : (options.body = params);
  // headers

  options.headers = {
    "Content-Type": "application/json",
  };

  // need to upload file, json content-type not working so exclude it.
  if (
    (path == config.api_endpoint["USER_DETAILS"] ||
      path == config.api_endpoint["COMPANY_DETAILS"] ||
      path == config.api_endpoint["INVOICE"] ||
      path == config.api_endpoint["RESOURCE"] ||
      path == config.api_endpoint["CHECK_RESOURCE"] ||
      path == config.api_endpoint["CHANGE_PASSWORD"] ||
      path == config.api_endpoint["RESEND_MAIL"] ||
      path == config.api_endpoint["USER_LIST"]) &&
    method == "POST"
  ) {
    options.headers = {};
  }
  if (
    (path == config.api_endpoint["USER_DETAILS"] ||
      path == config.api_endpoint["COMPANY_DETAILS"] ||
      path == config.api_endpoint["INVOICE"] ||
      path == config.api_endpoint["RESOURCE"] ||
      path == config.api_endpoint["CHECK_RESOURCE"] ||
      path == config.api_endpoint["CHANGE_PASSWORD"] ||
      path == config.api_endpoint["USER_LIST"]) &&
    method == "DELETE"
  ) {
    options.headers = {};
  }
  options.credentials = "include";

  if (csrf_token) {
    options.headers["X-CSRFToken"] = csrf_token;
  }

  return fetch(config.url + path, options).then((response) => {
    if (response.status === 204) {
      return undefined;
    }
    return response.json().then((result) => {
      return result;
    });
  });
};
export default {
  get,
  post,
  remove,
};
