import React from "react";
import StarActiveIcon from "../../../assets/images/resources/staractive.svg";
import StarUnactiveIcon from "../../../assets/images/resources/starunactive.svg";
import DownloadIcon from "../../../assets/images/billing/download.svg";
import { connect } from "react-redux";
import { Pagination } from "react-bootstrap";
import { ReactComponent as EditIcon } from "../../../assets/images/billing/edit.svg";
import api from "../../../config/api";
import config from "../../../config/config";
import moment from "moment";
import { downloadPdfInvoice } from "../../../store/actions/billingAction";
import { ReactComponent as ArrowDownIcon } from "../../../assets/images/billing/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../../assets/images/billing/arrow_up.svg";

const data = [
  "Signed contracts",
  "Addendums / notices",
  "Annual Statement of Account",
  "Commencement forms",
];

const MobileTable = (props) => {
  let theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";
  const { GET_TOKEN } = config.api_endpoint;

  const downloadPdf = (url, name) => {
    // api.get(GET_TOKEN).then((res) => {
    let imgToken = localStorage.getItem("imgToken");
    let combineLink = url + imgToken;

    props.setPdfFile({
      url: combineLink,
      filename: name,
    });
    // });
  };
  const shouldEditResource =
    props.AuthDetail &&
    props.AuthDetail.permissions.length > 0 &&
    props.AuthDetail.permissions.find((p) => p === "change_resource");
  return (
    <div className="mobile_tables">
      {/* <div className="mobile_title">Contracts & formal notices</div> */}
      <div className={`mobile_billing_header ${theme}`}>
        Name
        <span
          style={{ marginLeft: 15, cursor: "pointer" }}
          onClick={() => {
            props.setCurrentPage(1);
            props.setSorting((prev) => !prev);
          }}
        >
          {props.sorting ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </span>
      </div>

      <div className={`mobile_billing_header ${theme}`}>Favourite</div>
      {props.resources_list.map((_) => {
        return (
          <div className="mobile_download_table_card" key={_.id}>
            <div className="mt_card">
              <div>{_.title}</div>

              <div>
                {_.is_favourite ? (
                  <img
                    src={StarActiveIcon}
                    onClick={() =>
                      props.favouriteResource(_.id, _.is_favourite)
                    }
                  />
                ) : (
                  <img
                    src={StarUnactiveIcon}
                    onClick={() =>
                      props.favouriteResource(_.id, _.is_favourite)
                    }
                  />
                )}
              </div>
            </div>
            {/* <div style={{}}>
              <div style={{ fontWeight: "bold" }}>Date</div>
              <div>
                {_.timestamp_fmt}
           
              </div>
            </div> */}
            <div>
              {shouldEditResource && (
                <EditIcon
                  fill={theme === "light" ? "#000" : "#fff"}
                  width={18}
                  onClick={() => {
                    props.getDetailValue(_);
                    props.handleShowUpdate();
                  }}
                />
              )}
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (_.resource_file == null) {
                  return alert("PDF file doesn't exist");
                }
                downloadPdf(_.resource_file, _.timestamp_fmt);

                props.setPdfFileModal(true);
              }}
            >
              View
            </div>
            <div style={{ width: "100%", marginTop: 10 }}>
              <button
                className="btn btn-dark d-flex justify-content-center align-items-center"
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  if (_.resource_file == null) {
                    return alert("PDF file doesn't exist");
                  }
                  // props.downloadPdfInvoice(_.resource_file);
                  props.downloadPdfInvoice(_.resource_file);
                }}
              >
                <div style={{ marginRight: 5 }}>
                  <img src={DownloadIcon} />
                </div>
                <div>Download</div>
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};
const mapStateToProps = (state) => ({
  resource: state.resource,
  AuthDetail: state.loginUserDetail.userDetail,
  resources_list: state.resource.resources_list,
});

const mapDispatchToProps = (dispatch) => ({
  downloadPdfInvoice: (url) => dispatch(downloadPdfInvoice(url)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MobileTable);
