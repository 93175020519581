import api from "../../config/api";
import config from "../../config/config";
import resourcesAcionTypes from "./types/resourcesAcionTypes";
import moment from "moment";

const { GET_SOURCES } = config.api_endpoint;

const { GET_RESOURCE, GET_RESOURCE_SUCCESS, GET_RESOURCE_FAIL } =
  resourcesAcionTypes;

const fetchingResource = () => {
  return {
    type: GET_RESOURCE,
  };
};

const fetchResourceSuccess = (data, pageSize) => {
  return {
    type: GET_RESOURCE_SUCCESS,
    payload: data,
    pageSize: pageSize,
  };
};

const getCurrentSite = config.getCurrentSite;

export const fetchResource = (
  resource_type,
  search,
  current_page,
  sorting,
  pageSize
) => {
  return (dispatch) => {
    dispatch(fetchingResource());
    api
      .get(GET_SOURCES, {
        resource_type,
        search,
        page_size: 10,
        page: current_page,
        site_id: getCurrentSite(),
        sort_order: sorting ? "desc" : "asc",
        page_size: pageSize,
      })
      .then((res) => {
        console.log("res", res);
        dispatch(fetchResourceSuccess(res, pageSize));
      })
      .catch((err) => {
        console.log("er", err);
      });
  };
};

const updateSuccess = (data) => {
  return {
    type: "UPDATE_FAVOURITE_SUCCESS",
    payload: data,
  };
};
export const updateFavourite = (resource_id, is_favourite) => {
  return (dispatch) => {
    dispatch(updateSuccess({ resource_id, is_favourite }));
  };
};
