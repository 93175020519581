import React from "react";
import { Row, Col, Image } from 'react-bootstrap';

// image & css
import "./contactBlk.css";
import PhoneIcon from "../assets/images/contact/phone.png";
import EmailIcon from "../assets/images/contact/email.png";

function contactBlk(props) {

  const theme = localStorage.getItem("theme");
  const chart_title = props.chart_title;
  const heading = props.heading;
  const data = props.data;
  const removeWidgetClicked = props.removeWidgetClicked;
  const id = props.id;

  return (
    <Row className={`rounded contacts-blk${theme === "light" ? "" : "-dark"}`}>
      <Row className="ms-0 contact-main-blk">
        <Col lg="10" md="10" sm="10" className="col-10 contact-sub-blk">
          <div className="dash-contact-blk">
            <div className="contact-cell-blk contact-padding-blk padding-blk"></div>
            <h3 className={`contact-cell-blk contact-blk-header${theme === "light" ? "" : " dark"}`}>{heading}</h3>
          </div>
        </Col>
        <Col lg="2" md="2" sm="2" className="col-2 contact-sub-blk">
          <i className="bi bi-gear-fill settings-icon" role="button" id={id} data-bs-toggle="dropdown" aria-expanded="false"></i>
          <ul className="dropdown-menu" aria-labelledby={id}>
            <li><a className="dropdown-item" href="#" data-choice="day" data-id={id} onClick={removeWidgetClicked}>Remove</a></li>
          </ul>
        </Col>
      </Row>
      <Row className="ms-0 dcontact-contact" style={{ overflowY: "auto" }}>
        { data.length > 0 ? (
          data.map((_, index) => {
            return (
              <Row key={index} className="contact-info">
                <p className="department-name">{_.department}{ _.name && <>: {_.name}</> }</p>
                { _.email && <p>{_.email}</p> }
                { _.phone || _.email ? (
                  <Row>
                    <Col sm="8" className="col-8">
                      <p>{_.phone}</p>
                    </Col>
                    <Col sm="4" className="col-4" style={{ padding: 0 }}>
                      <a href={`tel:${_.phone}`}><Image src={PhoneIcon} /></a><a href={`mailto:${_.email}`}><Image src={EmailIcon} /></a>
                    </Col>
                  </Row>
                ): ("")}
              </Row>
            )
          })
        ): (
          <div>No contacts.</div>
        )}

      </Row>
    </Row>
  )

}

export default contactBlk;
